import React, {useEffect} from "react";
import {
    Alert, Box, Chip,
    FormControl, FormControlLabel, FormHelperText,
    Grid, Input,
    InputLabel,
    MenuItem, Paper,
    TextField, Typography
} from "@mui/material";
import {
    Control,
    FieldErrors,
    UseFormClearErrors,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue
} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {DocumentsTypeSelect} from "./DocumentsTypeSelect";
import {IDocumentType} from "../../../interfaces/media/document/IDocumentType";
import {ILang} from "../../../interfaces/langs/iLang";
import Switch from "@mui/material/Switch";
import {LangMultiselect} from "../../shared/LangMultiselect";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ProductAutocompleteSearch from "../../product/ProductAutocompleteSearch";
import {IProductAutocomplete} from "../../../interfaces/product/IProductAutocomplete";
import {DocumentSelectedProduct} from "./DocumentSelectedProduct";
import {IDocumentDetail} from "../../../interfaces/media/document/IDocumentDetail";
import {IDocumentProduct} from "../../../interfaces/media/document/IDocumentProduct";
import {DocumentProduct} from "../../../models/media/DocumentProduct";
import {AlertAppContext} from "../../alert/AlertAppContext";
import IconButton from "@mui/material/IconButton";
import documentService from "../../../services/DocumentService";


interface IProps {
    reg: UseFormRegister<IDocumentDetail>,
    con: Control<IDocumentDetail, any>,
    err: FieldErrors<IDocumentDetail>,
    setError: UseFormSetError<IDocumentDetail>,
    clearErrors: UseFormClearErrors<IDocumentDetail>,
    setValue: UseFormSetValue<IDocumentDetail>,
    document: IDocumentDetail | null,
    loaded: boolean,
    docId:number
}


export const Document: React.FC<IProps> = ({reg, con, err, setError, clearErrors, setValue, loaded, document, docId}) => {


    const {t} = useTranslation();
    const datum = {
        dnesniDatum: new Date().toISOString().substring(0, 10)
    };
    // noinspection RedundantConditionalExpressionJS
    const [isExpiration, setIsExpiration] = React.useState<boolean>(document && document.expirationDate !== '' ? true : false);

    const handleChangeExpirationSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsExpiration(event.target.checked);
    };

    const [selectedType, setSelectedType] = React.useState<IDocumentType | null>(document ? {name: "", code: document.documentTypeCode} : null);
    const [selectedLangs, setSelectedLangs] = React.useState<ILang[]>([]);
    const [products, setProducts] = React.useState<IDocumentProduct[]>([]);
    const appAlert = React.useContext(AlertAppContext);






    //set data from DB if edit

    useEffect(() => {
        if (loaded && document !== null) {

            let p = document.products && document.products.length > 0 ? document.products.map(x => new DocumentProduct(x)) : [];

            setValue("name", document.name);
            setValue("note", document.note);
            setValue("id", document.id);
            setValue("guid", document.guid);
            setValue('documentTypeCode', document.documentTypeCode);
            setValue('products', p);
            setValue('langs', document.langs);
            setProducts(p);
            setSelectedLangs(document.langs);

        }

    }, [loaded, document, setValue]);
    const getSelected = (selected: IDocumentType) => {

        setSelectedType(selected);

        if (!selected) {
            setError('documentTypeCode', {type: 'error', message: t('general.validation.required')});
        } else {
            clearErrors('documentTypeCode');
            setValue('documentTypeCode', selected.code);

        }
    }

    const handleRemoveProduct = (index:number) => {
        let arr = [...products];
        arr.splice(index, 1);
        if (arr.length === 0)
            setError('products', {type: 'error', message: t('general.validation.required')});
        setProducts(arr);
    }

    const handleLangsSelect = (items:ILang[]) => {
        if(items.length === 0){
            setError('langs', {type: 'error', message: t('general.validation.required')});
        } else {
            clearErrors('langs');
        }


        setSelectedLangs(items);



    }

    useEffect(() => {

        setValue('products', products);
    }, [products, setValue])

    useEffect(() => {

        setValue('langs', selectedLangs);
    }, [selectedLangs, setValue])



    const handleProductAutocomplete = (product: IProductAutocomplete | null) => {




        if (!product) return;

        let arr = products.slice();
        if(arr.find(f => f.id === product.id)){
            appAlert.showAlert({severity: "info", message: "Tento produkt je již přidán"});
            return false;
        }


        let p:IDocumentProduct = new DocumentProduct({
            name: product.name,
            id: product.id,
            codeSuffix: product.codeSuffix ?? null,
            basicCode: product.basicCode
        });

        arr.push(p);

        console.log(arr);

        if (arr.length > 0)
           clearErrors('products');

        setProducts(arr);
    }

    const handleDownloadFile = (guid: string) => {
        documentService.getBase64(guid).then((res) => {
            const byteCharacters = atob(res);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
        }).catch((err) => {
           console.error(err);
            appAlert.showAlert({severity: "error", message: t('documents.fileNotFound')});
        });
    };

    return (
        <Grid container spacing={2} padding={1}>
            <Grid item xs={12} sm={6}>
                <TextField label="ID" disabled={true} fullWidth={true}  {...reg('id')} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField label="GUID" disabled={true} fullWidth={true}  {...reg('guid')} />
            </Grid>


            <Grid item xs={12}>
                <TextField label={t("documents.name")} fullWidth={true}  {...reg('name', {required: true})}
                           error={err.name ? true : false} helperText={err.name && t('general.validation.required')}/>
            </Grid>

            {/* NEW ROW  */}

            <Grid item xs={12}>
                <FormControl fullWidth={true}>

                    <DocumentsTypeSelect selectedCode={selectedType ? selectedType.code : ""} showAllOption={false}
                                         hasError={!!err.documentTypeCode} onChange={getSelected}/>
                    {err.documentTypeCode &&
                        <FormHelperText
                            error={true}>{null != err.documentTypeCode.message ? err.documentTypeCode.message.toString() : ''}</FormHelperText>}
                </FormControl>



                <Input defaultValue={selectedType} type={"hidden"} {...reg('documentTypeCode', {
                    validate: () => {


                        if (selectedType === undefined) {
                            return t('general.validation.required')
                        }

                        return true;
                    }
                })} />
            </Grid>

            {/* NEW ROW */}


            <Grid item xs={6} sm={3}>
                <FormControl fullWidth={true}>
                    <TextField
                        select
                        fullWidth
                        label={t("documents.isActive")}
                        defaultValue={document ? document.isActive ? "true" : false : ""}
                        inputProps={reg('isActive', {
                            required: true
                        })}
                        error={err.isActive ? true : false}
                        helperText={err.isActive && t('general.validation.required')}
                    >
                        <MenuItem value="true">{t("general.text.yes")}</MenuItem>
                        <MenuItem value={"false"}>{t("general.text.no")}</MenuItem>
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={3}>
                <FormControl fullWidth={true}>
                    <TextField
                        select
                        fullWidth
                        label={t("documents.isPublic")}
                        defaultValue={document ? document.isPublic ? "true" : false : ""}
                        inputProps={reg('isPublic', {
                            required: true
                        })}
                        error={err.isPublic ? true : false}
                        helperText={err.isPublic && t('general.validation.required')}
                    >
                        <MenuItem value={"true"}>{t("general.text.yes")}</MenuItem>
                        <MenuItem value={"false"}>{t("general.text.no")}</MenuItem>
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item xs={0} sm={6}>

            </Grid>

            {/* NEW ROW - EXPIRATION */}

            <Grid item xs={6} sm={3}>
                <FormControlLabel control={<Switch
                    checked={isExpiration}
                    onChange={handleChangeExpirationSwitch}
                    inputProps={{ 'aria-label': 'controlled' }}

                />} label={t('documents.expiration')} />


            </Grid>
            <Grid item xs={6} sm={3}>
            {
                isExpiration ?
                    <TextField
                        label={t("documents.expirationDate")}
                        defaultValue={document && isExpiration ? document.expirationDate : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type={'date'}
                        fullWidth={true} {...reg('expirationDate')}
                        inputProps={reg('expirationDate', {
                            required: true
                        })}
                        error={err.expirationDate ? true : false}
                        helperText={err.expirationDate && t('general.validation.required')}/>
                    : null
            }
            </Grid>
            <Grid item xs={0} sm={6} />

            {/* NEW ROW - EXPIRATION */}



            <Grid item xs={12}>
                <LangMultiselect selectedLangs={selectedLangs} onSelect={(items) => handleLangsSelect(items)} />
                <FormHelperText
                    error={true}>{err.langs && err.langs.message == null ? '' : err.langs && err.langs.message}</FormHelperText>
                <Input value={selectedLangs} type="hidden" {...reg('langs', {
                    validate: (value) => {

                        return selectedLangs.length !== 0;
                    }
                })} />
            </Grid>


          {/*
          <Grid item xs={6}>
                <Lang isRequired={true} showAllOption={false} getSelected={getSelectedLang}/>
            </Grid>

          <Grid item xs={6}>
                {selectedLangs.length > 0 ?
                    <>
                        <Grid item xs={12}>
                            <Chip size="medium" variant="filled" color={'primary'} label={t('langs.selected')}/>
                        </Grid>
                        <Grid item xs={12}>
                            {selectedLangs && selectedLangs.map((lang: ILang) => {
                                return <DocumentSelectedLang key={lang.code} onDelete={onDelete}
                                                             lang={lang}></DocumentSelectedLang>
                            })}
                        </Grid>
                    </>
                    : null
                }
            </Grid>*/}

            <Grid item xs={12}>
                <TextField label={t('documents.note')} fullWidth={true} multiline={true} {...reg('note')}/>
            </Grid>

            {/* NEW ROW -vazba na proddukt */}


            <Grid item xs={12}>
                <ProductAutocompleteSearch onChange={(v) => handleProductAutocomplete(v)} label={t('documents.productLink')} resetValOnClick={true} />
                <FormHelperText
                    error={true}>{err.products && err.products.message == null ? '' : err.products && err.products.message}</FormHelperText>
                <Input value={products} type="hidden" {...reg('products', {
                    validate: (value) => {

                        return products.length !== 0;
                    }
                })} />

            </Grid>
            <Grid>
                {products.length > 0 ?
                    <Box key={"prodLenght_" + products.length}>
                        {products.map((x,i) => (
                            <DocumentSelectedProduct index={i} key={"docSelProd_" + i} product={x} onDelete={(index) => handleRemoveProduct(index)} />
                        ))}
                    </Box>
                : null}
            </Grid>

            {/* NEW ROW - document */}

            {document ?



                <>
                <Grid item xs={12} />

                <Grid item xs={12}>
                    <Paper sx={{padding: 2, backgroundColor: '#f4f1f1'}}>
                        <Box display="flex" alignItems="center">
                            <Box display="flex" flexDirection="column" width={50} justifyContent="center" alignItems="center">
                                <IconButton onClick={() => {handleDownloadFile(document?.guid)}}>
                                    <InsertDriveFileIcon color="info" fontSize="large" />
                                </IconButton>

                                <Chip  label={document.extension} variant="outlined" color="info" size="small" sx={{minWidth: 40}} />
                            </Box>
                            <Box padding={1}>
                                <Typography variant="h6" >{document.name}</Typography>
                                <Typography variant="note" >{document.fileName}</Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                </>


                : null }

            {/* NEW ROW - FILE */}

            {document ? null

            :
                <Grid item xs={12}>
                    <InputLabel>{t('general.text.uploadFile')}</InputLabel>
                    <Alert severity={'warning'}>{t('documents.fileLimit')}</Alert>
                    <Input type='file' {...reg('files', {
                        required: t('general.validation.required'),
                        validate: (value) => {

                            const acceptedFormats = ['pdf', 'xlsx', 'docx', 'xls', 'doc', 'jpg', 'jpeg', 'gif'];
                            //const acceptedFormats = ['aa', 'bbb'];
                            const maxFileSize = 5;

                            const fileSize = value[0].size / 1024 / 1024; // in MiB

                            const fileExtension = value[0].name.split('.').pop().toLowerCase();

                            if (!acceptedFormats.includes(fileExtension)) {
                                return t('general.validation.extensionNotAllowed') + acceptedFormats.join(', ');
                            }

                            if (fileSize > maxFileSize) {
                                return t('general.validation.maxFileSize') + maxFileSize + 'MB';
                            }

                            return true;
                        }
                    })} error={err.files ? true : false} sx={{marginTop: '15px'}}/>
                    {err.files &&
                        < FormHelperText
                            error={true}>{err.files.message == null ? '' : err.files.message.toString()}</FormHelperText>
                    }
                </Grid>
            }




        </Grid>


    );
}