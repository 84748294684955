import {IUserRole} from "../../interfaces/user/./IUserRole";


class UserRole implements IUserRole{
    id: number
    name: string
    description: string


    constructor(j:IUserRole) {
        this.id = j.id;
        this.name = j.name;
        this.description = j.description
    }

}

export default UserRole;