import React from "react";
import {Button} from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';

interface IProps{
    isNextData:boolean
    loadNext: () => void
}
export const BasicScrollNextPaginationButton:React.FC<IProps> = (props)  => {
    if(!props.isNextData){
        return(
            <Button disabled={true}>Více toho není</Button>
        )
    }

    return (
        <Button startIcon={<CachedIcon />} variant={'outlined'} onClick={props.loadNext}>
            Načti další
        </Button>
    )

}