import React, {useEffect} from "react";
import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    TextField,
    Typography
} from "@mui/material";
import {IErpStore} from "../../interfaces/erpStore/IErpStore";
import erpService from "../../services/ErpService";
import {useTranslation} from "react-i18next";


interface IProps {
    onClick: (store:IErpStore|null) => void
    id: number|null
    variant: 'buttonGroup'| 'select'
    size?: "small" | "medium"
}

export const ErpStoreSelect: React.FC<IProps> = (props) => {

    const [loaded, setLoaded] = React.useState(false);
    const [stores, setStores] = React.useState<IErpStore[]>([]);
    const {t} = useTranslation();

    useEffect(() => {
        if(!loaded){
            erpService.getErpStores('cz').then((res) => {
                setStores(res);
            }).finally(() => setLoaded(true));
        }
    }, [loaded]);


    if(!loaded){
        return(
            <Box>
                <CircularProgress />
            </Box>
        )

    }


    if(props.variant === 'buttonGroup'){
        return(
            <Box>
                <Typography variant="h5" gutterBottom>
                    Sklad
                </Typography>
                <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                    {stores.length > 0 && stores.map((x,i) => {
                        return <Button onClick={() => props.onClick(x)} key={'storeBut_' + i} variant={props.id && x.id === props.id ? 'contained' : 'outlined'}>{x.name}</Button>
                    })}

                </ButtonGroup>
            </Box>
        )
    }


    return (
            <Autocomplete
                fullWidth={true}
                options={stores}
                size={props.size ?? "medium"}
                getOptionLabel={(option) => option.name}
                renderInput={(params: any) => <TextField {...params} label={t('general.stores')}/>}
                onChange={(_, data) => {
                    props.onClick(data)
                }}
            />
    )
}