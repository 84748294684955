import {useAuth} from "../context/AuthProvider";
import React, {ReactNode, useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import IUser from "../interfaces/user/IUser";

interface IProtectedComponent{
    children: ReactNode;
    role: string[]
}
export const ProtectedComponent: React.FC<IProtectedComponent>  = (props: IProtectedComponent) => {

    const {token} = useAuth();

    const [role, setRole] = useState<string[]>([]);

    useEffect(() => {
        if (!token) {
            setRole([]);
            return;
        }

        let data = jwtDecode<IUser>(token);
        setRole(data.role);
    }, [token]);



    if (token && props.role && role) {
        //superAdmin
        if(role.includes('1') || props.role.length === 0){
            return (<>{props.children}</>);
        }

       if(role.some( v => props.role.includes(v)) ){
           return (<>{props.children}</>);
       }

       return (<></>);

    }



    return (<></>);


}