import React from "react";
import {IPhotoDetail} from "../../../interfaces/media/photo/IPhotoDetail";
import {Box, Button, Grid, Typography} from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';

interface IProps{
    photo: IPhotoDetail|null
}
export const PhotoEditPartInUpsertDialog:React.FC<IProps> = (props) => {

    if(props.photo === null){
        return null;
    }

    const p = props.photo;

    return(
        <>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <img style={{borderRadius: 5}} src={p.link} alt={p.fileName} />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <Button startIcon={<LaunchIcon />} variant="outlined" target="_blank" href={p.linkToSmall}>SMALL</Button>
                    <Button sx={{marginTop: 2}} startIcon={<LaunchIcon />} variant="outlined" target="_blank" href={p.linkToMedium}>MEDIUM</Button>
                    <Button sx={{marginTop: 2}} startIcon={<LaunchIcon />} variant="outlined" target="_blank" href={p.linkToOriginal}>ORIGINAL</Button>
                </Box>

            </Grid>
            <Grid item xs={12} sm={4} md={5} lg={6}>
                <Box display="flex" flexDirection="column" alignItems="flex-start">

                        <Typography variant="body1">Id: {p.id}</Typography>
                        <Typography variant="body1">Guid: {p.guid}</Typography>
                        <Typography variant="body1">Přípona: {p.extension}</Typography>
                        <Typography variant="body1">Mime-type: {p.mimeType}</Typography>
                        <Typography variant="body1">Název souboru: {p.fileName}</Typography>
                        <Typography variant="body1">Velikost souboru: {(p.length / 1024 / 1024).toFixed(2) } MB</Typography>

                </Box>

            </Grid>
        </>
    )
}