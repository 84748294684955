import React from "react";
import {Alert, Typography} from "@mui/material";
import {ProductErpLastSync} from "../../../models/product/ProductErpLastSync";
import {useTranslation} from "react-i18next";

interface IProps {
    lastSync: ProductErpLastSync,
}

export const ErpInfoTabSyncBox: React.FC<IProps> = ({lastSync}) => {
    const [showMore, setShowMore] = React.useState<boolean>(false);
    const {t} = useTranslation();
    const from = lastSync.direction === "in" ? "ERP" : "Intranet";
    const to = lastSync.direction === "in" ? "Intranet" : "ERP";


    return (
        <Alert sx={{marginTop: 1}} severity={lastSync.isSuccess ? 'success' : 'error'}>
            <div> {from} {' ---> '} {to} | {t('general.date')}: {lastSync.lastSyncDate}</div>
            {!lastSync.isSuccess ?
                <div>
                    <div><Typography variant={'body1'}>{t('products.productDetailDialog.erp.lastSyncError')}<span style={{cursor: 'pointer', color: 'gray'}}
                        onClick={() => setShowMore(!showMore)}>{t('general.showMore')}</span></Typography></div>
                </div>
                : <div><Typography variant={'body1'}>{t('products.productDetailDialog.erp.lastSyncSuccess')}</Typography></div>}

            {!lastSync.isSuccess && showMore ?
                <div>{lastSync.message}</div>
                : <div></div>}
        </Alert>
    );

}