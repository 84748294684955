import i18next from "i18next";
import {initReactI18next} from "react-i18next";

import cs from './cs.json';
import en from './en.json';

const resources = {
    en: {
        translation: en
    },
    cs: {
        translation: cs
    }
}

i18next.use(initReactI18next).init({
    resources,
    lng: !localStorage.getItem('lang') ? 'cs' : localStorage.getItem('lang')
})

export default i18next;