import React from "react";
import {
    Alert,
    CircularProgress,
    FormControl,
    Grid,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {IProductLocale} from "../../../interfaces/product/IProductLocale";
import productService from "../../../services/ProductService";
import HtmlTextEditor from "../../shared/HtmlTextEditor";
import {ButtonBasic} from "../../shared/ButtonBasic";
import ProductLocale from "../../../models/product/ProductLocale";
import {AlertAppContext} from "../../alert/AlertAppContext";
import {IBasicException} from "../../../interfaces/IBasicException";


interface IProps {
    productLocale: IProductLocale,
    onClose:  () => void;
}
const LocaleInfoTab:React.FC<IProps> = (props) => {
    const {t, i18n} = useTranslation();
    const [lang, setLang] = React.useState<string>(i18n.languages[0]);
    const [pl, setPl] = React.useState<IProductLocale>(props.productLocale);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [noLocale, setNoLocale] = React.useState<boolean>(false);


    const [actionLoading, setActionLoading] = React.useState<boolean>(false);

    const appAlert = React.useContext(AlertAppContext);


    const handleChangeLang = (
        event: React.MouseEvent<HTMLElement>,
        l:string
    ) => {
        if(l === null)
            return null;
        setIsLoading(true);
        productService.getLocaleInfo(props.productLocale.product.id,l).then((res) => {
            if(res === null){
                //pokud neexistuje locale varinata vrat novy prazdny objekt
                setPl({id: 0, productId: props.productLocale.product.id, name: "", longHtmlDescription: "", shortDescription: "", langCode: l, product: props.productLocale.product});
                setNoLocale(true);
            } else  {
                setPl(res);
            }

            setLang(l);
        }).finally(() => {
            setIsLoading(false);
        })

    };

    const handleChange = (key:string, val:string) => {
        let p = {...pl};
        p[key] = val;
        setPl(p);
    }

    const handleSave = () => {
        setActionLoading(true);
        let p = new ProductLocale(pl);

        //tim rekneme ze chceme update/create jen locale
        p.product.id = 0;

        if(p.name.length === 0){
            appAlert.showAlert({severity: "error", message: "Vyplňte název produktu."});
            setActionLoading(false);
            return false;
        }



        if(pl.id === 0){
            productService.create(p).then(() => {
                appAlert.showAlert({severity: "success", message: null});
                //props.onClose();
            }).catch((err:IBasicException) => {
                appAlert.showAlert({severity: "error", message: err.message});

            } ).finally(() => {
                setActionLoading(false);
            })
        } else {
            productService.update(p).then(() => {
                appAlert.showAlert({severity: "success", message: null});
                //props.onClose();
            }).catch((err:IBasicException) => {
                appAlert.showAlert({severity: "error", message: err.message});

            } ).finally(() => {
                setActionLoading(false);
            })
        }




    }





    if(isLoading)
        return <CircularProgress />


    return(
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ToggleButtonGroup
                        color="primary"
                        value={lang}
                        exclusive
                        onChange={handleChangeLang}
                        aria-label="Platform"
                        size="small"
                    >
                        <ToggleButton value="cs" key="cs">CS</ToggleButton>
                        <ToggleButton value="en" key="en">EN</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} >
                    {noLocale ?
                        <Alert severity="warning">Jazyková mutace pro tento jazyk neexistuje.</Alert>
                    : null}
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TextField
                            label={t('products.newProductlocale.name')}
                            fullWidth={true}
                            inputProps={{maxLength: 200}}
                            onChange={(e) => handleChange('name', e.target.value)}
                            variant="standard"
                            value={pl.name}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth={true}>
                        <TextField
                            label={t('products.newProductlocale.shortDescription')}
                            fullWidth={true}
                            inputProps={{maxLength: 500}}
                            onChange={(e) => handleChange('shortDescription' ,e.target.value)}
                            variant="standard"
                            multiline={true}
                            value={pl.shortDescription}
                            rows={6}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} />
                <Grid item xs={12}>
                    <Typography variant="note">Dlouhý popis (html)</Typography>
                    <HtmlTextEditor value={pl.longHtmlDescription} onChange={(val: string) => handleChange('longHtmlDescription' ,val)} />
                </Grid>

                <Grid item xs={12} />
                <Grid item xs={12} />

                {/*  FOOTER BUTTONS */}

                <Grid item xs={12}>
                    <ButtonBasic appVariant="save" onClick={handleSave} isLoading={actionLoading}/>
                </Grid>
            </Grid>
    )
}

export default LocaleInfoTab;