import {ILang} from "../../interfaces/langs/iLang";

export class Lang implements ILang {
    code: string;
    name: string;

    constructor(json:ILang) {
        this.code = json.code;
        this.name = json.name;
    }
}