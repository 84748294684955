import {IOrderItemConstructor} from "../../interfaces/order/IOrderItemConstructor";
import UnitLocale from "../unit/UnitLocale";

export class OrderItem {
    productId: number
    amount: number
    sellPrice: number
    sellPriceVat: number
    sellVat: number
    basicUnitId: number
    name: string;
    fullCode: string;
    sumSellPrice: number
    sumSellPriceVat: number
    unitLocale: UnitLocale

    constructor(j:IOrderItemConstructor) {
        this.productId = j.productId;
        this.amount = j.amount;
        this.sellPrice = j.sellPrice;
        this.sellVat = j.sellVat;
        this.basicUnitId = j.basicUnitId;
        this.name = j.name;
        this.fullCode = j.fullCode;
        this.unitLocale = new UnitLocale(j.unitLocale);
        //calculate
        this.sellPriceVat = 0;
        this.sumSellPriceVat = 0;
        this.sumSellPrice = 0;
        this.recalculate();
    }

    public recalculate(){
        this.sumSellPrice = (this.amount * this.sellPrice);
        this.sellPriceVat = (this.sellPrice * ( (this.sellVat / 100) + 1) );
        this.sumSellPriceVat = (this.amount * this.sellPriceVat);
    }
}