import React from "react";
import {IErpCommResponse} from "../../../interfaces/erp/IErpCommResponse";
import {IZboziFromEso} from "../../../interfaces/product/IProductDataFromErp";
import {Alert, Box, CircularProgress, Grid, Paper, Typography, useTheme} from "@mui/material";
import {NullableString} from "../../../interfaces/NullableStringType";

interface IProps{
    erpResponse: IErpCommResponse<IZboziFromEso>|null,
    isLoading: boolean
}

interface IKeyProps {
    k:NullableString;
    v:NullableString|number|undefined;
    direction: 'column'|'row';
    keyWidth?: number|null;
    valueBold?: boolean;
}

export const ErpInfoTabErpData: React.FC<IProps> = ({erpResponse, isLoading}) => {

    const theme = useTheme();


    const KeyValue:React.FC<IKeyProps> = (props)  => {
        return (
            <Box  display="flex" flexDirection={props.direction} alignItems="center">
                {props.k ?
                    <Box sx={{marginRight: 1, width: props.keyWidth ?? 120}}>
                        <Typography variant="note">{props.k}</Typography>
                    </Box>
                    : null}
                {props.v ?
                    <Box sx={{fontWeight: props.valueBold ? 'bold' : 'normal'}}>
                        <Typography variant="note" >{props.v}</Typography>
                    </Box>
                    : null }
            </Box>
        );
    }


    if(erpResponse === null) return null;

    if(isLoading){
        return <CircularProgress />
    }

    if(!erpResponse.isSuccess){
        return (
            <Alert severity={'error'}>
                {erpResponse.message}
            </Alert>
        );
    }

    if(erpResponse.data){
        let d = erpResponse.data[0];
        return(
            <Paper elevation={0} sx={{padding: 1.5, backgroundColor: theme.palette.grey[100]}} >
                <KeyValue keyWidth={150} k="ID ZBOŽÍ:"  v={d.idZbozi} direction={'row'} />
                <KeyValue keyWidth={150} k="ČÍSLO ZBOŽÍ:"  v={d.cis_Zbozi} direction={'row'} />
                <KeyValue keyWidth={150} k="PRODEJNÍ CENA:"  v={d.hdProdCena} direction={'row'} />
                <KeyValue keyWidth={150} k="PRODEJNÍ CENA S DPH:"  v={d.hdCenaDphZbozi} direction={'row'} />
                <KeyValue keyWidth={150} k="PRODEJNÍ DPH:"  v={d.sazbaProdej} direction={'row'} />
                <KeyValue keyWidth={150} k="KÓD MJ:"  v={d.kod_Mj} direction={'row'} />
                <KeyValue keyWidth={150} k="ID SUBJEKT:"  v={d.idSubjekt} direction={'row'} />
                <KeyValue keyWidth={150} k="KÓD SUBJEKTU:"  v={d.kod_Subjektu} direction={'row'} />
                <KeyValue keyWidth={150} k="NÁZEV SUBJEKTU:"  v={d.subj_Nazev} direction={'row'} />
                <KeyValue keyWidth={150} k="VÝROBCE:"  v={d.nazev_Vyrobce} direction={'row'} />
            </Paper>
        )
    }



    return (
        <Alert severity={'error'}>
            Chyba struktury vrácených dat z ERP.
        </Alert>
    )


}