import {IUnitLocale} from "../../interfaces/unit/IUnitLocale";
import {IUnit} from "../../interfaces/unit/IUnit";
import Unit from "./Unit";

class UnitLocale implements IUnitLocale {
    code: string;
    langCode: string;
    name: string;
    unit: IUnit;
    unitId: number;

    constructor(j:IUnitLocale) {
        this.code = j.code;
        this.langCode = j.code;
        this.name = j.name;
        this.unit = new Unit(j.unit);
        this.unitId = j.unitId;

    }

}

export default UnitLocale;