import React, {useEffect} from "react";
import {Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Paper, TextField} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {ProducerUpsertDialog} from "./ProducerUpsertDialog";
import {IProducerFilter} from "../../interfaces/producer/IProducerFilter";
import {IProducer} from "../../interfaces/producer/IProducer";
import CountrySelect from "../country/countrySelect";
import {ProducerFilterTable} from "./ProducerFilterTable";
import producerService from "../../services/ProducerService";
import {ButtonBasic} from "../shared/ButtonBasic";
import {useTranslation} from "react-i18next";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {PaperBasic} from "../shared/PaperBasic";



interface IProps {
    selectedId: number|null;
}
export const ProducerFilter: React.FC<IProps> = (props) => {

    const {t, i18n} = useTranslation();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [countryCode, setCountryCode] = React.useState<string>("");
    const [name, setName] = React.useState<string>("");
    const [data, setData] = React.useState<IProducer[]>([]);
    const [aLoading, setALoading] = React.useState<boolean>(false);
    const [nonActive, setNonActive] = React.useState<boolean>(false);
    const [producer, setProducer] = React.useState<IProducer|null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNonActive(event.target.checked);
    };

    useEffect(() => {



        if(props.selectedId && props.selectedId > 0){

            let f:IProducerFilter = {
                countryCode: countryCode,
                name: name,
                fullText: "",
                showNonActive: nonActive,
                id: props.selectedId
            }

            producerService.filter(f).then((res) => {

                if(res.length === 1)
                {
                    setProducer(res[0]);
                    setOpenDialog(true);
                }
            })
        }

    }, [props.selectedId]);

    const filterProducers = async (f:IProducerFilter) => {
        await producerService.filter(f).then(
            res => setData(res)
        );
    }

    const handleFilter = () => {
        setALoading(true);
        let f:IProducerFilter = {
            countryCode: countryCode,
            name: name,
            fullText: "",
            showNonActive: nonActive,
            id: 0
        }
        filterProducers(f).finally(() => setALoading(false));

    }



    return(
        <>
       <Container>
           <Box padding={1} sx={{display: 'flex'}} >
               <Button variant="contained" color={'info'} startIcon={<AddBoxIcon />} onClick={()=>setOpenDialog(true)} >{t('producers.buttons.addNew')}</Button>
           </Box>
           <PaperBasic title={t('producers.producerFilter.title')} paperMargins={'20px 0px 30px 0px'}>
               <Box sx={{
                   px:3
               }}>
                   <Grid container spacing={1.5}>
                     <Grid item xs={8} md={4}>
                         <CountrySelect hasError={false} showAll={true} size={"small"} onChange={(val) => setCountryCode(val)} countryCode={countryCode} />

                     </Grid>
                     <Grid item xs={12} md={8}>
                         <TextField
                             label={t('producers.producerFilter.producerName')}
                             variant={"outlined"}
                             size={"small"}
                             fullWidth={true}
                             onChange={event => setName(event.target.value)}
                         />
                     </Grid>
                     <Grid item xs={12}>
                         <FormGroup>
                             <FormControlLabel control={<Checkbox onChange={handleChange} checked={nonActive}  />} label={t('producers.producerFilter.showInactive')} />
                         </FormGroup>

                     </Grid>
                     <Grid item xs={12} sx={{
                         display: {md:'flex'},
                         justifyContent: 'left'
                     }}>
                         <ButtonBasic
                             appVariant="filter"
                             onClick={handleFilter}
                             isLoading={aLoading}
                         />

                     </Grid>
                 </Grid>
               </Box>
           </PaperBasic>
           <ProducerUpsertDialog refresh={() => handleFilter()} producer={producer} onClose={()=>setOpenDialog(false)} open={openDialog}/>
       </Container>
        <ProducerFilterTable data={data} onFilter={handleFilter} />
        </>
    );

}