import {IProductLocale} from "../../interfaces/product/IProductLocale";
import {IProduct} from "../../interfaces/product/IProduct";
import {NullableString} from "../../interfaces/NullableStringType";
import {Product} from "./Product";

class ProductLocale implements IProductLocale {
    [key: string]: string | number | boolean | IProduct | null
    id:number;
    name: string;
    langCode: string;
    productId: number;
    longHtmlDescription: NullableString;
    shortDescription: NullableString;
    product:IProduct;

    constructor(json:IProductLocale) {
        this.name = json.name;
        this.product = new Product(json.product);
        this.langCode = json.langCode;
        this.productId = json.productId;
        this.id = json.id;
        this.shortDescription = json.shortDescription;
        this.longHtmlDescription = json.longHtmlDescription;
    }






}

export default ProductLocale;