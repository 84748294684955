import {NetworkService} from "./NetworkService";
import {Job} from "../models/job/job";
import {IJobConstructor} from "../interfaces/job/IJobConstructor";

const controller = "/job";

class JobService {
    async filter(): Promise<Job[]> {
        const {data} = await NetworkService.newConnection().get<IJobConstructor[]>(controller);
        return data.map(x=> new Job(x));
    }
}

const jobService = new JobService();

export default jobService;