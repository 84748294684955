import React from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    Grid,
    TextField, Typography
} from "@mui/material";
import {IDialogBase} from "../../interfaces/IDialogBase";
import {CloseButton} from "../../theming/customButtons";
// -------- IKONY ---------
import CloseIcon from "@mui/icons-material/Close";
import CategoryIcon from '@mui/icons-material/Category';

import UnitSelect from "../unit/UnitSelect";
import ProductAutocompleteSearch from "./ProductAutocompleteSearch";
import NewProductCategoryTreeModal from "./NewProductCategoryTreeModal";
import {Product} from "../../models/product/Product";
import {AlertAppContext} from "../alert/AlertAppContext";
import productService from "../../services/ProductService";
import {useTranslation} from "react-i18next";
import ProductLocale from "../../models/product/ProductLocale";
import ProducerAllSelect from "../producer/ProducerAllSelect";
import {CategoryTreeSelected} from "../category/CategoryTreeSelected";
import {IProductAutocomplete} from "../../interfaces/product/IProductAutocomplete";
import {FormChip} from "../shared/FormChip";
import {ButtonBasic} from "../shared/ButtonBasic";
import {ProductState} from "./ProductState";
import Switch from "@mui/material/Switch";
const NewProductDialog: React.FC<IDialogBase> = (props) => {

    const {t} = useTranslation();

    const appAlert = React.useContext(AlertAppContext);
    const [err, setErr] = React.useState<string|null>("");

    const automaticCode = "";
    const [basicCodeVariant, setBasicCodeVariant] = React.useState<string>("new");

    const [basicUnitId, setBasicUnitId] = React.useState<number>(0);
    const [autoCompleteProduct, setAutocompleteProduct] = React.useState<IProductAutocomplete | null>(null);
    const [showInCatalogue, setShowInCatalogue] = React.useState<boolean>(true);
    const [basicCode, setBasicCode] = React.useState<string|null>(null);
    const [producerId, setProducerId] = React.useState<number>(0);
    const [producerCode, setProducerCode] = React.useState<string>("");
    const [isCatModalOpen ,setIsCatModalOpen] = React.useState<boolean>(false);
    const [catgoryId, setCategoryId] = React.useState<number>(0);
    const [categoryTree, setCategoryTree] = React.useState<string[]>([]);
    const [codeSuffix, setCodeSuffix] = React.useState<string|null>("");
    const [internalName, setInternalName] = React.useState<string>("");
    const [stateId, setStateId] = React.useState<number>(3);

    const handleProductSearchAutocomplete = (productLocale: IProductAutocomplete | null) => {
        setAutocompleteProduct(productLocale);
        setBasicCode( productLocale !== null ? productLocale.basicCode : null);
    }



    const handleChooseCategory = (names: string[], id: number) => {
        setCategoryId(id);
        setCategoryTree(names);
        setIsCatModalOpen(false);
    }

    const resetForm = () => {
        setBasicUnitId(0);
        setAutocompleteProduct(null);
        setBasicCode(null);
        setProducerId(0);
        setCategoryId(0);
        setCategoryTree([]);
        setCodeSuffix("");
        setInternalName("");
        setBasicCodeVariant("new");
        setProducerCode('');
    }



    const handleBasicCodeVariant = (val:string) => {
        setBasicCodeVariant(val);
        if(val === 'new'){
            setBasicCode(automaticCode);
        }
    }

    const handleUnitChange = (val:number) => {
        setBasicUnitId(val);
    }

    const handleCreate = () => {
        let p = new Product({
            basicUnitId: basicUnitId,
            producerId: producerId,
            producerCode: producerCode,
            categoryId: catgoryId,
            basicCode: basicCode ?? "",
            name: internalName,
            codeSuffix: codeSuffix ?? "",
            id: 0,
            mainPhotoId: 0,
            stateId: stateId,
            showInPublicCatalogue: showInCatalogue
        });
        let pl = new ProductLocale({
            name: internalName, //musime zalozit rovnou i locale pro cs
            langCode: 'cs',
            productId: 0,
            product: p,
            id: 0,
            shortDescription: "",
            longHtmlDescription: ""
        });

        let v = p.validate(false);
        if(!v.isValid){
            appAlert.showAlert({severity: "error", message: v.message});
            setErr(v.message);
            return false;
        }

        productService.create(pl).then(() => {
            props.onClose(true);
            appAlert.showAlert({severity: "success", message: t('general.done')});
            resetForm();

        }).catch((res) => {
            console.log(res);
            appAlert.showAlert({severity: "error", message: res.message});
        });
    }

    return(
        <>
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth={'xl'}
        >
            <DialogTitle sx={{padding: 0}}>
                <Typography variant={'paperHeading'} sx={{padding: 3}}>
                    {t('products.newProductDialog.title')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={4} sx={{px: 1, py: 2}} direction={{xs:'column', md:'row'}}>

                    <Grid item xs={12} md={5}>

                        <TextField
                            label={t('products.newProductDialog.productInternalTitle')}
                            fullWidth={true}
                            value={internalName}
                            variant="standard"
                            onChange={(e) => setInternalName(e.target.value)}
                            inputProps={{ maxLength: 200 }}
                            />
                    </Grid>

                    <Grid item xs={0} md={7} />

                    {/* ----- BASIC CODE a SUFFIX ------ */}

                    <Grid item xs={12} md={12}>
                        <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                            <Grid item xs={6} md={1} >
                                <TextField
                                    fullWidth={true}
                                    label= {t('products.newProductDialog.basicCode')}
                                    disabled={basicCodeVariant === 'new'}
                                    value={basicCode !== null ? basicCode : automaticCode}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <TextField
                                    label= {t('Suffix')}
                                    variant="standard"
                                    value={codeSuffix}
                                    onChange={(e) => setCodeSuffix(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Box display="flex" flexDirection="column" >
                                 {/*   <Typography variant="note">{t('products.newProductDialog.basicProductCode')}</Typography>*/}
                                    <ButtonGroup size="small" variant="outlined" aria-label="outlined button group" color='secondary'>
                                        <Button
                                            onClick={() => handleBasicCodeVariant('new')}
                                            variant={basicCodeVariant === 'new' ? 'contained' : 'outlined'}
                                            sx={{color: basicCodeVariant === 'new' ? 'white' : 'text.primary'}}
                                        >{t('general.buttons.new')}</Button>
                                        <Button
                                            onClick={() => handleBasicCodeVariant('search')}
                                            variant={basicCodeVariant === 'search' ? 'contained' : 'outlined'}
                                            sx={{color: basicCodeVariant === 'search' ? 'white' : 'text.primary'}}
                                        >{t('general.buttons.toSearch')}</Button>
                                    </ButtonGroup>
                                </Box>

                            </Grid>
                            <Grid item xs={4} md={4}>
                                {basicCodeVariant === "search" ?
                                    <Box>
                                        <ProductAutocompleteSearch size="small"  label="Vyhledat produkt" onChange={handleProductSearchAutocomplete} resetValOnClick={false} />
                                        <Typography variant="note">{t('products.newProductDialog.basicCodeVariantNote')}</Typography>
                                    </Box>

                                    : <Typography variant='note' >{t('products.newProductDialog.codeAddNote')}</Typography> }
                            </Grid>
                        </Grid>
                    </Grid>



                    {/* ----- PRODUCER ------ */}



                    <Grid item xs={6} md={4}>

                        <ProducerAllSelect variant="standard" selectedId={producerId} onChange={(id) => setProducerId(id)}  />

                    </Grid>
                    <Grid item xs={6} md={3}>

                        <TextField
                            label={t('products.newProductDialog.producerCode')}
                            fullWidth={true}
                            value={producerCode}
                            onChange={(e) => setProducerCode(e.target.value)}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item xs={0} md={4} />

                    {/* ----- MEASURE UNIT -------  */}

                    <Grid item xs={6} md={2}  >

                       <UnitSelect isDisabled={false} variant="standard" onChange={handleUnitChange} id={basicUnitId} />
                    </Grid>

                    {/* ----- STATE -------  */}

                    <Grid item xs={6} md={2}>

                        <ProductState multiple={false} singleId={stateId} multipleIds={[]} onSingleChange={(val) => setStateId(val)} onMultipleChange={(val) => null} disabled={false} />
                    </Grid>

                    {/* ----- SHOW IN PUBLIC KATALOGUE -------  */}

                    <Grid item xs={6} md={2}>
                        <FormControlLabel
                            value="top"
                            control={  <Switch
                                checked={showInCatalogue}
                                onChange={() => setShowInCatalogue(!showInCatalogue)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="Zobraz v katalogu"
                            labelPlacement="end"
                        />

                    </Grid>

                    {/* ----- CATEGORY -------  */}


                    <Grid item xs={12} md={12}>

                        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems={'flex-start'}>
                            <CategoryTreeSelected tree={categoryTree} showText={true} />
                            <Button onClick={() => setIsCatModalOpen(true)} variant="outlined" color="secondary" startIcon={<CategoryIcon />} sx={{marginTop: 2}}>{t('general.text.categoryTree')}</Button>

                        </Box>


                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions sx={{padding: 3}}>
                <ButtonBasic appVariant={'save'} onClick={handleCreate}>{t('general.buttons.save')}</ButtonBasic>
                <CloseButton startIcon={<CloseIcon />} onClick={() => {props.onClose(true)}}>{t('general.buttons.close')}</CloseButton>
            </DialogActions>
        </Dialog>
            <NewProductCategoryTreeModal tree={[]} matrix={[]} showEditButton={false} onClose={() => setIsCatModalOpen(false)} open={isCatModalOpen} onCatSelect={handleChooseCategory} />
        </>
    )
}

export default NewProductDialog;