import React, {useEffect, useRef} from "react";
import {Box, Grid, Paper, TextField, Typography} from "@mui/material";
import {ButtonBasic} from "../components/shared/ButtonBasic";
import LoginIcon from "@mui/icons-material/Login";
import {useTranslation} from "react-i18next";
import {AlertAppContext} from "../components/alert/AlertAppContext";
import {SubmitHandler, useForm} from "react-hook-form";
import authService from "../services/AuthService";
import {IRecoveryInputs} from "../interfaces/auth/IRecoveryInputs";
import {useLocation, useNavigate, useParams} from "react-router-dom";

export const RecoveryPasswordPage: React.FC = () => {

    const {t} = useTranslation();
    const appAlert = React.useContext(AlertAppContext);
    const {setValue, watch, register, handleSubmit, formState: {errors}} = useForm<IRecoveryInputs>();


    const [isSaving, setIsSaving] = React.useState<boolean>(false);

    const password = useRef({});
    password.current = watch("password", "");

    const params = useParams();


    const navigate = useNavigate();


    useEffect(() => {



        if(params.guid)
        {
            setValue('guid', params.guid);
        }

    }, [params])
    const submitForm: SubmitHandler<IRecoveryInputs> = async (data: IRecoveryInputs) => {


        setIsSaving(true);

        await authService.recovery(data).then((res) => {

            appAlert.showAlert({severity: "success", message: 'Heslo bylo změněno. Můžete se přihlásit'});


            navigate('/login', {replace: true});

        }).catch((err) => {
            appAlert.showAlert({severity: "error", message: err.response.data.message});
        }).finally(() => {
            setIsSaving(false);
        });
    }

    return(
        <Grid container component="main" justifyContent={"center"} sx={{height: '100vh'}}>


            <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img alt='logo' style={{width: 100}} src={"/assets/images/logo.png"}/>
                    <Typography component="h1" variant="h5">
                        Obnova hesla
                    </Typography>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Box sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                autoFocus
                                type='password'
                                label='Heslo'
                                placeholder='Zadejte nové heslo'
                                variant="outlined"
                                {...register('password', {required: true})}
                                error={errors.password ? true : false}
                                helperText={errors.password && t('general.validation.required')}
                            />
                            <TextField
                                margin="normal"
                                required
                                autoComplete="current-password"
                                label='Heslo potvrzení'
                                placeholder='Zadejte heslo pro potvrzení'
                                type='password'
                                variant="outlined"
                                fullWidth {...register('password', {required: true})}
                                {...register('passwordConfirm', {
                                    required: t('general.validation.required'),
                                    validate: value =>
                                        value === password.current || "Hesla se neshodují"
                                })}
                                error={errors.passwordConfirm ? true : false}
                                helperText={errors.passwordConfirm && errors.passwordConfirm.message}

                            />
                            <ButtonBasic color={'primary'} appVariant={"save"} type={"submit"} isLoading={isSaving}
                                         icon={<LoginIcon/>} fullWidth={true}>Obnovit heslo</ButtonBasic>

                        </Box>
                    </form>
                </Box>
            </Grid>

        </Grid>
    );
}