import {NetworkService} from "./NetworkService";
import {IErpStore} from "../interfaces/erpStore/IErpStore";

export class ErpService {
    async getErpStores(countryCode:string):Promise<IErpStore[]> {
      const {data} =  await NetworkService.newConnection().get<IErpStore[]>(`erpStore?countryCode=${countryCode}`);

        return data;
    }
}

const erpService = new ErpService();
export default erpService;