import React, {ReactNode} from "react";
import {Box, Paper, Typography, useTheme} from "@mui/material";
import {alpha} from "@mui/material/styles";

interface IPaperBasic {
    children?: ReactNode | string | null,
    title?: string | null,
    paperMargins?: string | null
    boxPadding?: number
}
export const PaperBasic: React.FC<IPaperBasic> = (props) => {
    const theme = useTheme();
    return(
        <Paper variant={'outlined'} sx={{
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            margin: props.paperMargins ? props.paperMargins : null,
            paddingBottom: 2

        }}>
            {props.title ?
                <Typography variant={'h5'} component={'h1'} align={'left'} sx={{
                    fontWeight: 'bold',
                    //color: alpha('#525252',0.8),
                    color: alpha('#675355',0.95),
                    width: '100%',
                    borderBottom: '1px solid #E0E0E0',
                    paddingLeft: '30px',
                    paddingTop: '20px',
                    paddingBottom: '15px',
                    marginBottom: '10px',

                    backgroundColor: theme.palette.lightBlue.light,
                }}>
                    {props.title ? props.title : null}
                </Typography>


                : null}



            <Box sx={{padding: props.boxPadding ?? 0}}>
                {props.children}
            </Box>
        </Paper>
    );
}