import React from "react";
import {Box, Container, Grid, TextField} from "@mui/material";
import {PaperBasic} from "../shared/PaperBasic";
import {ButtonBasic} from "../shared/ButtonBasic";
import {useTranslation} from "react-i18next";
import {ICustomerFilter} from "../../interfaces/customer/ICustomerFilter";
import customerService from "../../services/CustomerService";
import Customer from "../../models/customer/Customer";
import {CustomerFilterTable} from "./CustomerFilterTable";

export const CustomerFilter: React.FC = () => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string|null>(null);
    const [customers, setCustomers] = React.useState<Customer[]>([]);
    const handleFilter = () => {
        setIsLoading(true);
        let f: ICustomerFilter = {
            name: name,
            fullText: null
        }
        customerService.filter(f).then((res) => {
            setCustomers(res);
        }).finally(() => {
            setIsLoading(false);
        })

    }
    return (
        <>
            <Container sx={{
                marginBottom: '25px'
            }}>
                <PaperBasic title={t('customers.customers')} paperMargins={'20px 0px 30px 0px'}>
                    <Box sx={{
                        px:3
                    }}>
                        <Grid container spacing={1.5} p={3}>
                            <Grid item xs={12} md={4}>
                                <TextField size={'small'} label={t('general.title')} variant={"outlined"}
                                   onChange={(e) => setName(e.target.value)}        fullWidth={true}/>
                            </Grid>
                            <Grid item xs={12} sx={{
                                display: {md: 'flex'},
                                justifyContent: 'left'
                            }}>
                                <ButtonBasic onClick={handleFilter} isLoading={isLoading}   appVariant="filter" type="button">
                                    {t('general.buttons.toFilter')}
                                </ButtonBasic>
                            </Grid>
                        </Grid>
                    </Box>
                </PaperBasic>

            </Container>
            <CustomerFilterTable data={customers} isLoading={isLoading} />
        </>
    );
}