import React from "react";
import {Box, CircularProgress, Grid, Paper, Typography, useTheme} from "@mui/material";
import {Order} from "../../models/order/Order";
import {NullableString} from "../../interfaces/NullableStringType";
import {useTranslation} from "react-i18next";
import {OrderItem} from "../../models/order/OrderItem";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from '@mui/material/styles';

interface IProps{
    order: Order|null;
    loaded: boolean;
}

interface IKeyProps {
    k:NullableString;
    v:NullableString|number|undefined;
    direction: 'column'|'row';
    keyWidth?: number|null;
    valueBold?: boolean;
}

interface IItemProps{
    item: OrderItem;
    index: number;
}

export const OrderDetail:React.FC<IProps> = (props) => {

    const theme = useTheme();
    const {t} = useTranslation();
    const elev = 2;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.grey[200],
            padding: '11px 10px',
            fontSize: 14,
            color: '#4f4f4f'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding: '8px 10px',
            color: '#4f4f4f'
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    //todo: O merna jednotka previd z ID na kod
    const Item:React.FC<IItemProps> = (props) => {
        const i = props.item;
        return (
            <StyledTableRow
                key={'tRow_' + props.index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <StyledTableCell align="right">{props.index + 1}</StyledTableCell>
                <StyledTableCell align="right">{i.fullCode}</StyledTableCell>
                <StyledTableCell align="left">{i.name}</StyledTableCell>
                <StyledTableCell align="right">{i.amount}</StyledTableCell>
                <StyledTableCell align="center">{i.unitLocale.code}</StyledTableCell>
                <StyledTableCell align="right">{i.sellPrice}</StyledTableCell>
                <StyledTableCell align="right">{i.sellVat}</StyledTableCell>
                <StyledTableCell align="right">{i.sellPriceVat}</StyledTableCell>
                <StyledTableCell align="right">{i.sumSellPrice}</StyledTableCell>
                <StyledTableCell align="right">{i.sumSellPriceVat}</StyledTableCell>
            </StyledTableRow>
        );
    }


    const KeyValue:React.FC<IKeyProps> = (props)  => {
        return (
            <Box  display="flex" flexDirection={props.direction} alignItems="center">
                {props.k ?
                <Box sx={{marginRight: 1, width: props.keyWidth ?? 120}}>
                     <Typography variant="note">{props.k}</Typography>
                </Box>
                    : null}
                {props.v || props.v === 0  ?
                    <Box sx={{fontWeight: props.valueBold ? 'bold' : 'normal'}}>
                    <Typography variant="note" >{props.v}</Typography>
                </Box>
                    : null }
            </Box>
        );
    }

    if(!props.loaded){
        return (
          <Box p={2}>
              <CircularProgress />
          </Box>
        );
    }

    if(props.order === null) return null;

    const o = props.order;

    //todo: O preklady
    return(
        <Box p={2} sx={{backgroundColor: theme.palette.grey[100]}}>
            <Grid container spacing={2} alignItems="flex-start" >
                <Grid item xs={12} sm={12} md={3}>
                    <Paper elevation={elev} sx={{padding: 1.5}} >
                        <KeyValue direction='row' k={'Číslo objednávky:'} v={o.orderNumber} valueBold={true} />
                        <KeyValue k={'ID intranet:'} direction={'row'} v={o.id} />
                        <KeyValue k={'Datum vytvoření:'} direction={'row'} v={o.createdAt} />
                        <KeyValue k={'Vytvořena:'} direction={'row'} v={o.userCreated.fullName} />
                        <KeyValue k={'Sklad:'} direction={'row'} v={o.erpStore?.name + " [" + o.erpStore?.note + "]"} />
                    </Paper>

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Paper elevation={elev} sx={{padding: 1.5}} >
                        <KeyValue direction='row' k={null} v={'Zákazník:'}  />
                        <KeyValue k={null} direction={'row'} v={o.customer?.name} valueBold={true} />
                        <KeyValue keyWidth={80} k={'Kód ERP:'} direction={'row'} v={o.customer?.code} />
                        <KeyValue keyWidth={80} k={'IČ:'} direction={'row'} v={o.customer?.idNumber} />

                    </Paper>

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Paper elevation={elev} sx={{padding: 1.5}} >
                        <KeyValue direction='row' k={null} v={'Adresa (pobočka):'}  />
                        <KeyValue k={null} direction={'row'} v={o.customerAddress?.name ?? 'N/A'} valueBold={true} />
                        <KeyValue keyWidth={80} k={null} direction={'row'} v={o.customerAddress?.street} />
                        <KeyValue keyWidth={80} k={null} direction={'row'} v={(o.customerAddress?.city + ' ' + o.customerAddress?.postal)} />

                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={elev} sx={{padding: 1.5}}>
                        <KeyValue k={null} v={'Položky:'} direction={'row'} />
                        <TableContainer component={Box}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{backgroundColor: theme.palette.grey[100]}}>
                                    <TableRow>
                                        <StyledTableCell align="right">#</StyledTableCell>
                                        <StyledTableCell align="right">Kód</StyledTableCell>
                                        <StyledTableCell align="left">Název</StyledTableCell>
                                        <StyledTableCell align="right">Množství</StyledTableCell>
                                        <StyledTableCell align="center">MJ</StyledTableCell>
                                        <StyledTableCell align="right">Cena MJ</StyledTableCell>
                                        <StyledTableCell align="right">DPH</StyledTableCell>
                                        <StyledTableCell align="right">Cena MJ s DPH</StyledTableCell>
                                        <StyledTableCell align="right">Cena celkem</StyledTableCell>
                                        <StyledTableCell align="right">Cena celkem s DPH</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {o.items.length > 0 ?
                                        o.items.map((item, index) => <Item key={'oItem_'+ index} item={item} index={index} />)
                                        : null
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box display="flex" flexDirection="column">


                        </Box>
                    </Paper>


                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Paper elevation={elev} sx={{padding: 1.5}} >
                        <KeyValue keyWidth={160} k={'Počet položek:'} direction={'row'} v={o.items.length} />
                        <KeyValue direction='row' keyWidth={160} k={'Cena celkem'} v={o.sumSellPrice}  />
                        <KeyValue direction='row' keyWidth={160} k={'Cena celkem s DPH'} v={o.sumSellPriceVat} />



                    </Paper>

                </Grid>
            </Grid>

        </Box>

    )
}