import React, {ReactNode} from "react";
import {Box} from "@mui/material";
import {Popover} from "@mui/material";
import {IMainPhoto} from "../../interfaces/product/IMainPhoto";

interface IProps {
    children?: ReactNode | string | null,
    product?: IMainPhoto | null,
    size: "small"| "medium" | "large"

}
export const MainProductPhoto: React.FC<IProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    let height = '52px';
    switch (props.size){
        case "small":
            height = '52px';
            break;
        case "medium":
            height = '90px';
            break;
        case "large":
            height = '150px';
            break
        default:
            height = '52px';

    }


    return(
        <div>
            <Box
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <img style={{borderRadius: 5}} src={props.product?.link} height={height} alt={'Fotografie produktu'}/>
            </Box>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <img src={props.product?.link} alt={'Fotografie produktu'}/>
            </Popover>
        </div>
    );
}