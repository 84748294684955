import {ICategory} from "../../interfaces/category/ICategory";
import {NullableString} from "../../interfaces/NullableStringType";
import {ICategoryLocale} from "../../interfaces/category/ICategoryLocale";
import CategoryLocale from "./CategoryLocale";
import i18n from "../../translations/i18n";
import {IValid} from "../../interfaces/IValid";

const lang = i18n.language;


class Category implements ICategory{
    devName: NullableString;
    id: number;
    locales: CategoryLocale[];
    note: NullableString;
    parentId: number;
    name: string;
    parentIndex: null|number

    constructor(j:ICategory) {
        this.devName = j.devName;
        this.id = j.id;
        this.note = j.note;
        this.parentId = j.parentId;
        this.locales = j.locales.map(x => new CategoryLocale(x));
        this.name = this.resolveLocaleName(j.locales);
        this.parentIndex = null;

    }

    resolveLocaleName(locales:ICategoryLocale[]){
        let name = "N/A";
        let a = locales.filter(f => f.langCode === lang);
        if (a.length === 1)
            name = a[0].name;

        return name;
    }

    isValidOnCreate():IValid{
        let v:IValid = {
            isValid: true,
            message: ""
        }
        if(this.locales.length === 0){
            v.message = "Vyplňte alespoň jednu lokalizaci";
            v.isValid = false;
            return v;
        }

        this.locales.forEach(function (element) {
            let vl  = element.isValid();
            if(!vl.isValid){
                v.isValid = false;
                v.message += " " + vl.message;
            }
        })

        return v;
    }



}

export default Category;