import {NetworkService} from "./NetworkService";
import {ISearchResponse} from "../interfaces/search/ISearchResponse";

const controller = "/search";

class SearchService {

    async search(value: string): Promise<ISearchResponse[]> {

        const {data} = await NetworkService.newConnection().get<ISearchResponse[]>(`${controller}?value=${value}&take=3`);
        return data;
    }
}

const searchService = new SearchService();

export default searchService;