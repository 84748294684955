import axios, {Axios} from "axios";
import {IBasicException} from "../interfaces/IBasicException";

const baseURL : string|undefined = process.env.REACT_APP_CATALOG_WEB_API_ENDPOINT;


export class NetworkService {



    static newConnection(): Axios {

        var instance =  axios.create({
            responseType: "json",
            baseURL: baseURL,
            timeout: 60000,
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
                'Accept-Language': localStorage.getItem('lang'),
                    'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })

       instance.interceptors.response.use(response => {
            return response;
        }, error => {


            if (error.response.status === 401) {
                localStorage.removeItem("token");
                window.location.reload();
            }

           let e: IBasicException = {
               message: '',
               errors: null
           };

            if(error.response && error.response.data){
                e.message = error.response.data.message ?? '';



                e.errors = error.response.data.errors;
            }
   
           return Promise.reject(e);
        });



        return instance;
    }



}