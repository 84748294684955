import * as React from 'react';
import {Autocomplete, Box, Chip, darken, InputAdornment, lighten, MenuItem, MenuList, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import searchService from "../../services/SearchService";
import {ISearchResponse} from "../../interfaces/search/ISearchResponse";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {SearchType} from "../../types/SearchType";

export default function SearchBar() {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [items, setItems] = React.useState<ISearchResponse[]>([]);
    const [val, setVal] = useState<ISearchResponse | null>(null);


    useEffect(() => {

        setItems([]);

        if (!searchTerm) {
            return
        };

        searchService.search(searchTerm).then((res) => {
            setItems(res);
        });


    }, [searchTerm]);


    const handleOptionSelect = (event: React.SyntheticEvent<Element, Event>, value: ISearchResponse | null, reason: string) => {
        setVal(value);

        if (reason === 'clear') {
            setItems([]);
            return;
        }

        if (!value) return;


        switch (value.searchType) {
            case SearchType.Product:
                navigate("/products-page/" + value.id);
                break;
            case SearchType.Producer:
                navigate("/producers-page/" + value.id);
                break;
            case SearchType.Document:
                navigate("/documents-page/" + value.id);
                break;
        }


        // props.onChange(value);

    };


    return (<Autocomplete
        noOptionsText={'Začněte psát pro vyhledávání'}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        forcePopupIcon={false}
        options={items}
        getOptionLabel={(option: ISearchResponse) => option.name}
        groupBy={(option) => option.type}
        onInputChange={(event, value) => setSearchTerm(value)}
        style={{width: 600}}
        renderInput={(params) => (

            <TextField

                {...params}
                size={'small'}
                InputLabelProps={{shrink: false}}
                variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    style: { height: 40},
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon  />
                        </InputAdornment>
                    )
                }}

            />

        )}

        value={val}
        onChange={handleOptionSelect}
        renderGroup={(params) => (
            <Box key={params.key}>
                <Typography fontSize={15} fontStyle="italic" p={1} color={'primary'}>
                    {params.group}
                </Typography>
                <MenuList>
                    {params.children}
                </MenuList>
            </Box>
        )}


    />);

}