import React, {useEffect} from 'react';
import {Box} from "@mui/material";
import {ProductFilter} from "../../components/product/ProductFilter";
import {BreadcrumbsBase} from "../../components/breadcrumbs/BreadcrumbsBase";
import {IBreadcrumbs} from "../../interfaces/breadcrumbs/IBreadcrumbs";
import { useParams } from 'react-router-dom';
export const ProductsPage: React.FC = (props) => {

    const [selectedId, setSelectedId] = React.useState<number|null>(null);

    const params = useParams();

    useEffect(() => {

        if(params.id)
        {
            setSelectedId(parseInt(params.id));
        }

    }, [params])





    const breadcrumbs: IBreadcrumbs = {
        data: [
            {
                isLink: false,
                name: "Katalog produktů",
                to: "/products-page"

            }
        ]
    }

    return(
        <Box>
            <BreadcrumbsBase breadcrumbs={breadcrumbs} />
            <Box>
                <ProductFilter selectedId={selectedId}/>
            </Box>
        </Box>
    );

}