import React from 'react';
import {
    Box,
    Grid,
    TextField, Typography,
} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import authService from "../../services/AuthService";
import {useAuth} from "../../context/AuthProvider";
import {AlertAppContext} from "../alert/AlertAppContext";
import {ILogin} from "../../interfaces/auth/ILogin";
import {ButtonBasic} from "../shared/ButtonBasic";
import LoginIcon from '@mui/icons-material/Login';
import {useLocation, useNavigate} from "react-router-dom";

interface IProps {
    onForgotPassword(): void
}

export const Login = (props: IProps) => {

    const {t} = useTranslation();
    const appAlert = React.useContext(AlertAppContext);
    const {register, handleSubmit, formState: {errors}} = useForm<ILogin>();

    const {setToken} = useAuth();

    const [isSaving, setIsSaving] = React.useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || "/";
    const submitForm: SubmitHandler<ILogin> = async (data: ILogin) => {
        console.log(data);

        setIsSaving(true);

        await authService.login(data).then((res) => {
            console.log(res);
            setToken(res.token);
            navigate(from, {replace: true});
        }).catch((err) => {
            console.log(err);
            console.log(err);
            appAlert.showAlert({severity: "error", message: err.title});
        }).finally(() => {
            setIsSaving(false);
        });
    }

    return (

        <>


            <Typography component="h1" variant="h5">
                Přihlášení
            </Typography>
            <form onSubmit={handleSubmit(submitForm)}>
                <Box sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        autoComplete="email"
                        autoFocus
                        label='Přihlašovací jméno'
                        placeholder='Zadejte přihlašovací jméno'
                        variant="outlined" {...register('username', {required: true})}
                        error={errors.username ? true : false}
                        helperText={errors.username && t('general.validation.required')}
                    />
                    <TextField
                        margin="normal"
                        required
                        id="password"
                        autoComplete="current-password"
                        label='Heslo'
                        placeholder='Zadejte heslo'
                        type='password'
                        variant="outlined"
                        fullWidth {...register('password', {required: true})}
                        error={errors.password ? true : false}
                        helperText={errors.password && t('general.validation.required')}
                    />
                    <ButtonBasic color={'primary'} appVariant={"save"} type={"submit"} isLoading={isSaving}
                                 icon={<LoginIcon/>} fullWidth={true}>Přihlásit se</ButtonBasic>
                    <Grid container mt={2}>
                        <Grid item xs  sx={{
                            '&:hover': {
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            },
                        }}
                        >
                                    <span onClick={props.onForgotPassword}>
                                        Zapomenuté heslo
                                    </span>

                        </Grid>
                    </Grid>
                </Box>
            </form>
        </>

    )


}

export default Login