import React from "react";
import {Box, Chip, Dialog, DialogActions, DialogContent, Grid, Typography} from "@mui/material";
import {IDialogBase} from "../../interfaces/IDialogBase";
import {INotice} from "../../interfaces/noticeBoard/INotice";
import {CloseButton} from "../../theming/customButtons";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";

interface IProps extends IDialogBase {
    notice: INotice
}
export const NoticeReadDialog: React.FC<IProps> = (props) => {

    const {t } = useTranslation();


    return(
        <Dialog
            open={props.open}
            onClose={props.onClose} fullWidth={true} maxWidth={"md"}>
            <DialogContent>
                <Grid container spacing={2} padding={1}>
                    <Grid item xs={12}>
                        <Chip variant='filled'
                              sx={{marginRight: 1,
                                  bgcolor: 'primary.dark',
                                  fontWeight: 'bold'}}
                              color='warning'
                              label={props.notice.createdAt} />
                        {props.notice.isFromAdmin ? <Chip size='medium'  label={'ADMIN'} variant='filled' color='warning' /> : null}

                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"h4"}>{props.notice.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div dangerouslySetInnerHTML={{__html: props.notice.htmlContent ?? ''}}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box sx={{padding: 1}}>
                    <CloseButton
                        startIcon={<CloseIcon/>}
                        onClick={() => {
                        props.onClose(false)
                    }}>
                        {t('general.buttons.close')}
                    </CloseButton>
                </Box>
            </DialogActions>

        </Dialog>
    )
}