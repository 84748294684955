import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography, Box,
} from '@mui/material';
import {IBasicConfirmDialog} from "../../interfaces/IBasicConfirmDialog";
import {ButtonBasic} from "./ButtonBasic";
import CheckIcon from '@mui/icons-material/Check';
import FeedbackIcon from '@mui/icons-material/Feedback';

interface IProps {
    data:     IBasicConfirmDialog
    onClose: () => void
    onConfirm: () => void
    open: boolean
}


export const BasicConfirmDialog:React.FC<IProps> = (props) => {
    const handleConfirm = () => {
        props.onConfirm();
        props.onClose();
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-content"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle sx={{backgroundColor: '#f3ebe6' }} id="confirm-dialog-title">
                <Box display="flex" alignItems="center">
                    <FeedbackIcon color='warning' sx={{marginRight: 1}} /><Typography  variant="h3"> {props.data.title}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent >
                <Typography variant='body1' style={{marginTop: 20, marginBottom: 20, color: 'grey'}}  id="confirm-dialog-content">{props.data.message}</Typography>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <ButtonBasic onClick={props.onClose} appVariant="close" />

                <Button startIcon={<CheckIcon />} onClick={handleConfirm} variant="contained" color="success" autoFocus>
                    Potvrdit
                </Button>
            </DialogActions>
        </Dialog>
    );
}



