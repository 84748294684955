import React from "react";
import {Box, Button, CircularProgress, Container} from "@mui/material";
import {NoticesCont} from "./NoticesCont";
import {ProtectedComponent} from "../ProtectedComponent";
import {NoticeBoardUpsertDialog} from "./NoticeBoardUpsertDialog";
import {INotice} from "../../interfaces/noticeBoard/INotice";
import noticeBoardService from "../../services/NoticeBoardService";
import {useTranslation} from "react-i18next";

export const NoticeBoardContainer: React.FC = () => {
    const {t, i18n} = useTranslation();
    const [open, setOpen] = React.useState(false);

    const [loaded, setLoaded] = React.useState(false);
    const [notices, setNotices] = React.useState<INotice[]>([]);
    const [isNextData, setIsNextData] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const take = 12;

    const refresh = () => {
        setLoaded(false);
    }

    const handleDelete = (id:number) => {
        noticeBoardService.delete(id).then(() => {
            refresh();
        }).catch((err) => {
            console.log(err);
        });
    }

    const handleLoadNext = () => {
        let p = (page + 1);
        noticeBoardService.filter({page: p, take: take}).then((res) => {
            let a = [...notices];
            setNotices(a.concat(res.items));
            setIsNextData(res.isNextData);
            setPage(p);
            setLoaded(true);

        })
    }


    React.useEffect(() => {
        if(!loaded){
            noticeBoardService.filter({page: page, take: take}).then((res) => {
                setNotices(res.items);
                setIsNextData(res.isNextData);
                setLoaded(true);

            })

        }
    }, [loaded]);



    return(
        <Container>
            <ProtectedComponent role={['112']} >
                <Box padding={1} paddingBottom={2} sx={{display: 'flex'}} >
                    <Button onClick={() => setOpen(true)} variant='contained'>{t('dashboard.buttons.newMessage')}</Button>
                </Box>
            </ProtectedComponent>
            {!loaded ?  <Box><CircularProgress /></Box>
              : <NoticesCont loadNext={handleLoadNext} page={page} onDelete={(id:number) => handleDelete(id)} notices={notices} isNextData={isNextData} />
            }
            <NoticeBoardUpsertDialog refresh={() => refresh()} id={0} onClose={() => setOpen(false)} open={open} />
        </Container>
    )
}
