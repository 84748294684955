import React, {useEffect} from "react";
import {IDialogBase} from "../../../interfaces/IDialogBase";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {Photo} from "./Photo";
import {useTranslation} from "react-i18next";
import {SubmitHandler, useForm} from "react-hook-form";
import {ButtonBasic} from "../../shared/ButtonBasic";
import CloseIcon from "@mui/icons-material/Close";
import {CloseButton} from "../../../theming/customButtons";
import {AlertAppContext} from "../../alert/AlertAppContext";
import {IPhotoInputs} from "../../../interfaces/media/photo/IPhotoInputs";
import photoService from "../../../services/PhotoService";
import {IPhotoDetail} from "../../../interfaces/media/photo/IPhotoDetail";
import {IBasicException} from "../../../interfaces/IBasicException";


interface IProps extends IDialogBase {
    id: number,
    refresh: () => void

}

export const PhotoUpsertDialog: React.FC<IProps> = (props) => {

    const {t} = useTranslation();
    const {
        clearErrors,
        setError,
        setValue,
        register,
        handleSubmit,
        formState: {errors},
        control
    } = useForm<IPhotoInputs>();
    const [isSaving, setSaving] = React.useState(false);
    const [isDeleting, setDeleting] = React.useState(false);
    const appAlert = React.useContext(AlertAppContext);
    const [loaded, setLoaded] = React.useState<boolean>(props.id === 0);
    const [photo, setPhoto] = React.useState<IPhotoDetail | null>(null);
    const submitForm: SubmitHandler<IPhotoInputs> = async (agent: IPhotoInputs) => {

        setSaving(true);

        if(props.id === 0){
            photoService.upload(agent).then(() => {
                props.onClose(true);
                appAlert.showAlert({severity: "success", message: t('general.form.success')});
                props.refresh();
            }).catch((err:IBasicException) => {

                appAlert.showAlert({severity: "error", message: err.message});
            }).finally(() => {
                setSaving(false);
            });
        }
        if(props.id > 0){
            photoService.update(agent.products, props.id).then(() => {
                props.onClose(true);
                appAlert.showAlert({severity: "success", message: t('general.form.success')});
                props.refresh();
            }).catch((err:IBasicException) => {

                appAlert.showAlert({severity: "error", message: err.message});
            }).finally(() => {
                setSaving(false);
            });
        }


    }

    const handleDeleteButtonClick = () => {
        if(!window.confirm("Opravdu chcete fotku smazat?")){
            return;
        }
        setDeleting(true);
        photoService.delete(props.id).then(() => {
            props.onClose(true);
            props.refresh();
            appAlert.showAlert({severity: "success"});
        }).catch((err) => {
            appAlert.showAlert({severity: "error"});
        }).finally(() => {
            setDeleting(false);
        });
    }

    useEffect(() => {
        if (!loaded && props.id > 0) {
            photoService.detail(props.id).then((response) => {
                console.log(response);
                setPhoto(response);
                setLoaded(true);
            }).catch((err) => {
                console.log(err);
                appAlert.showAlert({severity: "error"});
            });
        }
    }, [appAlert, loaded, props.id]);

    if (!loaded) {
        return (
            <Dialog
                open={props.open}
                onClose={props.onClose} fullWidth={true} maxWidth={"lg"}>
                <DialogTitle sx={{
                    p: 0
                }}>
                    <Typography variant={'paperHeading'} py={1}>
                        {props.id > 0 ? t('photos.photoDetail') : t('photos.add')}

                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <CircularProgress/>

                </DialogContent>
            </Dialog>)
    }


    return (
        <Dialog
            open={props.open}
            onClose={props.onClose} fullWidth={true} maxWidth={"lg"}>
            <form onSubmit={handleSubmit(submitForm)}>
                <DialogTitle sx={{
                    p: 0
                }}>
                    <Typography variant={'paperHeading'} py={1}>
                        {props.id > 0 ? t('photos.photoDetail') : t('photos.add')}

                    </Typography>
                </DialogTitle>
                <DialogContent>

                    <Photo photo={photo} id={props.id} clearErrors={clearErrors} setError={setError} reg={register}
                           con={control} err={errors} set={setValue}/>

                </DialogContent>
                <DialogActions>
                    <CloseButton startIcon={<CloseIcon/>} onClick={() => {props.onClose(false)}}>
                        {t('general.buttons.close')}
                    </CloseButton>
                    {props.id > 0 ?
                        <ButtonBasic onClick={handleDeleteButtonClick} appVariant={"delete"} isLoading={isDeleting}/>
                        : null}

                    <ButtonBasic appVariant={"save"} type={"submit"} isLoading={isSaving}/>

                </DialogActions>
            </form>
        </Dialog>
    );
}