
import IUserFilter from "../../interfaces/user/IUserFilter";

class UserFilterModel implements IUserFilter{
    fulltext: string
    onlyActive: boolean
    orderBy: string = 'name'

    constructor(fulltext: string, onlyActive: boolean, orderBy?: string) {
        this.fulltext = fulltext
        this.onlyActive = onlyActive

        if(orderBy)
            this.orderBy = orderBy

    }

}

export default UserFilterModel;