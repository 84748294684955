import {Notice} from "./Notice";
import {IBaseScrollPaginationResponse} from "../../interfaces/IBaseScrollPaginationResponse";
import {INotice} from "../../interfaces/noticeBoard/INotice";

export class NoticeScrollPaginationResponse {
    isNextData:boolean
    items: Notice[]

    constructor(j:IBaseScrollPaginationResponse<INotice>) {
        this.isNextData = j.isNextData;
        this.items = j.items.map(x => new Notice(x));
    }

}