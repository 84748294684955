import React, {ReactNode} from "react";
import {Button, CircularProgress} from "@mui/material";
import TButtonColors from "../../interfaces/TButtonColors";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import SyncIcon from '@mui/icons-material/Sync';
interface IButtonBasic {
    children?: ReactNode | string | null,
    isLoading?: boolean,
    appVariant?: 'save' | 'delete' | 'filter' | 'close' | 'sync',
    customColor?: string,
    onClick?: () => void,
    type?: "submit" | "button",
    fullWidth? :boolean,
    icon? :React.ReactNode,
    text? :string,
    form? :string,
    color?: TButtonColors,
    mr? :number //margin right
}


export const ButtonBasic: React.FC<IButtonBasic> = (props) => {

    const {t} = useTranslation();
    const isLoading = (props.isLoading) ? props.isLoading : false;
    const type = (props.type) ?  props.type : "button";
    const full = props.fullWidth ? props.fullWidth : false;
    const icon = props.icon ? props.icon : null;

    let btnColor:TButtonColors;
    let btnIcon:ReactNode | undefined;
    let btnText = '';
    let btnVariant:"outlined" | "contained" | "text" | undefined;

    switch (props.appVariant) {
        case 'save':
            btnVariant = 'contained'
            btnColor = props.color ? props.color : 'success';
            btnIcon = <SaveIcon />;
            btnText = t('general.buttons.save');
            break;
        case 'delete':
            btnVariant = 'contained'
            btnColor = 'error';
            btnIcon = <DeleteIcon />;
            btnText = t('general.buttons.delete');
            break;
        case 'filter':
            btnVariant = 'contained'
            btnColor = 'primary';
            btnIcon = <FilterAltIcon />;
            btnText = t('general.buttons.toFilter');
            break;
        case 'close':
            btnVariant = 'outlined'
            btnColor = 'error';
            btnIcon = <CloseIcon />;
            btnText = t('general.buttons.close');
            break;
        case 'sync':
            btnVariant = 'contained'
            btnColor = 'info';
            btnIcon = <SyncIcon />;
            btnText = t('general.buttons.sync');
            break;
        default:
            btnVariant = 'contained'
            btnColor = props.color ?? 'primary';
            btnIcon = '';
            btnText = '';
            break;
    }

    const sx = {
        backgroundColor: props.customColor ? props.customColor : null,
        cursor: 'pointer',
        marginRight: props.mr ?? 0
    }

    return(



        <Button variant={btnVariant}
                //form={props.form ? props.form : ''}
                {...(props.form && {form: props.form})}
                type={type}
                color={btnColor}
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={24} sx={{marginRight: 0, color: 'white'}}/> : (icon ? icon : btnIcon)}
                onClick={props.onClick}
                fullWidth={full}
                sx={sx}
        >{props.children ? props.children : btnText}

        </Button>

    );
}