import {CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import React, {useEffect} from "react";
import categoryService from "../../services/CategoryService";
import {useTranslation} from "react-i18next";
import {ICategorySelectItem} from "../../interfaces/category/ICategorySelectItem";

interface IProps {
    selectedcatId: number,
    handleChange: (id:number) => void,
    excludeCategoryId: number //logika abych nemohl udelat vnoreni kategorie samo do sebe, pokud nechci brat v potaz dam 0
}
export const CategorySelect: React.FC<IProps> = (props) => {
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const {t, i18n} = useTranslation();

    const [categories, ssetCategories] = React.useState<ICategorySelectItem[]>([]);


    const handleChange = (event: SelectChangeEvent) => {
        props.handleChange(parseInt(event.target.value as string));
    };

    useEffect(() => {
        if(!loaded){
            categoryService.get().then((res) => {
                let catSelect = categoryService.returnSelectItem(res, i18n.language);
                ssetCategories(catSelect);
            }).finally(() => {
                setLoaded(true);
            })
        }

    }, [i18n.language, loaded]);

    if(!loaded){
        return(
            <CircularProgress />
        )
    }


    return(

        <FormControl fullWidth >
            <InputLabel id="parentCat-label"> {t('categories.categoryUpsertDialog.parentCategory')}</InputLabel>
            <Select
                labelId="parentCat-label"
                id="parentCat-select"
                value={props.selectedcatId.toString()}
                label="Nadřazená kategorie"
                onChange={handleChange}>
                <MenuItem value={"0"} >{t('categories.categoryUpsertDialog.noParentCategory')}</MenuItem>
                {categories.map((x,i) => {
                    if(x.id === props.excludeCategoryId)
                        return null;
                    return(
                        <MenuItem key={'pCatIt_' + x.id} value={x.id.toString()}>[{(x.level + 1)}] {x.name}</MenuItem>
                        )
                })}

            </Select>
        </FormControl>

    )
}