import React, {useEffect} from "react";
import {MenuItem, Select, InputLabel, CircularProgress, FormControl} from "@mui/material";
import {useTranslation} from "react-i18next";
import documentTypeService from "../../../services/DocumentTypeService";
import {IDocumentType} from "../../../interfaces/media/document/IDocumentType";


interface IProps {
    showAllOption: boolean,
    hasError: boolean
    onChange(item: IDocumentType|null): void
    selectedCode: string
}

export const DocumentsTypeSelect: React.FC<IProps> = ({showAllOption, hasError, onChange, selectedCode}) => {

    const {t} = useTranslation();
    const [types, setTypes] = React.useState<IDocumentType[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {

        if (loaded) return;

        documentTypeService.types().then((res) => {
            setTypes(res);
        }).catch((err) => {

        }).finally(() => setLoaded(true));

    }, [loaded]);

    if(!loaded)
        return <CircularProgress />

    const handleChange = (code: string) => {
        let item = types.find(x=>x.code === code);


        onChange(item ?? null);
    }

    return (

        <FormControl variant="outlined" fullWidth>


            <InputLabel shrink id="document-type-id">{t("documents.type")}</InputLabel>

            <Select
                notched
                labelId="document-type-id"
                fullWidth
                label={t("documents.type")}
                value={selectedCode}
                error={hasError}
                onChange={(e => {
                    handleChange(e.target.value)
                })}
                displayEmpty={showAllOption}
            >
                {showAllOption && <MenuItem key={-1} value="">{t("general.all")}</MenuItem>}
                {types.map((x) => {
                    return (<MenuItem key={x.code} value={x.code}>{x.name}</MenuItem>);
                })}
            </Select>
        </FormControl>
    );
}