import React from 'react';
import {Box} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {DashBoardPage} from "../pages/dashboard/DashBoardPage";

import {ProductsPage} from "../pages/catalogue/ProductsPage";
import {CategoryPage} from "../pages/catalogue/CategoryPage";
import {ProducersPage} from "../pages/catalogue/ProducersPage";
import {SuppliersPage} from "../pages/catalogue/SuppliersPage";
import {CustomersPage} from "../pages/catalogue/CustomersPage";
import {DocumentsPage} from "../pages/media/DocumentsPage";
import {PhotosPage} from "../pages/media/PhotosPage";
import MainNav from "./header/MainNav";
import {drawerWidth} from "../theming/dtTheme";
import {DevComponentsPage} from "../pages/dev/DevComponentsPage";
import {ProtectedRoute} from "./ProtectedRoute";
import {UnAuthorized} from "./auth/UnAuthorized";
import ChangePassword from "./user/ChangePassword";
import {UsersPage} from "../pages/admin/UsersPage";
import {JobsPage} from "../pages/admin/JobsPage";
import {OrdersPage} from "../pages/OrdersPage";

export const Layout: React.FC = () => {
    return (
        // <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box>
            <Box sx={{
                marginBottom: '0px',
                // backgroundColor: '#f7f7f8'
                backgroundColor: '#F0F2F5'
            }}>
                {/*<ResponsiveAppBar />*/}
                <MainNav/>
            </Box>
            <Box
                component={"main"}
                sx={{//backgroundColor: '#EFEFEF',
                    flexGrow: 1,
                    p: 1,
                    width: {md: `calc(100% - ${drawerWidth}px)`},
                    ml: {xs: '0px', md: '240px'},
                    mt: {xs: '85px', md: '70px'}
                }}
            >

                <Routes>


                    <Route element={<ProtectedRoute allowedRoles={[]}/>}>
                        <Route path="/unauthorized" element={<UnAuthorized/>}/>
                    </Route>

                    <Route element={<ProtectedRoute allowedRoles={[]}/>}>
                        <Route path="/" element={<DashBoardPage/>}/>
                    </Route>

                    <Route path="/profile" element={<ChangePassword/>}/>

                    <Route element={<ProtectedRoute allowedRoles={['104']}/>}>
                        <Route path="/producers-page/:id?" element={<ProducersPage/>}/>
                    </Route>

                    <Route element={<ProtectedRoute allowedRoles={['106']}/>}>
                        <Route path="/suppliers-page" element={<SuppliersPage/>}/>
                    </Route>

                    <Route element={<ProtectedRoute allowedRoles={['107']}/>}>
                        <Route path="/customers-page" element={<CustomersPage/>}/>
                    </Route>

                    <Route element={<ProtectedRoute allowedRoles={['108']}/>}>
                        <Route path="/documents-page/:id?" element={<DocumentsPage/>}/>
                    </Route>


                    <Route element={<ProtectedRoute allowedRoles={['109']}/>}>
                        <Route path="/photos-page" element={<PhotosPage/>}/>
                    </Route>

                    <Route element={<ProtectedRoute allowedRoles={['100']}/>}>
                        <Route path="/products-page/:id?" element={<ProductsPage/>}/>
                    </Route>


                    <Route element={<ProtectedRoute allowedRoles={['102']}/>}>
                        <Route path="/categories-page" element={<CategoryPage/>}/>
                    </Route>


                    <Route element={<ProtectedRoute allowedRoles={['1']}/>}>
                        <Route path="/dev-components" element={<DevComponentsPage/>}/>
                    </Route>

                    <Route element={<ProtectedRoute allowedRoles={['1']}/>}>
                        <Route path="/admin/users" element={<UsersPage/>}/>
                    </Route>
                    <Route element={<ProtectedRoute allowedRoles={['1']}/>}>
                        <Route path="/admin/jobs" element={<JobsPage/>}/>
                    </Route>

                    <Route element={<ProtectedRoute allowedRoles={['110', '111']}/>}>
                        <Route path="/orders" element={<OrdersPage/>}/>
                    </Route>

                    <Route element={<ProtectedRoute allowedRoles={[]}/>}>
                        <Route path="/*" element={<DashBoardPage/>}/>
                    </Route>

                </Routes>

            </Box>


        </Box>
    );
}