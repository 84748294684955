import {Box, Chip, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

interface IProps {
    tree: string[],
    showText: boolean
}

export const CategoryTreeSelected: React.FC<IProps> = (props) => {
    const {t} = useTranslation();

    if(props.tree.length === 0){
        return null;
    }

    return(
        <Box display="flex" flexWrap="wrap" flexDirection='column'>
            {props.showText ?  <Typography color={'text.primary'} mb={1}>{t('categories.selectedCategories')}</Typography> : null}

            <Box alignContent="flex-start" justifyContent="flex-start" display="flex" flexWrap="wrap">

                {
                    props.tree.map((x,i) => (
                        <Chip sx={{marginRight: 1, color: 'text.primary'}} color='secondary' variant={(i+1) === props.tree.length ? 'filled' : 'outlined' } key={i} label={ (i + 1) + ". " + x} />
                    ))
                }
            </Box>
        </Box>

    )
}