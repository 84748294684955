import {IUnit} from "../../interfaces/unit/IUnit";
import {NullableString} from "../../interfaces/NullableStringType";

class Unit implements IUnit{
    devName: NullableString;
    devNote: NullableString;
    id: number;

    constructor(j:IUnit) {
        this.devName = j.devName;
        this.devNote = j.devNote;
        this.id = j.id;
    }

}

export default Unit;