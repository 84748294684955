import {ProductAddToOrderInfo} from "../../../models/product/ProductAddToOrderInfo";
import React from "react";
import {Box, Button, CircularProgress, Grid, Paper, Typography, useTheme} from "@mui/material";
import {MainProductPhoto} from "../../product/MainProductPhoto";
import {CategoryTreeSelected} from "../../category/CategoryTreeSelected";
import {NullableString} from "../../../interfaces/NullableStringType";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import UnitSelect from "../../unit/UnitSelect";
import {IUnitLocale} from "../../../interfaces/unit/IUnitLocale";
import {OrderItem} from "../../../models/order/OrderItem";
import UnitLocale from "../../../models/unit/UnitLocale";
import {AlertAppContext} from "../../alert/AlertAppContext";
import {t} from "i18next";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";

interface IProps{
    p: ProductAddToOrderInfo|null
    isLoading: boolean
    onAddItem: (p:OrderItem) => void,
    units: IUnitLocale[]
    onClear: () => void
}
export const OrderFormProductAddToOrderInfoBox: React.FC<IProps> = (props) => {

    const theme = useTheme();
    const appAlert = React.useContext(AlertAppContext);

    const renderItem = (key:string|null, value: NullableString|number|undefined) =>{
        if(!value) return null;
        return (
            <Box display="flex" flexDirection={"column"}>
                { key? <Typography variant={'caption'}>{key}:</Typography> : null }
                <Typography variant="body1">{value}</Typography>
            </Box>
        )
    }

    const handleClear = () => {
        props.onClear();
    }

    const handleAddProductToOrderItems = () => {

        let p = props.p;
        if(p === null) return;
        let ul = props.units.find(x => x.unitId === p?.basicUnitId);
        if(!ul){
            appAlert.showAlert({severity: "error", message: t('orders.form.productAlreadyExist')});
            return;
        }



        let item = new OrderItem({name: p.name!, productId: p.productId, sellVat: p.sellVat, sellPrice: p.sellPrice, basicUnitId: p.basicUnitId, amount: 1, fullCode: p.fullCode, unitLocale: new UnitLocale(ul)} );

        props.onAddItem(item);
    }

    if(props.isLoading){
        return (
            <Paper elevation={3}  sx={{ padding: 1.2, marginTop: 1, backgroundColor: theme.palette.lightBlue.light}}>
                <CircularProgress size={10} />
            </Paper>
        );
    }

    if(props.p === null){
        return (
            <Paper elevation={3}  sx={{ padding: 1.2, marginTop: 1, backgroundColor: theme.palette.lightBlue.light}}>
                <Typography variant="body2" color="textSecondary">Vyberte produkt</Typography>
                {/* code goes here */}
            </Paper>
        );
    }

    return (
        <Paper elevation={3}  sx={{ padding: 1.2, marginTop: 1, backgroundColor: theme.palette.lightBlue.light}}>
            <Grid container={true} spacing={0.5} direction="row">
                <Grid item xs={4}>
                    <MainProductPhoto size="medium" product={props.p.mainPhoto} />


                </Grid>
                <Grid item xs={8}display="flex" flexDirection="column">
                    <Typography variant="h5">{props.p.fullCode}</Typography>
                    <Typography variant="h5">{props.p.name}</Typography>
                    <Box marginTop={1} display="flex" flexDirection="row" alignItems="center" width={'100%'} >
                        <IconButton onClick={handleClear} color="error" aria-label="add an alarm">
                            <CloseIcon />
                        </IconButton>
                        <Button onClick={handleAddProductToOrderItems} sx={{ width: 250, marginLeft: 1}} startIcon={<AddCircleOutlineIcon />} size="small" variant="outlined">Přidat</Button>

                    </Box>




                </Grid>
                <Grid item xs={12}>
                    {renderItem("Výrobce",props.p.producerNameAndCode)}
                    {renderItem("Dodavatel",props.p.supplierNameAndCode)}
                    {renderItem("Cena b. DPH",props.p.sellPrice)}
                    {renderItem("DPH",props.p.sellVat)}
                    {renderItem("Cena s DPH",props.p.sellPriceVat)}
                    <UnitSelect  onChange={() => {}} id={props.p.basicUnitId} variant="standard" isDisabled={true} units={props.units} />
                    <CategoryTreeSelected tree={props.p.categoryTree} showText={false} />
                </Grid>
            </Grid>
        </Paper>
    );
}