import React, {useEffect} from "react";
import {Box, Chip, CircularProgress, Grid, Typography} from "@mui/material";
import {IDocument} from "../../../interfaces/media/document/IDocument";
import documentService from "../../../services/DocumentService";
import {IDocumentFilter} from "../../../interfaces/media/document/IDocumentFilter";
import {useTranslation} from "react-i18next";
import {ProductDocumentItem} from "../ProductDocumentItem";

interface IProps {
    productId: number
}

export const DocumentInfoTab: React.FC<IProps> = (props) => {
    const [loaded, setLoaded] = React.useState(false);
    const [documents, setDocuments] = React.useState<IDocument[]>([]);
    const {t} = useTranslation();

    useEffect(() => {
        if(!loaded){
            let f:IDocumentFilter = {
              langCodes: [],
              expiration: "",
              name: "",
              productIds: [props.productId],
              active: "",
              public: "",
              documentTypeCode: ""
            };
            documentService.filter(f).then((res) => {
                setDocuments(res);
            }).finally(() => setLoaded(true));
        }
    }, [loaded, props.productId]);

    if(!loaded){
        return(
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Chip label={"Dokumentů: " + documents.length} variant="outlined" size="medium" />
            </Grid>
            <Grid item xs={12}>
                {documents.length === 0 ?
                    <Typography variant="body1">{t('missingTranslation')}</Typography>
                :
                    <Box display="flex" flexWrap="wrap">
                        {documents.map((x,i) => {
                            return(
                                <ProductDocumentItem document={x} key={'prodDoc_' + i} />
                            )
                        })}
                    </Box>
                }
            </Grid>
        </Grid>
    )

}