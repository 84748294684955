import {IOrderCreateDto} from "../interfaces/order/IOrderCreateDto";
import {NetworkService} from "./NetworkService";
import {IOrderFilter} from "../interfaces/order/IOrderFilter";
import {Order} from "../models/order/Order";
import {IOrderConstructor} from "../interfaces/order/IOrderConstructor";
import {IOrderCreatedDto} from "../interfaces/order/IOrderCreatedDto";
import {IErpStore} from "../interfaces/erpStore/IErpStore";

const controller = "/order";

class OrderService {
    async create(order: IOrderCreateDto): Promise<IOrderCreatedDto> {
      const {data} =  await NetworkService.newConnection().post<IOrderCreatedDto>(controller, order);
      return data;
    }
    async filter(filter:IOrderFilter): Promise<Order[]>{


        let str = `?regionId=${filter.regionId}`;
        str +=`&customerId=${filter.customerId ?? ''}`;
        str +=`&storeId=${filter.storeId ?? ''}`;
        str +=`&createdFrom=${filter.createdFrom ?? ''}`;
        str +=`&createdTo=${filter.createdTo ? filter.createdTo + " 23:59:59"  : ''}`;
        str +=`&ordernumber=${filter.orderNumber ?? ''}`;
        str +=`&userId=${filter.userId ?? ''}`;
        if(filter.stateIds.length > 0 ){
            for (let i = 0; i < filter.stateIds.length; i++) {
                str += '&stateIds=' + filter.stateIds[i];
            }
        }
        const {data} = await NetworkService.newConnection().get<IOrderConstructor[]>(controller +  str);
        return data.map(x => new Order(x));

    }

    async detail(id:number):Promise<Order>{
        const {data} = await NetworkService.newConnection().get<IOrderConstructor>(controller + `/${id}`);
        return new Order(data);
    }

    async syncOutOrder(orderId:number, countryCode:string):Promise<void>{
        await NetworkService.newConnection().put(controller + `/${orderId}/country/${countryCode}/sync/out`);
    }

    async syncOrdersFromErp(countryCode: string ,from: string, auto: boolean):Promise<any>{
        await NetworkService.newConnection().get(`${controller}/country/${countryCode}/sync/in?from=${from}&auto=${auto}`);
    }

    async cancelOrder(orderId:number): Promise<void>{
        await NetworkService.newConnection().put(controller + `/${orderId}/storno`);
    }

     returnOrderStateChipColor (stateId:number): "default" | "primary" | "warning" | "secondary" | "success" | "error" | "info" | undefined  {
        switch (stateId) {
            case 1:
                return 'primary';
            case 2:
                return 'error';
            default:
                return 'primary';
        }
    }



}



const orderService = new OrderService();

export default orderService;