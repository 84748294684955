import React, {useEffect} from "react";
import {Box, Chip, CircularProgress, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {IPhoto} from "../../../interfaces/media/photo/IPhoto";
import {IPhotoFilter} from "../../../interfaces/media/photo/IPhotoFilter";
import photoService from "../../../services/PhotoService";
import {ProductPhotoItem} from "../ProductPhotoItem";
import {PhotoUpsertDialog} from "../../media/photos/PhotoUpsertDialog";
import productService from "../../../services/ProductService";
import {AlertAppContext} from "../../alert/AlertAppContext";

interface IProps {
    productId: number,
    mainPhotoId: number
}
export const PhotosInfoTab: React.FC<IProps> = (props) => {

    console.log(props.mainPhotoId);

    const [loaded, setLoaded] = React.useState(false);

    const [open, setOpen] = React.useState(false);

    const [selectedPhotoid, setSelectedPhotoId] = React.useState(0);

    const [photos, setPhotos] = React.useState<IPhoto[]>([]);

    const {t} = useTranslation();

    const appAlert = React.useContext(AlertAppContext);

    const [rand, setRand] = React.useState(0);

    const handleDetail = (photoId: number) => {
        setSelectedPhotoId(photoId);
        setOpen(true);
    }

    const handleRefresh = () => {

        setLoaded(false);
    }

    const handleSetMainPhotoOnProduct = (photoId:number) => {
        productService.setMainPhotoOnProduct( props.productId, photoId).then(() => {
            handleRefresh();
            appAlert.showAlert({severity: "success" });

        }).catch((err) => {
            appAlert.showAlert({severity: "error" });
            console.log(err);
        });
    }

    useEffect(() => {
        if(!loaded){
            let f:IPhotoFilter = {
                productIds: [props.productId],
                createdAtFrom: "",
                createdAtTo: ""
            };
            photoService.filter(f).then((res) => {
                setPhotos(res);
                setRand(Math.random());
            }).finally(() => setLoaded(true));
        }
    }, [loaded, props.productId]);


    if(!loaded){
        return(
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
            </Grid>
        )
    }

    return(
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Chip label={"Fotek: " + photos.length} variant="outlined" size="medium" />
            </Grid>
            <Grid item xs={12}>
                {photos.length === 0 ?
                    <Typography variant="body1">{t('missingTranslation')}</Typography>
                    :
                    <Box display="flex" flexWrap="wrap" key={rand}>
                        {photos.map((x,i) => {
                            return(
                                <ProductPhotoItem mainPhotoId={props.mainPhotoId} onSetMainPhoto={(photoId) => handleSetMainPhotoOnProduct(photoId)} onDetail={(photoId:number) => handleDetail(photoId) } photo={x} key={'photo_' + i } />
                            )
                        })}
                    </Box>
                }
            </Grid>
            {selectedPhotoid > 0 ?
                <PhotoUpsertDialog id={selectedPhotoid} refresh={() => {handleRefresh()}} onClose={() => setOpen(false)} open={open} />
            : null}

        </Grid>

    )
}