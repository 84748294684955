import React, {useEffect} from "react";
import {Box, CircularProgress, Grid, Paper, Typography, useTheme} from "@mui/material";
import productService from "../../../services/ProductService";
import {NullableString} from "../../../interfaces/NullableStringType";
import ProductErp from "../../../models/product/ProductErp";

interface IProps{
    productId: number|null;
}

interface IKeyProps {
    k:NullableString;
    v:NullableString|number|undefined;
    direction: 'column'|'row';
    keyWidth?: number|null;
    valueBold?: boolean;
}

export const ErpInfoTabProductErp :React.FC<IProps> = (props) => {
    const theme = useTheme();
    const [loaded, setLoaded] = React.useState(false);
    const [productErp, setProductErp] = React.useState<ProductErp|null>(null);

    useEffect(() => {
        if(!loaded && props.productId !== null){
            productService.getErpInfo(props.productId, 'cz').then((res) => {
                if(res === null) return;
                setProductErp(res);
            }).catch(() => {

            }).finally(() => setLoaded(true));
        }

    }, [loaded, props.productId])

    if(!loaded){
        return (
            <Paper elevation={1} sx={{padding: 1.5}} >
                <CircularProgress />
            </Paper>
        );
    }

    //todo: O preklad
    if(productErp === null){
        return (
            <Paper elevation={1} sx={{padding: 1.5}} >
                Žádná produktová data z ERP
            </Paper>
        );
    }

    const KeyValue:React.FC<IKeyProps> = (props)  => {
        return (
            <Box  display="flex" flexDirection={props.direction} alignItems="center">
                {props.k ?
                    <Box sx={{marginRight: 1, width: props.keyWidth ?? 120}}>
                        <Typography variant="note">{props.k}</Typography>
                    </Box>
                    : null}
                {props.v ?
                    <Box sx={{fontWeight: props.valueBold ? 'bold' : 'normal'}}>
                        <Typography variant="note" >{props.v}</Typography>
                    </Box>
                    : null }
            </Box>
        );
    }

    return (
        <Grid container spacing={1} direction="row">
            <Grid item xs={12} sm={12} md={6}>
                <Paper elevation={0} sx={{padding: 1.5, backgroundColor: theme.palette.lightBlue.light}} >
                    <KeyValue k='ID (ERP)' keyWidth={160} v={productErp.productIdInErp} direction={'row'} />
                    <KeyValue k='cena MJ bez DPH' keyWidth={160} v={productErp.sellPrice} direction={'row'} />
                    <KeyValue k='DPH' keyWidth={160} v={productErp.sellVat} direction={'row'} />
                    <KeyValue k='cena MJ s DPH' keyWidth={160} v={productErp.sellPriceVat} direction={'row'} />
                    {/* todo: O dodelat stav v erp */}
                    <KeyValue k='Stav v ERP' keyWidth={160} v={productErp.stateIdInErp} direction={'row'} />
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Paper elevation={0} sx={{padding: 1.5, backgroundColor: theme.palette.lightBlue.light}} >
                    <KeyValue k={null}  v='dodavatel' direction={'row'} />
                    <KeyValue k={null}  v={productErp.supplier?.name} direction={'row'} />
                    <KeyValue k="IČ:"  v={productErp.supplier?.idNumber} direction={'row'} />
                    <KeyValue k="ID (ERP):"  v={productErp.supplier?.idFromErp} direction={'row'} />

                </Paper>
            </Grid>

        </Grid>

    );

}