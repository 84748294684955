import React, {useEffect} from "react";
import {Box} from "@mui/material";
import {IBreadcrumbs} from "../../interfaces/breadcrumbs/IBreadcrumbs";
import {BreadcrumbsBase} from "../../components/breadcrumbs/BreadcrumbsBase";
import {ProducerFilter} from "../../components/producer/ProducerFilter";
import {useParams} from "react-router-dom";

export const ProducersPage: React.FC = () => {

    const [selectedId, setSelectedId] = React.useState<number|null>(null);

    const params = useParams();

    useEffect(() => {



        if(params.id)
        {


            setSelectedId(parseInt(params.id));
        }

    }, [params])


    const breadcrumbs: IBreadcrumbs = {
        data: [
            {
                isLink: false,
                name: "Katalog výrobců",
                to: ""

            }
        ]
    }

    return(
        <Box>
            <BreadcrumbsBase breadcrumbs={breadcrumbs} />
            <ProducerFilter selectedId={selectedId} />
        </Box>
    );



}