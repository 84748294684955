import React from "react";
import {IBreadcrumb} from "../../interfaces/breadcrumbs/IBreadcrumbs";
import {Link} from "@mui/material";
import {NavLink} from "react-router-dom";

export const MobileBreadItem = (props:IBreadcrumb) => {

    return (
        <Link underline={'hover'} to={props.to} component={NavLink} sx={{
            color: 'grey'
        }}>{props.name}</Link>
    );

}