import React, {useEffect} from "react";
import {
    Box, Button,
    Container,
    FormControl, FormControlLabel,
    Grid, InputLabel, MenuItem,
    Paper, Select,
    TextField
} from "@mui/material";
import {IDocumentFilter} from "../../../interfaces/media/document/IDocumentFilter";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {DocumentUpsertDialog} from "./DocumentUpsertDialog";
import {useTranslation} from "react-i18next";
import { useForm} from "react-hook-form";
import {DocumentsTypeSelect} from "./DocumentsTypeSelect";
import {ButtonBasic} from "../../shared/ButtonBasic";
import documentService from "../../../services/DocumentService";
import {AlertAppContext} from "../../alert/AlertAppContext";
import {IDocumentType} from "../../../interfaces/media/document/IDocumentType";
import {IDocument} from "../../../interfaces/media/document/IDocument";
import {DocumentsFilterTable} from "./DocumentsFilterTable";
import ProductAutocompleteSearch from "../../product/ProductAutocompleteSearch";
import {IProductAutocomplete} from "../../../interfaces/product/IProductAutocomplete";
import {LangMultiselect} from "../../shared/LangMultiselect";
import {ILang} from "../../../interfaces/langs/iLang";
import Switch from "@mui/material/Switch";
import {IProductFilter} from "../../../interfaces/product/IProductFilter";
import productService from "../../../services/ProductService";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {PaperBasic} from "../../shared/PaperBasic";

interface IProps {
    selectedId: number|null
}

export const DocumentsFilter: React.FC<IProps> = (props) => {

    const {register, handleSubmit, formState: {errors}, control} = useForm<IDocumentFilter>();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const {t} = useTranslation();
    const appAlert = React.useContext(AlertAppContext);

    const [data, setData] = React.useState<IDocument[]>([]);

    const [timeStamp, setTimeStamp] = React.useState<number>(Date.now());

    const [name, setname] = React.useState<string>("");
    const [product, setProduct] = React.useState<IProductAutocomplete|null>(null);

    const [documentType, setDocumentType] = React.useState<IDocumentType|null>(null);

    const [selectedLangs, setSelectedLangs] = React.useState<ILang[]>([]);

    const [active, setActive] = React.useState("");

    const [pub, setPublic] = React.useState("");

    const [showExpiration, setShowExpiration] = React.useState(false);

    const [expiration, setExpiration] = React.useState("");

    const [selectedId, setSelectedId] = React.useState<number>(0);


    useEffect(() => {

        if(props.selectedId && props.selectedId > 0){
            setSelectedId(props.selectedId);
            setOpenDialog(true);
        }

    }, [props.selectedId])

    const handleShowExpiration = (v:boolean) => {
        if(!v){
            setExpiration("");
        }

        setShowExpiration(v);
    }
    const handelFilter = () => {

        setLoading(true);

        let f: IDocumentFilter = {
            name: name,
            documentTypeCode: documentType ? documentType.code : "",
            active: active,
            expiration: expiration,
            langCodes: [],
            public: pub,
            productIds: []
        }
        if(selectedLangs.length > 0) {
            selectedLangs.forEach((x) => {
                f.langCodes.push(x.code);
            })
        }

        documentService.filter(f).then((res) => {
            setData(res);
            setTimeStamp(Date.now());

        }).catch(() => {
            appAlert.showAlert({ severity: "error"});
        }).finally(() => {
           setLoading(false);
        });

    }



    const [openDialog, setOpenDialog] = React.useState(false);

    return (
        <>
        <Container>
            <Box padding={1} sx={{display: 'flex'}}>
                <Button variant="contained" startIcon={<AddBoxIcon/>} color={'info'}
                        onClick={() => setOpenDialog(true)}>{t('documents.buttons.add')}</Button>
            </Box>
            <PaperBasic title={t('documents.documentsFilter')} paperMargins={'20px 0px 30px 0px'}>
                <Box sx={{
                    px:3
                }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField  label={t('general.title')} variant={"outlined"}
                                       fullWidth={true} value={name} onChange={(e) => setname(e.target.value)} />
                        </Grid>
                        <Grid item xs={12} >
                           <ProductAutocompleteSearch label={t('documents.productLink')} onChange={(p) => setProduct(p)} resetValOnClick={false} />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <FormControl  fullWidth={true}>
                                <DocumentsTypeSelect selectedCode={documentType ? documentType.code : ""} showAllOption={true} hasError={false} onChange={(i) => setDocumentType(i)} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <FormControl fullWidth>
                                <InputLabel shrink id="active-select-label">{t('general.active')}</InputLabel>
                                <Select
                                    notched
                                    labelId="active-select-label"
                                    id="active-simple-select"
                                    value={active}
                                    label={t('general.active')}
                                    displayEmpty
                                    onChange={(e) => setActive(e.target.value)}
                                >
                                    <MenuItem value={""}>{t('general.all')}</MenuItem>
                                    <MenuItem value={"1"}>{t('general.active')}</MenuItem>
                                    <MenuItem value={"0"}>{t('general.inactive')}</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item xs={6} md={4}>
                            <FormControl fullWidth>
                                <InputLabel shrink id="public-select-label">{t('general.public')}</InputLabel>
                                <Select
                                    notched
                                    labelId="public-select-label"
                                    id="public-simple-select"
                                    value={pub}
                                    label={t('general.public')}
                                    displayEmpty
                                    onChange={(e) => setPublic(e.target.value)}
                                >
                                    <MenuItem value={""}>{t('general.all')}</MenuItem>
                                    <MenuItem value={"1"}>{t('general.public')}</MenuItem>
                                    <MenuItem value={"0"}>{t('general.notPublic')}</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item xs={4} sm={4} md={2}>
                            <FormControlLabel control={<Switch checked={showExpiration} onChange={(e) => handleShowExpiration(e.target.checked)} />} label={t('documents.expirationUntill')} />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2}>
                            {showExpiration ?
                                <TextField type="date" value={expiration} onChange={(e) => setExpiration(e.target.value)} />
                            : null}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LangMultiselect selectedLangs={selectedLangs} onSelect={(s) => setSelectedLangs(s)} />
                        </Grid>

                        <Grid item xs={6} sm={4}>

                        </Grid>

                        {/*<Grid item xs={6} md={3}>
                            <FormControl fullWidth={true}>
                                <Lang isRequired={true} showAllOption={true} getSelected={getSelectedLang}/>
                            </FormControl>
                        </Grid>*/}
                        <Grid item xs={12}>
                            <ButtonBasic onClick={handelFilter} appVariant={"filter"}  isLoading={isLoading} />
                        </Grid>
                    </Grid>
                </Box>
            </PaperBasic>
            <DocumentUpsertDialog id={selectedId} onClose={() => setOpenDialog(false)} open={openDialog}/>
            <DocumentsFilterTable key={timeStamp} data={data} onFilter={handelFilter} />
        </Container>
        </>
    );
}