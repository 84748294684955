import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField, Typography
} from "@mui/material";
import React, {useContext, useEffect} from "react";
import {IDialogBase} from "../../interfaces/IDialogBase";
import {ICategory} from "../../interfaces/category/ICategory";
import categoryService from "../../services/CategoryService";
import {AlertAppContext} from "../alert/AlertAppContext";
import {CategorySelect} from "./CategorySelect";
import Category from "../../models/category/Category";
import {ButtonBasic} from "../shared/ButtonBasic";
import CategoryLocale from "../../models/category/CategoryLocale";
import {useTranslation} from "react-i18next";
import {IBasicException} from "../../interfaces/IBasicException";
import axios from "axios";

interface IProps extends IDialogBase{
    id: number,
    onRefresh: () => void
}

export const CategoryUpsertDialog: React.FC<IProps> = (props) => {


    const {t, i18n} = useTranslation();

    const name = props.id ? t('categories.categoryUpsertDialog.titleEditCategory') : t('categories.categoryUpsertDialog.titleNewCategory');
    const [loaded, setLoaded] = React.useState<boolean>(props.id > 0 ? false : true);
    const [aLoading, setALoading] = React.useState<boolean>(false);
    const appAlert = useContext(AlertAppContext);

    const defaultCat:ICategory = {
       id: 0,
       name: "",
       devName: "",
       parentId: 0,
       note: "",
       locales: [
           {
               categoryId: 0,
               langCode: 'cs',
               name: '',
               note: ''
           },
           {
               categoryId: 0,
               langCode: 'en',
               name: '',
               note: ''
           }
       ]
    } ;
    const [category, setCategory] = React.useState(defaultCat);

    useEffect(() => {
        if(!loaded && props.id > 0){
            categoryService.getById(props.id).then((res) => {
                setCategory(res);
            }).catch(() => {
                appAlert.showAlert({severity: "error"});
            }).finally(() => {setLoaded(true)})
        }
    }, [appAlert, loaded, props.id]);


    const handleSelectChage = (id:number) => {
        let c = {...category};
        c.parentId = id;
        setCategory(c);
    }

    const handleLocaleNameChange = (langCode: string, name: string) => {
        let c = {...category};
        let l = c.locales.filter(x => x.langCode === langCode);
        l[0].name = name;

        setCategory(c);
    }

    const handleLocaleNoteChange = (langCode: string, note: string) => {
        let c = {...category};
        let l = c.locales.filter(x => x.langCode === langCode);
        l[0].note = note;

        setCategory(c);
    }

    const handleSave = () => {

        setALoading(true);

        let c = new Category(category);


        categoryService.update(c).then(() => {
            appAlert.showAlert({severity: "success"});
            props.onClose(true);
            props.onRefresh();
        }).catch((content) => {
            appAlert.showAlert({severity: "error"})
        }).finally(() => setALoading(false));

    }

    const handleCreate = () => {
        setALoading(true);

        let c = new Category(category);

        let valid = c.isValidOnCreate();
        if(!valid.isValid){
            appAlert.showAlert({severity: "error", message: valid.message});
            setALoading(false);
            return false;
        }
        categoryService.create(c).then(() => {
            appAlert.showAlert({severity: "success"});
            props.onClose(true);
            props.onRefresh();
        }).catch(() => {
            appAlert.showAlert({severity: "error"})
        }).finally(() => setALoading(false));



    }


    const handleSaveLocale = (langCode:string) => {
        setALoading(true);
        let c = {...category};
        let l = c.locales.filter(x => x.langCode === langCode);
        let cl = new CategoryLocale(l[0]);
        let v = cl.isValid();
        if(!v.isValid){
            appAlert.showAlert({severity: "error", message: v.message});
            setALoading(false);
            return false;
        }

        categoryService.updateLocale(cl).then(() => {
            appAlert.showAlert({severity: "success"});
            props.onRefresh();
        }).catch(() => {
            appAlert.showAlert({severity: "error"})
        }).finally(() => setALoading(false));
    }

    if(!loaded){
        return (
            <Dialog
                open={props.open}
                onClose={props.onClose}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }

    return(
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle sx={{
                p:0
            }}>
                <Typography variant={'paperHeading'} py={1}>
                    {name}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {t('categories.categoryUpsertDialog.categorySettings')}
                    </Grid>
                    <Grid item xs={12} >
                        <CategorySelect excludeCategoryId={category.id} handleChange={handleSelectChage} selectedcatId={category.parentId} />
                    </Grid>
                    {props.id >0 ?
                        <Grid item xs={12}>
                            <ButtonBasic appVariant="save" isLoading={aLoading} onClick={handleSave} />
                        </Grid>
                        : null}

                    <Grid item xs={12}>
                        {t('categories.categoryUpsertDialog.localizationSettings')}
                    </Grid>
                    {category.locales.map((x,i) =>
                        <Grid item key={'catLoc_' + i} xs={12} sm={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth={true}>
                                        <TextField
                                            label={x.langCode + " - " + t('general.title') }
                                            fullWidth={true}
                                            value={x.name}
                                            inputProps={{maxLength: 200}}
                                            variant="standard"
                                            onChange={(e) => handleLocaleNameChange(x.langCode, e.target.value)}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth={true}>
                                        <TextField
                                            label={x.langCode + " - " + t('general.note') }
                                            fullWidth={true}
                                            value={x.note}
                                            inputProps={{maxLength: 400}}
                                            variant="standard"
                                            multiline
                                            rows={4}
                                            onChange={(e) => handleLocaleNoteChange(x.langCode, e.target.value)}


                                        />
                                    </FormControl>
                                </Grid>
                                {props.id > 0 ?
                                    <Grid item xs={12}>
                                        <ButtonBasic appVariant="save" isLoading={aLoading} onClick={() => handleSaveLocale(x.langCode)} />
                                    </Grid>

                                    : null}

                            </Grid>
                        </Grid>
                    )}


                </Grid>
            </DialogContent>
            <DialogActions sx={{padding: 2}}>
                <ButtonBasic appVariant="close" onClick={() => {props.onClose(false)}} />
                {props.id === 0 ?
                    <Grid item xs={12}>
                        <ButtonBasic appVariant="save" isLoading={aLoading} onClick={handleCreate} >{t('categories.categoryUpsertDialog.buttons.createNewCategory')}</ButtonBasic>
                    </Grid>

                    : null}
            </DialogActions>
        </Dialog>
    );
}