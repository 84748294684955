import React from "react";
import {Box, Container, Grid, TextField} from "@mui/material";
import {PaperBasic} from "../shared/PaperBasic";
import {ButtonBasic} from "../shared/ButtonBasic";
import {useTranslation} from "react-i18next";
import supplierService from "../../services/SupplierService";
import {ISupplierFilter} from "../../interfaces/supplier/ISupplierFilter";
import Supplier from "../../models/supplier/Supplier";
import {SupplierFilterTable} from "./SupplierFilterTable";

export const SupplierFilter: React.FC = () => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string|null>(null);
    const [suppliers, setSuppliers] = React.useState<Supplier[]>([]);

    const handleFilter = () => {
        setIsLoading(true);
        let f: ISupplierFilter = {
            name: name,
            fullText: null
        }
        supplierService.filter(f).then((res) => {
            setSuppliers(res);
        }).finally(() => {
            setIsLoading(false);
        })

    }

    return (
        <>
            <Container sx={{
                marginBottom: '25px'
            }}>
                <PaperBasic title={t('suppliers.suppliers')} paperMargins={'20px 0px 30px 0px'}>
                    <Box sx={{
                        px:3
                    }}>
                        <Grid container spacing={1.5} p={3}>
                            <Grid item xs={12} md={4}>
                                <TextField size={'small'} label={t('general.title')} variant={"outlined"}
                                           onChange={(e) => setName(e.target.value)}        fullWidth={true}/>
                            </Grid>
                            <Grid item xs={12} sx={{
                                display: {md: 'flex'},
                                justifyContent: 'left'
                            }}>
                                <ButtonBasic  isLoading={isLoading} onClick={handleFilter}  appVariant="filter" type="button">
                                    {t('general.buttons.toFilter')}
                                </ButtonBasic>
                            </Grid>
                        </Grid>
                    </Box>
                </PaperBasic>

            </Container>
            <SupplierFilterTable data={suppliers} isLoading={isLoading} />
        </>
    );
}