import React from "react";
import {Box} from "@mui/material";
import {IPhoto} from "../../../interfaces/media/photo/IPhoto";
import {PhotosFilterContainerItem} from "./PhotosFilterContainerItem";
import {useTranslation} from "react-i18next";

interface IProps {
    photos: IPhoto[]
    onDetail: (id:number) => void
}
export const PhotosFilterContainer: React.FC<IProps> = (props) => {
    const {t, i18n} = useTranslation();

    return(
        <Box>
            <Box>
                {t('photos.totalPhotos')} {props.photos.length}
            </Box>
            <Box display="flex" flexWrap="wrap">
                {props.photos.length > 0 && props.photos.map((x,i) => (
                    <PhotosFilterContainerItem onDetail={(id:number) => props.onDetail(id)} photo={x} key={'photo_' + i } />
                ))}
            </Box>

        </Box>
    )
}