import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {INotice} from "../../interfaces/noticeBoard/INotice";
import {Notice} from "./Notice";
import {BasicScrollNextPaginationButton} from "../shared/BasicScrollNextPaginationButton";
import {useTranslation} from "react-i18next";


interface IProps{
    notices: INotice[]
    isNextData: boolean
    onDelete: (id:number) => void
    page: number
    loadNext: () => void
}
export const NoticesCont:React.FC<IProps> = (props) => {

    const {t, i18n} = useTranslation();



    if(props.notices.length === 0){
        return (
            <Box>
                <Typography>{t('dashboard.notices.noMessages')}</Typography>
            </Box>
        );
    }


    return (
        <Box sx={{paddingTop: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>

            { props.notices.length > 0 && props.notices.map((x,i) => { return(<Notice onDelete={(id:number) => props.onDelete(id)} notice={x} key={i} />)}) }



         <Box sx={{width: '100%'}}>
               <BasicScrollNextPaginationButton isNextData={props.isNextData} loadNext={props.loadNext} />
         </Box>

        </Box>
    );
}