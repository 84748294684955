import React from "react";
import {IBreadcrumb} from "../../interfaces/breadcrumbs/IBreadcrumbs";
import {Chip, emphasize, useTheme} from "@mui/material";
import {grey} from "@mui/material/colors";
import HomeIcon from "@mui/icons-material/Home";
import {NavLink} from "react-router-dom";

type TBreadItemProps = IBreadcrumb & {
    home?: boolean
}

export const BreadItem = (props: TBreadItemProps) => {

    const theme = useTheme();
    const breadBbackgroundColor = theme.palette.grey[300];


    return (

        <Chip
            label={props.name}
            to={props.to}
            component={NavLink}
            icon={props.home ? <HomeIcon /> : undefined }
            sx={{
                backgroundColor: props.isLink ? breadBbackgroundColor : theme.palette.actionColor.light ,
                height: '30px',
                // color: theme.palette.text.primary,
                color: props.isLink ? 'gray' : grey[700],
                //fontWeight: item.isLink ? theme.typography.fontWeightRegular : 'bold',
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.actionColor.light,
                    color:'gray',
                    //fontWeight:'bold'
                },
                '&:active': {
                    boxShadow: theme.shadows[1],
                    backgroundColor: emphasize(breadBbackgroundColor, 0.12)
                },
            }}
        />

    );
}