import {IUnitLocale} from "../interfaces/unit/IUnitLocale";
import {NetworkService} from "./NetworkService";
import UnitLocale from "../models/unit/UnitLocale";

const controller = "/unit";

export class UnitService{
    async get(lang:string):Promise<IUnitLocale[]>{
        let params = "?";
            params += "lang=" + lang;
        const { data } = await NetworkService.newConnection().get<IUnitLocale[]>(controller + params);
        return data.map(x => new UnitLocale(x));


    }

}

const unitService = new UnitService();
export default unitService;