import React from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs} from "@mui/material";
import {IDialogBase} from "../../interfaces/IDialogBase";
import BasicInfoTab from "./detailTabs/BasicInfoTab";
import {IProductLocale} from "../../interfaces/product/IProductLocale";
import LocaleInfoTab from "./detailTabs/LocaleInfoTab";
import {ButtonBasic} from "../shared/ButtonBasic";
import {DocumentInfoTab} from "./detailTabs/DocumentInfoTab";
import {PhotosInfoTab} from "./detailTabs/PhotosInfoTab";
import {useTranslation} from "react-i18next";
import ErpInfoTab from "./detailTabs/ErpInfoTab";

interface IProps extends IDialogBase{
    product:IProductLocale|null
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{
                width: '100%'
            }}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, width: '100%' }}>
                   {children}
                </Box>
            )}
        </div>
    );
}
export const ProductDetailDialog: React.FC<IProps> = (props) => {
    const {t, i18n} = useTranslation();
    const product = props.product;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if(!product)
        return null;

    return(
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogTitle>{product.name}</DialogTitle>
            <DialogContent >
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', width: '100%'}}
                >
                    <Tabs

                        orientation='vertical'
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider', width: 200 }}
                    >
                        <Tab label={t('products.productDetailDialog.basicInfo')}  />
                        <Tab label={t('products.productDetailDialog.langMutations')}  />
                        <Tab label={t('products.productDetailDialog.documents')}  />
                        <Tab label={t('products.productDetailDialog.photos')}  />
                        <Tab label={'ERP'} />

                    </Tabs>
                    <TabPanel   value={value} index={0}>
                        <BasicInfoTab product={product.product} onClose={() => {props.onClose(true)}} />
                    </TabPanel>
                    <TabPanel  value={value} index={1}>
                        <LocaleInfoTab productLocale={product} onClose={() => props.onClose(true)} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <DocumentInfoTab productId={product.productId} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <PhotosInfoTab productId={product.productId} mainPhotoId={product.product.mainPhotoId} />
                    </TabPanel>
                    <TabPanel index={4} value={value} >
                        <ErpInfoTab  productId={product.productId} />
                    </TabPanel>


                </Box>
            </DialogContent>
            <DialogActions sx={{padding: 3}}>
                <ButtonBasic onClick={() => {props.onClose(true)}} appVariant="close"/>
            </DialogActions>
        </Dialog>
    );


}