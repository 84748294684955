import React, {useRef} from 'react';
import {
    Box,
    Container,
    FormControl,
    Grid,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {ButtonBasic} from "../shared/ButtonBasic";
import {SubmitHandler, useForm} from "react-hook-form";

import {useTranslation} from "react-i18next";
import {IChnagePasswordInputs} from "../../interfaces/user/IChnagePasswordInputs";
import userService from "../../services/UserService";
import {AlertAppContext} from "../alert/AlertAppContext";
import {IBasicException} from "../../interfaces/IBasicException";

const ChangePassword = () => {

    const {t} = useTranslation();
    const appAlert = React.useContext(AlertAppContext);

    const [isSaving, setSaving] = React.useState(false);
    const {
        watch,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IChnagePasswordInputs>();

    const password = useRef({});
    password.current = watch("newPassword", "");
    const submitForm: SubmitHandler<IChnagePasswordInputs> = async (agent: IChnagePasswordInputs) => {


        setSaving(true);

        userService.changePassword(agent).then((res) => {
            appAlert.showAlert({severity: "success", message: t('general.form.success')});
        }).catch((err:IBasicException) => {
            appAlert.showAlert({severity: "error", message: err.message});
        }).finally(() => {
            setSaving(false);
        });


    }

    return (
        <Box>
            <Container>
                <Paper variant="outlined" sx={{
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;'
                }}>
                    <Typography variant={'paperHeading'} py={1}>
                        Změna hesla
                    </Typography>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Grid container spacing={1.5} p={3}>
                            <FormControl fullWidth margin="dense">
                                <TextField label='Aktuální heslo' placeholder='Aktuální heslo' type='password'
                                           variant={"standard"}
                                           fullWidth {...register('currentPassword', {required: true})}
                                           error={errors.currentPassword ? true : false}
                                           helperText={errors.currentPassword && t('general.validation.required')}
                                />
                            </FormControl>
                            <FormControl
                                fullWidth
                                margin="dense"

                            >
                                <TextField label='Nové heslo' placeholder='Nové heslo' type='password'
                                           variant={"standard"} fullWidth {...register('newPassword', {required: true})}
                                           error={errors.newPassword ? true : false}
                                           helperText={errors.newPassword && t('general.validation.required')}
                                />
                            </FormControl>
                            <FormControl
                                fullWidth
                                margin="dense"

                            >
                                <TextField label='Nové heslo - potvrzení' placeholder='Nové heslo - potvrzení'
                                           type='password' variant={"standard"}
                                           fullWidth {...register('newPasswordConfirm', {
                                    required: t('general.validation.required'),
                                    validate: value =>
                                        value === password.current || "Hesla se neshodují"
                                })}
                                           error={errors.newPasswordConfirm ? true : false}
                                           helperText={errors.newPasswordConfirm && errors.newPasswordConfirm.message}
                                />
                            </FormControl>
                            <Box sx={{my: 2}}>
                                <ButtonBasic appVariant={"save"} type={"submit"} isLoading={isSaving}></ButtonBasic>
                            </Box>
                        </Grid>
                    </form>

                </Paper>
            </Container>
        </Box>);

}

export default ChangePassword;