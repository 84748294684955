import React from "react";
import {Alert, Box} from "@mui/material";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {useTranslation} from "react-i18next";


interface IProps {
    value: string|null;
    onChange: (text: string) => void,
    maxLength?: number
}
const HtmlTextEditor: React.FC<IProps> = (props) => {

    const {t, i18n} = useTranslation();

    const [character, setCharacters] = React.useState(0);

    const handleChange = (editor:ClassicEditor) => {
        const data = editor.getData();
        const char = data.length;
        setCharacters(char);
        props.onChange(data);


    }

    return(
        <Box>


        <CKEditor
            editor={ClassicEditor}
            data={props.value}
            onChange={(event, editor) => handleChange(editor)}
        />

            {props.maxLength ?
                <Box>
                    {t('dashboard.notices.upsertDialog.charLength')} {character} / {props.maxLength}
                </Box>
                : null}

                {props.maxLength !== undefined && character > props.maxLength ?
                    <Box sx={{marginTop: 2}}>
                        <Alert severity="warning">{t('dashboard.notices.upsertDialog.charLengthOver')} ({character} / {props.maxLength}).</Alert>
                    </Box>
                    : null}

        </Box>
    )
}

export default HtmlTextEditor;