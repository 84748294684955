import {IProductAutocomplete} from "../../interfaces/product/IProductAutocomplete";
import {NullableString} from "../../interfaces/NullableStringType";
import {IProductLocale} from "../../interfaces/product/IProductLocale";

export class ProductAutocomplete implements IProductAutocomplete {
    basicCode: string;
    basicUnitId: number;
    categoryId: number;
    codeSuffix: NullableString;
    id: number; //product id
    label: string;
    name: string;
    producerId: number;
    value: number;
    mainPhotoId: number;

    constructor(json:IProductLocale) {
        this.id = json.product.id;
        this.basicCode = json.product.basicCode;
        this.name = json.name;
        this.codeSuffix = json.product.codeSuffix ?? null;
        this.categoryId = json.product.categoryId;
        this.producerId = json.product.producerId;
        this.basicUnitId = json.product.basicUnitId;
        this.label = this.returnLabel(json);
        this.value = this.id;
        this.mainPhotoId = json.product.mainPhotoId ?? 0;
    }

    returnLabel(json:IProductLocale){
        let str = json.product.basicCode;
        if(json.product.codeSuffix !== "" || json.product.codeSuffix !== null){
            str += "_" + json.product.codeSuffix;
        }
        str += " - ";
        str += json.name;
        return str;
    }

}