import {IProducer} from "../interfaces/producer/IProducer";
import {NetworkService} from "./NetworkService";
import {Producer} from "../models/producer/Producer";
import {IProducerFilter} from "../interfaces/producer/IProducerFilter";
import {IBasicException} from "../interfaces/IBasicException";


const controller = "/producer";

class ProducerService {



    async insert (producer:IProducer): Promise<IProducer|IBasicException> {

            const {data} = await NetworkService.newConnection().post<IProducer>(controller, producer);
            return new Producer(data);

    }

    async update (producer:IProducer): Promise<IProducer> {
        const {data} = await NetworkService.newConnection().put<IProducer>(controller, producer);
        return new Producer(data);
    }

    async filter (f:IProducerFilter): Promise<IProducer[]> {
        const {data} = await NetworkService.newConnection().get<IProducer[]>(`${controller}?name=${f.name}&countryCode=${f.countryCode}&fullText=${f.fullText}&showNonActive=${f.showNonActive}&id=${f.id}`);
        return data.map(x => new Producer(x));
    }
}

const producerService = new ProducerService();

export default producerService;

