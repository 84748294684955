import {IProducer} from "../../interfaces/producer/IProducer";

interface IValid {
    isValid:boolean,
    message: null|string
}
export class Producer implements IProducer {
    active: boolean;
    code: string;
    countryCode: string;
    id: number;
    name: string;
    note: string | null;
    website: string | null;

    constructor(json:IProducer) {
        this.active = json.active;
        this.code = json.code;
        this.countryCode = json.countryCode;
        this.id = json.id;
        this.name = json.name;
        this.note = json.note;
        this.website = json.website;

    }

    isValid():IValid{
        let v:IValid = {
            isValid: false,
            message: null
        }
        if(this.code === ""){
            v.message = "Kód nesmí být prázdný.";
            return v;
        }
        if(this.name === ""){
            v.message += "Název nesmí být prázdný.";
            return v;
        }

        v.isValid = true;
        return v;
    }

    getProducerObject():IProducer {
        return {
            id: this.id,
            active: this.active,
            code: this.code,
            name: this.name,
            note: this.note,
            website: this.website,
            countryCode: this.countryCode
        }
    }

}