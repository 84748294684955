import React, {useEffect} from "react";
import {Job} from "../../models/job/job";
import jobService from "../../services/JobService";
import {Box, Button, CircularProgress, Container} from "@mui/material";
import {TableIsEmpty} from "../shared/TableIsEmpty";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {PaperBasic} from "../shared/PaperBasic";
import SyncIcon from "@mui/icons-material/Sync";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const JobFilterTable:React.FC = () => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
    const [jobs, setJobs] = React.useState<Job[]>([]);

    const handleFilter = () => {
        setIsLoading(true);
        jobService.filter().then((res) => {
            setJobs(res);
            setIsLoaded(true);
        }).finally(() => setIsLoading(false));
    }

    const columns: GridColDef[] = [
        {
            field: 'active',
            headerName: 'Active',
            width: 60,
            renderCell: (params) => (params.row.active ? <CheckCircleOutlineIcon /> : <DoNotDisturbIcon />)
        },
        {
            field: 'lastRunWasSuccess',
            headerName: 'Last run',
            width: 80,
            renderCell: (params) => (params.row.lastRunWasSuccess ? <CheckCircleIcon color={'success'} /> : <ErrorIcon color={'error'} />)
        },
        {
            field: 'lastRun',
            headerName: 'Last run datetime',
            width: 150

        },
        {
            field: 'lastSuccessRun',
            headerName: 'Last success run datetime',
            width: 180

        },
        {
            field: 'id',
            headerName: 'ID',
            width: 50
        },
        {
            field: 'direction',
            headerName: 'Direction',
            width: 70
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 200
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 300
        },

        {
            field: 'description',
            headerName: 'Description',
            minWidth: 460
        },
        {
            field: 'lastRunMesssage',
            headerName: 'LastRunMessage',
            minWidth: 300
        },
        {
            field: 'lastRunLog',
            headerName: 'lastRunLog'
        }

    ]

    useEffect(() => {
        if(!isLoaded){
            handleFilter();
        }
    },[isLoaded])


    if(jobs.length === 0 && !isLoaded){
        return(
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if(jobs.length === 0){
        return(
                <TableIsEmpty />
        )
    }

    return(
        <>
                <Box display={'flex'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                    <Button onClick={() => setIsLoaded(false)} startIcon={isLoading ? <CircularProgress size={20} /> : <SyncIcon />} sx={{marginRight: 1}} variant={'outlined'} color={'primary'} >
                        Refresh
                    </Button>
                </Box>
                <PaperBasic title={'Jobs'} paperMargins={'20px 0px 30px 0px'}>


                    <Box sx={{
                        px:3
                    }}>
                        <DataGrid
                            loading={isLoading}
                            rows={jobs}
                            columns={columns}
                            pageSizeOptions={[jobs.length]}
                            hideFooterPagination={true}
                            hideFooter={true}

                        />
                    </Box>
                </PaperBasic>

        </>
    )
}