import React from "react";
import {Box, Button, Card, CardActions, CardContent, Chip, IconButton, Typography} from "@mui/material";
import {INotice} from "../../interfaces/noticeBoard/INotice";
import DraftsIcon from '@mui/icons-material/Drafts';
import {NoticeReadDialog} from "./NoticeReadDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from "react-i18next";



interface IProps{
    notice: INotice
    onDelete: (id:number) => void
}
export const Notice:React.FC<IProps> = (props) => {
    const {t, i18n} = useTranslation();

    const [open, setOpen] =  React.useState(false);

    const handleRead = () => {
        setOpen(true);
    }

    const handleDelete = () => {
        props.onDelete(props.notice.id);
    }

    return(
        <>
        <Card sx={{ width: 330,
                    //height: 250,
                    margin: 1,
                    bgcolor: '#F9F9F9',
                    paddingTop: 1,
                    //paddingBottom: 100,
                    paddingRight: 0,
                    paddingLeft: 0 }}>
            <CardContent sx={{height: 180}} >
                <Box sx={{display: 'flex'}}>
                    <Chip variant='filled'
                          sx={{
                              marginRight: 1,
                              color: 'white',
                              fontWeight: 'bold',
                              bgcolor: 'primary.dark'
                          }}
                          label={props.notice.createdAt} />
                    {props.notice.isFromAdmin ? <Chip size='medium'  label={'ADMIN'} variant='filled' color='warning' /> : null}

                </Box>
                <Box sx={{padding: 1}}>
                    <Typography sx={{ fontSize: 14 }} textAlign='left' color="text.secondary" gutterBottom>
                        <span style={{ fontWeight: 'bold' }}>{t('general.author')}:</span> {props.notice.createdBy}
                    </Typography>
                    <Typography variant='h6' textAlign='left' color='text.primary'>
                        {props.notice.name}
                    </Typography>
                </Box>


            </CardContent>
            <CardActions disableSpacing={true} sx={{paddingTop: 1, paddingBottom: 1, paddingLeft: 2, paddingRight: 2, margin: 0, justifyContent: 'space-between',  bgcolor: `#EFEFEF`
                }}>

                        <Button onClick={handleRead} sx={{marginRight: 1}} startIcon={<DraftsIcon />} variant='contained' color='primary' size="small">{t('dashboard.notices.buttons.read')}</Button>
                        <IconButton aria-label='delete' onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>

            </CardActions>


        </Card>
            <NoticeReadDialog notice={props.notice}  onClose={() => setOpen(false)} open={open} />
        </>
    )
}