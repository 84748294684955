import {IMainPhoto} from "../../interfaces/product/IMainPhoto";

const baseLinkUrl = process.env.REACT_APP_AZURE_BLOB_ENDPOINT + '/' + process.env.REACT_APP_AZURE_IMAGES_CONTAINER;

export class MainProductPhoto implements IMainPhoto{
    public id: number;
    public guid: string;
    public link: string;

    constructor(json:IMainPhoto) {
        this.id = json.id;
        this.guid = json.guid;
        this.link = baseLinkUrl + "/" + process.env.REACT_APP_AZURE_IMAGES_SMALL_FOLDER + "/" + json.guid + ".webp";
    }
}