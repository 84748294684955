import React from 'react';
import {Box, Breadcrumbs, Container} from "@mui/material";
import {IBreadcrumb, IBreadcrumbs} from "../../interfaces/breadcrumbs/IBreadcrumbs";
import HomeIcon from '@mui/icons-material/Home';
import {MobileBreadItem} from "./MobileBreadItem";
import {BreadItem} from "./BreadItem";

type Props = {
    breadcrumbs: IBreadcrumbs
}


export const BreadcrumbsBase: React.FC<Props> = (props : Props) => {

    if(props.breadcrumbs.data.length === 0)
        return null;


    return(
        <Box>

            {/*                MOBILE breadcrumb          */}

            <Container sx={{display: {md:'none'}}}>

                <Breadcrumbs maxItems={2} sx={{
                    mb:3
                }}>
                    <Box sx={{display:'flex'}}><HomeIcon sx={{mr:1}} />
                        <MobileBreadItem to={'/'} name={'Dashboard'} isLink={true} />
                    </Box>

                    {props.breadcrumbs.data.map((x:IBreadcrumb, i) => {
                        return(
                        <MobileBreadItem to={x.to} name={x.name} isLink={x.isLink} key={'MobileBreadCr'+i} />
                        );
                    })}
                </Breadcrumbs>

            </Container>





    {/*                DESKTOP breadcrumb          */}
            <Container sx={{
                display: {xs:'none', md: 'inherit'}
            }}>
                <Breadcrumbs sx={{
                    mt:3,
                    mb:3
                }}>

                    <BreadItem to={'/'} name={'Dashboard'} isLink={true} home={true} />

                    {props.breadcrumbs.data.map((x,i) => {
                        return (
                            <BreadItem to={x.to} name={x.name} isLink={x.isLink} key={'BreadCrumb'+i}/>
                        );
                    })}
                </Breadcrumbs>
            </Container>

        </Box>
    );

}