import React, {useEffect} from "react";
import customerService from "../../services/CustomerService";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import {ICustomerAddressConstructor} from "../../interfaces/customer/ICustomerAddressConstructor";
import {useTranslation} from "react-i18next";

interface IProps {
    customerId: number,
    onSelect(item: ICustomerAddressConstructor|null): void,
    size?: "small" | "medium"
}

export const CustomerAddressSelect: React.FC<IProps> = (props) => {
    const {t} = useTranslation();
    const [loaded, setLoaded] = React.useState(false);
    const [addresses, setAddresses] = React.useState<ICustomerAddressConstructor[]>([]);



    const onChange = (item: ICustomerAddressConstructor | null) => {
        if (item === null) {
            props.onSelect(null);
        } else {
            props.onSelect(item);
        }
    }

    useEffect(() => {

        if (loaded) return;

        customerService.getAddresses(props.customerId).then((res) => {
            setAddresses(res);
            setLoaded(true);
        })

    }, [loaded, props.customerId])

    if(!loaded)return <CircularProgress size={20} />;

    return (<Autocomplete

        options={addresses}
        size={props.size ?? "medium"}
        getOptionLabel={(option) => `${option.name} (${option.street}, ${option.city})`}
        renderInput={(params: any) => <TextField {...params} label={t('orders.form.customerAddress')}/>}
        onChange={(_, data) => {
            onChange(data)
        }}
    />);

}