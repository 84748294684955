import {ICategoryLocale} from "../../interfaces/category/ICategoryLocale";
import {NullableString} from "../../interfaces/NullableStringType";
import {IValid} from "../../interfaces/IValid";


class CategoryLocale implements ICategoryLocale{
    categoryId: number;
    langCode: string;
    name: string;
    note: NullableString;

    constructor(j:ICategoryLocale) {
        this.categoryId = j.categoryId;
        this.langCode = j.langCode;
        this.name = j.name;
        this.note = j.note;

    }

    isValid():IValid{
        let v:IValid = {
            isValid: false,
            message: ""
        }
        if(this.name.length === 0){
            v.message = "Vyplňte název [" + this.langCode + "].";
            return v;
        }

        v.isValid = true;
        return v;
    }

}

export default CategoryLocale;