import React, {useEffect} from 'react';
import userService from "../../services/UserService";
import IUserFull from "../../interfaces/user/IUserFull";
import {Button, Card, CardActions, CardContent, Grid, Typography} from "@mui/material";
import IUserFilter from "../../interfaces/user/IUserFilter";
import {EditUserDialog} from "./EditUserDialog";
import {useTranslation} from "react-i18next";

interface IProps {
    filter: IUserFilter
}

const Users: React.FC<IProps> = (props) => {

    const {t, i18n} = useTranslation();

    const [users, setUsers] = React.useState<IUserFull[]>();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedUserId, setSelectedUserId] = React.useState<number>(0);

    useEffect(() => {
        document.title = "Uživatelé";
    }, []);


    useEffect(() => {
        setLoaded(false);
    }, [props.filter]);

    useEffect(() => {

        if (loaded) return;

        const list = userService.list(props.filter)


        Promise.all([list]).then((data) => {
            setUsers(data[0]);
        }).catch((err) => {

        }).finally(() => {
            setLoaded(true);
        });


    }, [users, loaded]);


    return (<Grid container spacing={2}>

        {users?.length === 0 ?
            <>{t('admin.users.noMatchingUsers')}</>

            :

            users?.map((x, i) => {
                return <Grid key={x.id} item xs={12} sm={6} md={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom></Typography>
                            <Typography variant="h5" component="div" color={x.isDisabled ? "darkred" : "black"}>
                                {x.firstName || x.lastName ? <>{x.firstName} {x.lastName}</> : x.username}
                            </Typography>

                            {x.isDisabled ?
                                <Typography color="text.secondary" style={{minHeight: 30}}>
                                    {x.isDisabled ? <p>{t('admin.users.disabledAccount')}</p> : <p></p>}
                                </Typography>
                                :
                                <Typography color="text.secondary" style={{minHeight: 30}}>
                                   <p> {x.position}</p>
                                </Typography>
                            }


                        </CardContent>
                        <CardActions style={{display: 'flex', justifyContent: 'center'}}>
                            <Button size="small" onClick={() => {
                                setSelectedUserId(x.id)
                                setOpenDialog(true)
                            }}>{t('general.edit')}</Button>
                        </CardActions>
                    </Card>
                </Grid>

            })}
        <EditUserDialog id={selectedUserId} onClose={() => {
            setOpenDialog(false);
            setLoaded(false);
        }} open={openDialog}/>
    </Grid>)

}

export default Users;