import {IPhotoInputs} from "../interfaces/media/photo/IPhotoInputs";
import {NetworkService} from "./NetworkService";
import {IPhotoFilter} from "../interfaces/media/photo/IPhotoFilter";
import {IPhoto} from "../interfaces/media/photo/IPhoto";
import {Photo} from "../models/photo/Photo";
import {IPhotoDetail} from "../interfaces/media/photo/IPhotoDetail";
import {PhotoDetail} from "../models/photo/PhotoDetail";
import {IPhotoProduct} from "../interfaces/media/photo/IPhotoProduct";

const controller = "/photo";


export class PhotoService {

    async upload(photo: IPhotoInputs): Promise<void> {

        let data = new FormData();
        for (let i = 0; i < photo.products.length; i++) {
            data.append('products[]', photo.products[i].id.toString() + ';' + (photo.products[i].mainPhoto ? 'true' : 'false') );
            //data.append('products[]', JSON.stringify(photo.products[i]));
        }

        for (let i = 0; i < photo.files.length; i++) {
            data.append('files', photo.files[i]);
        }
        await NetworkService.newConnection().post(controller + '/upload', data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    }

    async update (products: IPhotoProduct[], photoId: number): Promise<void> {
        let data = new FormData();
        for (let i = 0; i < products.length; i++) {
            data.append('products[]', products[i].id.toString() + ';' + (products[i].mainPhoto ? 'true' : 'false') );
            //data.append('products[]', JSON.stringify(photo.products[i]));
        }
        data.append("photoId", photoId.toString());

        await NetworkService.newConnection().put(controller , data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    }

    async filter(f:IPhotoFilter): Promise<IPhoto[]> {
        let params = "";
        if(f.productIds.length > 0 ){
            f.productIds.forEach((x) => {
                params += "&productIds=" + x;
            })
        }
        params += `&createdAtFrom=${f.createdAtFrom}`;
        params += `&createdAtTo=${f.createdAtTo}`;



        const {data} = await NetworkService.newConnection().get<IPhoto[]>(controller + "?" + params);
        console.log(data.map(x => new Photo(x)))


        return data.map(x => new Photo(x));
    }

    async detail(id:number): Promise<IPhotoDetail>{
        const {data} = await NetworkService.newConnection().get<IPhotoDetail>(`${controller}/${id}`);
        return new PhotoDetail(data);
    }

    async delete(id:number):Promise<void> {
        await NetworkService.newConnection().delete(`${controller}/${id}`);
    }

}

const photoService = new PhotoService();
export default photoService;