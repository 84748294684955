import React from "react";
import {Box, Button, Container} from "@mui/material";
import Users from "../../components/user/Users";
import {UserFilter} from "../../components/user/UserFilter";
import IUserFilter from "../../interfaces/user/IUserFilter";
import UserFilterModel from "../../models/user/UserFilterModel";
import {AddUserDialog} from "../../components/user/AddUserDialog";
import {useTranslation} from "react-i18next";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {PaperBasic} from "../../components/shared/PaperBasic";


export const UsersPage: React.FC = () => {

    const {t } = useTranslation();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [userFilter, setUserFilter] = React.useState<IUserFilter>(new UserFilterModel('', true));
    const [userComponentId, setUserComponentId] = React.useState<Number>(Math.random());
    const handleFilter = (filter: IUserFilter) => {
        setUserFilter(filter);
    }

    return (
        <Box>
            <Container>
                <Box padding={1} sx={{display: 'flex'}}>
                    <Button variant="contained" startIcon={<AddBoxIcon/>} color={'info'}
                            onClick={() => setOpenDialog(true)}>{t('admin.users.createNewUser')}</Button>
                </Box>

                <PaperBasic title={t('admin.users.userFilter')} paperMargins={'20px 0px 30px 0px'}>
                    <Box sx={{
                        px:3
                    }}>
                        <UserFilter filter={userFilter} refreshFilter={(filter) => { handleFilter(filter); }}/>
                    </Box>
                </PaperBasic>



                <PaperBasic title={t('admin.users.userList')}>
                    <Box sx={{
                        px:3
                    }}>
                        <Users  filter={userFilter} key={userComponentId?.toString()}/>
                    </Box>
                </PaperBasic>

            </Container>
            <AddUserDialog onClose={(refresh) => {setOpenDialog(false); if(refresh){ setUserComponentId(Math.random())}}} open={openDialog} />
        </Box>
    );
}