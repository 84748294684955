import Supplier from "../supplier/Supplier";
import {IErp} from "../../interfaces/erp/IErp";
import {IProductErpConstructor} from "../../interfaces/product/IProductErpConstructor";
import dayjs from "dayjs";
import {NullableString} from "../../interfaces/NullableStringType";

class ProductErp {

    productId: number;
    erpId: number;
    erp: IErp|null;
    name: NullableString;
    supplierCode: NullableString;
    supplierId: number|null;
    supplier: Supplier|null;
    sellPrice: number;
    sellVat: number;
    sellPriceVat: number;
    buyPrice: number;
    buyVat: number;
    buyPriceVat: number;
    stateIdInErp: number;
    createdAt: string;
    updatedAt: string;
    productIdInErp: number;

    constructor(json:IProductErpConstructor){
        this.productId = json.productId;
        this.erpId = json.erpId;
        this.erp = json.erp ?? null;
        this.name = json.name ?? null;
        this.supplierCode = json.supplierCode ?? null;
        this.supplierId = json.supplierId ?? null;
        this.supplier = json.supplier ? new Supplier(json.supplier) : null;
        this.sellPrice = json.sellPrice;
        this.sellVat = json.sellVat;
        this.sellPriceVat = json.sellPriceVat;
        this.buyPrice = json.buyPrice;
        this.buyVat = json.buyVat;
        this.buyPriceVat = json.buyPriceVat;
        this.stateIdInErp = json.stateIdInErp;
        this.productIdInErp = json.productIdInErp;
        this.createdAt = dayjs(json.createdAt).format('DD.MM.YYYY HH:mm');
        this.updatedAt = dayjs(json.updatedAt).format('DD.MM.YYYY HH:mm');
    }
}

export default ProductErp;