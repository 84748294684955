import {NetworkService} from "./NetworkService";
import {ICategory} from "../interfaces/category/ICategory";
import {IChnagePasswordInputs} from "../interfaces/user/IChnagePasswordInputs";
import IUserFull from "../interfaces/user/IUserFull";
import UserFull from "../models/user/UserFull";
import IUserFilter from "../interfaces/user/IUserFilter";
import {IUserInputs} from "../interfaces/user/IUserInputs";
import UserRole from "../models/user/UserRole";
import {IUserRole} from "../interfaces/user/./IUserRole";


const controller = "/user";

class UserService {

    async changePassword(form: IChnagePasswordInputs): Promise<any> {

        const {data} = await NetworkService.newConnection().post<ICategory>(`${controller}/change-password`, form);
        return data;
    }

    async list(filter: IUserFilter): Promise<UserFull[]> {

        let urlParameters = Object.entries(filter).map(e => e.join('=')).join('&');

        const {data} = await NetworkService.newConnection().get<IUserFull[]>(`${controller}?${urlParameters}`);
        return data.map(x => new UserFull(x));
    }

    async create(user: IUserInputs) {
        const {data} = await NetworkService.newConnection().post(`${controller}`, user);
        return data;
    }

    async get(id: number) {
        const {data} = await NetworkService.newConnection().get(`${controller}/${id}`);
        return new UserFull(data);
    }

    async update(user: IUserInputs, id: number) {
        const {data} = await NetworkService.newConnection().post(`${controller}/${id}`, user);
        return new UserFull(data);
    }

    async roles() /*: Promise<IUserRole[]>*/ {
        const {data} = await NetworkService.newConnection().get<IUserRole[]>(`${controller}/roles`);
        return data.map(x => new UserRole(x));
    }


}

const userService = new UserService();
export default userService;