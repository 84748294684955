import React from "react";
import {TableBasic} from "../shared/TableBasic";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Order} from "../../models/order/Order";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import {useTranslation} from "react-i18next";
import orderService from "../../services/OrderService";
import {Chip} from "@mui/material";

interface IProps {
    isLoading: boolean;
    rows: Order[];
    onClick: (id:number) => void
}

interface IPropsTollTip{
    orderId:number
}

export const OrdersFilterTable: React.FC<IProps> = (props) => {

    const {t} = useTranslation();

    const getRowId = (row:Order) => ('oTable_' + row.id);
    const columns: GridColDef[] = [
        {
            field: ' ',
            headerName: '',
            width: 30,
            renderCell: (params) => <InfoTooltip orderId={params.row.id}  />

        },
        {
            field: 'createdAt',
            headerName: 'Vytvořeno',
            width: 130,
            type: 'string'
        },
        {
            field: 'orderstate.name',
            headerName: 'Stav',
            width: 100,
            type: 'string',
            renderCell: (params) => <Chip size="small" color={orderService.returnOrderStateChipColor(params.row.orderState.id ?? 0)} label={params.row.orderState.name}  />
        },
        {
            field: 'erpStore.name',
            headerName: 'Sklad',
            width: 100,
            type: 'string',
            valueGetter: (params) => (params.row.erpStore.name)
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 60,
            type: 'number'
        },
        {
            field: 'orderNumber',
            headerName: 'Číslo obj.',
            width: 120,
            type: 'string'

        },
        {
            field: 'customerOrderNumber',
            headerName: 'Číslo obj. odběratele',
            width: 140,
            type: 'string'

        },
        {
            field: 'customer.name',
            headerName: 'Zákazník',
            width: 190,
            type: 'string',
            valueGetter: (params) => (params.row.customer.name)

        },
        {
            field: 'userCreated.fullName',
            headerName: 'Uživatel',
            width: 180,
            type: 'string',
            valueGetter: (params) => (params.row.userCreated.fullName)

        },
        {
            field: 'items',
            headerName: 'Položek',
            width: 80,
            type: 'number',
            valueGetter: (params) => (params.row.items.length)

        },
        {
            field: 'sumSellPrice',
            headerName: 'Celkem',
            width: 120,
            type: 'number'

        },
        {
            field: 'sumSellPriceVat',
            headerName: 'Celkem s DPH',
            width: 120,
            type: 'number'

        },




    ];

    const InfoTooltip:React.FC<IPropsTollTip> = (props) => {
        return (
            <Tooltip title={t('orders.orderDetail')} onClick={() => handleInfoClick(props.orderId)}>
                <IconButton>
                    <InfoIcon/>
                </IconButton>
            </Tooltip>


        );
    }

    const handleInfoClick = (orderId:number) => {
        props.onClick(orderId);
    }

    return(
        <TableBasic title={'Výsledky filtrování'} count={props.rows.length} boxPadding={1}>
            <DataGrid
                loading={props.isLoading}
                rows={props.rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {page: 0, pageSize: 10},
                    },
                }}
                pageSizeOptions={[10, 50, 100]}
                getRowId={getRowId}
            />
        </TableBasic>

    )

}