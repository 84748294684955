import React from "react";
import {IPhoto} from "../../interfaces/media/photo/IPhoto";
import {Box, Paper} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';

interface IProps {
    photo: IPhoto,
    onDetail: (photoId:number) => void,
    onSetMainPhoto: (photoId:number) => void,
    mainPhotoId: number
}
export const ProductPhotoItem: React.FC<IProps> = (props) => {
    const p = props.photo;
    return(
        <Paper sx={{padding: 1, backgroundColor: '#f4f1f1', width: 230, margin: 1}}>
            <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                    <img src={p.link} alt={p.fileName} />
                </Box>
                <Box display="flex" flexDirection="row" alignItems="flex-start" paddingBottom={0.5} paddingTop={0.5}>

                    <Tooltip title="Detail fotky">
                        <IconButton color="info" onClick={() => props.onDetail(p.id)} >
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                    {props.mainPhotoId === p.id ?
                        <Tooltip title="Fotka je nastavená jako hlavní na produktu">
                            <IconButton color="success" >
                                <BookmarkAddedIcon />
                            </IconButton>
                        </Tooltip>
                    :

                        <Tooltip title="Nastavit tuto fotku jako hlavní fotku produktu">
                            <IconButton color="primary" onClick={() => props.onSetMainPhoto(p.id)}>
                                <BookmarkIcon />
                            </IconButton>
                        </Tooltip>
                    }


                </Box>
            </Box>

        </Paper>
    )
}