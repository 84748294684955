import {IPhotoProduct} from "../../interfaces/media/photo/IPhotoProduct";
import { NullableString } from "../../interfaces/NullableStringType";

export class PhotoProduct implements IPhotoProduct {
    id: number;
    mainPhoto: boolean;
    name: string;
    codeSuffix: NullableString;
    basicCode: string;


    constructor(data: IPhotoProduct) {
        this.id = data.id;
        this.mainPhoto = data.mainPhoto;
        this.name = data.name;
        this.basicCode = data.basicCode;
        this.codeSuffix = data.codeSuffix;
    }




}