import {NetworkService} from "./NetworkService";
import {Lang} from "../models/lang/Lang";
import {ILang} from "../interfaces/langs/iLang";

const controller = "/lang";

//export type TUserList = ILang[]

export class LangService{
    async list() :Promise<ILang[]>{
        const { data } = await NetworkService.newConnection().get<ILang[]>(controller);
        return data.map(x=> new Lang(x));
    }
}

const langService = new LangService();

export default langService;