import IUserFull from "../../interfaces/user/IUserFull";

import {IUserRoles} from "../../interfaces/user/IUserRoles";

class UserFull implements IUserFull{
    id: number
    username: string
    isDisabled: boolean
    firstName: string
    lastName: string
    note: string
    position: string
    roles: IUserRoles[] = []
    email: string
    fullName: string

    constructor(j:IUserFull) {

        this.username = j.username;
        this.isDisabled = j.isDisabled;
        this.id = j.id;
        this.note = j.note;
        this.firstName = j.firstName;
        this.lastName = j.lastName;
        this.position = j.position;
        this.email = j.email;
        this.fullName = j.firstName + " " + j.lastName;

        if(j.roles){
            this.roles = j.roles;
        }
    }

}

export default UserFull;