import React from "react";
import {
    Box,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Input,
    TextField,
    Typography
} from "@mui/material";
import {IDialogBase} from "../../interfaces/IDialogBase";
import {SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import HtmlTextEditor from "../shared/HtmlTextEditor";
import {ProtectedComponent} from "../ProtectedComponent";
import Switch from "@mui/material/Switch";
import {INoticeInputs} from "../../interfaces/noticeBoard/INoticeInputs";
import noticeBoardService from "../../services/NoticeBoardService";
import {AlertAppContext} from "../alert/AlertAppContext";
import {CloseButton} from "../../theming/customButtons";
import CloseIcon from "@mui/icons-material/Close";
import {ButtonBasic} from "../shared/ButtonBasic";

interface IProps extends IDialogBase {
    id: number,
    refresh: () => void
}
export const NoticeBoardUpsertDialog: React.FC<IProps> = (props)  => {
    const {
        reset,
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<INoticeInputs>();
    const {t } = useTranslation();

    const title = props.id > 0 ? "Detail zprávy" : t('dashboard.notices.upsertDialog.dialogTitleNew');
    const [html, setHtml] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [isFromAdmin, setIsFromAdmin] = React.useState(false);
    const appAlert = React.useContext(AlertAppContext);

    console.log(html);

    const submitForm: SubmitHandler<INoticeInputs> = async (agent: INoticeInputs) => {
        setLoading(true);
        if(props.id > 0){

        } else {
            //TODO: O nefunguje register
            agent.htmlContent = html;
            console.log(agent);

            noticeBoardService.create(agent).then(() => {
                props.onClose(true);
                appAlert.showAlert({severity: "success"});
                setHtml('');
                setIsFromAdmin(false);
                reset();
                props.refresh();
            }).catch((err) => {
                console.log(err);

                appAlert.showAlert({severity: "error", message: err.response.data});
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    return(
        <Dialog
            open={props.open}
            onClose={props.onClose} fullWidth={true} maxWidth={"md"}>
            <form onSubmit={handleSubmit(submitForm)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} padding={1}>
                        <Grid item xs={12}>
                            <TextField label={t('dashboard.notices.upsertDialog.messageTitle')} fullWidth={true}  {...register('name', {required: true})}
                                       error={errors.name ? true : false} helperText={errors.name && t('general.validation.required')}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ProtectedComponent role={['2']}>
                            <FormControlLabel
                                value="end"
                                control={<Switch color="primary" onClick={() => setIsFromAdmin(!isFromAdmin)} checked={isFromAdmin} />}
                                label={t('dashboard.notices.upsertDialog.adminMessage')}
                                labelPlacement="end"
                            />
                            </ProtectedComponent>

                            <Input value={isFromAdmin} type={"hidden"} {...register('isFromAdmin', {})} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="note">{t('dashboard.notices.upsertDialog.message')}</Typography>
                            <Box sx={{maxHeight: 500, overflow: 'auto', paddingBottom: 1}}>
                                <HtmlTextEditor maxLength={2000} value={html} onChange={(val: string) => setHtml(val)} />
                                <Input defaultValue={html} type={"hidden"}  {...register('htmlContent', {
                                    validate: () => {

                                        if (html.length > 2000) {
                                            return t('Překročena povolené délka znaků')
                                        }
                                        return true;
                                    }
                                })} />
                            </Box>


                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{padding: 3}}>
                        <CloseButton startIcon={<CloseIcon/>} onClick={() => {
                            props.onClose(false)
                        }}>{t('general.buttons.close')}</CloseButton>
                        <ButtonBasic appVariant={"save"} type={"submit"} isLoading={loading}></ButtonBasic>

                </DialogActions>
            </form>
        </Dialog>
    )
}