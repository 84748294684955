import React from 'react';
import './App.css';
import {ThemeProvider} from "@mui/material";
import {Layout} from "./components/Layout";
import './translations/i18n';
import {AlertAppProvider} from "./components/alert/AlertAppContext";
import {newDTtheme} from "./theming/dtTheme";
import {Route, Routes} from "react-router-dom";
import {LoginPage} from "./pages/login/LoginPage";
import {RecoveryPasswordPage} from "./pages/RecoveryPasswordPage";
const App: React.FC = () => {

    return (
        <Routes>
            <Route path="/login" element={
                <AlertAppProvider>
                    <LoginPage />
                </AlertAppProvider>}/>
            <Route path={"/password-recovery/:guid"} element={
                <AlertAppProvider>
                    <RecoveryPasswordPage />
                </AlertAppProvider>}/>
            <Route path="/*" element={<ThemeProvider theme={newDTtheme}>
                <AlertAppProvider>
                    <div className="App">
                        <Layout/>
                    </div>
                </AlertAppProvider>
            </ThemeProvider>}/>

        </Routes>


    );

}

export default App;

