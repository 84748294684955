import React, {useEffect, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import {IProducer} from "../../interfaces/producer/IProducer";
import producerService from "../../services/ProducerService";
import {useTranslation} from "react-i18next";


interface IProps {
    onChange: (value:IProducer|null) => void
}
const ProducerAutocompleteSearch: React.FC<IProps> = (props) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<IProducer[]>([]);
    const {t} = useTranslation();

    const handleOptionSelect = (event:React.SyntheticEvent<Element, Event>, value:IProducer|null) => {
        props.onChange(value);
    };

    useEffect(() => {
        if(searchTerm.length > 2){

            producerService.filter({fullText: searchTerm, countryCode: "", name: "", showNonActive: false, id: 0}).then((res) => {
                setSearchResults(res);
            })
        }

    }, [searchTerm]);

    return(
        <Autocomplete
            options={searchResults}
            size="small"
            getOptionLabel={(option) => ( option.code + '-' + option.name)}
            onInputChange={(event, value) => setSearchTerm(value)}
            renderInput={(params:any) => (
                <TextField {...params} label={t('products.productFilter.searchProducer')} variant="outlined" />
            )}
            onChange={(event, value) => handleOptionSelect(event, value)}

        />
    )
}

export default ProducerAutocompleteSearch;