import {ICountryLocale} from "../interfaces/country/ICountryLocale";
import {NetworkService} from "./NetworkService";
import CountryLocale from "../models/country/CountryLocale";

const controller = "/country";

class CountryService{
    async get (lang:string):Promise<ICountryLocale[]>{
        let params = "?";
        params += "lang=" +lang;

        const {data} = await NetworkService.newConnection().get<ICountryLocale[]>(controller + params);
        return data.map(x => new CountryLocale(x));


    }
}

const countryService = new CountryService();

export default countryService;