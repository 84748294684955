import React, {useEffect} from "react";
import UserFull from "../../models/user/UserFull";
import {Autocomplete, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import userService from "../../services/UserService";

interface IProps{
    onChange: (id:number|null) => void
    id:number|null
    variant: "filled" | "standard" | "outlined" | undefined
    isDisabled: boolean
    size: 'small'|'medium'
    showAll: boolean
}

export const UsersSelect:React.FC<IProps> = (props) => {

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [users, setUser] = React.useState<UserFull[]>([]);
    const {t} = useTranslation();

    useEffect(() => {
        if(!loaded  ){
            userService.list({fulltext: '', onlyActive: true, orderBy: ''}).then( (res)  => {
                setUser(res);
                setLoaded(true);
            })
        }

    }, [loaded]);

    const handleChange = (user:UserFull|null) => {


        props.onChange(user ? Number(user.id) : null);

    }

    if(!loaded){
        return <CircularProgress size={34} /> ;

    }

    return(
        <Autocomplete
            fullWidth={true}
            options={users}
            size={props.size ?? "medium"}
            getOptionLabel={(option) => option.fullName}
            renderInput={(params: any) => <TextField {...params} label={t('general.users')}/>}
            onChange={(_, data) => {
                handleChange(data)
            }}
        />


    )
}