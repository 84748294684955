//import React from "react";
import {Button} from "@mui/material";
import {alpha, styled} from "@mui/material/styles";

const iconMarginLeft:string = '2px';
export const SaveButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: 'white',
    display: 'flex',
    '&:hover': {
        backgroundColor: theme.palette.success.dark,
    },
    '& .MuiSvgIcon-root':{
        marginLeft: iconMarginLeft
    }
}));

export const DeleteButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.error.main,
    color: 'white',
    display: 'flex',
    '&:hover': {
        backgroundColor: theme.palette.error.dark,
    },
    '& .MuiSvgIcon-root':{
        marginLeft: iconMarginLeft
    }
}));

export const CloseButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'none',
    color: theme.palette.error.main,
    border: '1px solid',
    borderColor: theme.palette.error.main,
    display: 'flex',
    '&:hover': {
        backgroundColor: alpha(theme.palette.error.light, 0.1),
    }
}));

export const FilterButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
    '& .MuiSvgIcon-root':{
        marginLeft: iconMarginLeft
    }
}));