import React from "react";
import {IBreadcrumbs} from "../../interfaces/breadcrumbs/IBreadcrumbs";
import {Box} from "@mui/material";
import {BreadcrumbsBase} from "../../components/breadcrumbs/BreadcrumbsBase";
import {CustomerFilter} from "../../components/customer/CustomerFilter";

export const CustomersPage: React.FC = () => {
    const breadcrumbs: IBreadcrumbs = {
        data: [
            {
                isLink: false,
                name: "Katalog zákazníků",
                to: ""

            }
        ]
    }

    return(
        <Box>
            <BreadcrumbsBase breadcrumbs={breadcrumbs} />
            <Box>
               <CustomerFilter />
            </Box>
        </Box>
    );

}