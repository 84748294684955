import {IDocumentProduct} from "../../interfaces/media/document/IDocumentProduct";
import {NullableString} from "../../interfaces/NullableStringType";

export class DocumentProduct implements IDocumentProduct {
    basicCode: string;
    codeSuffix: NullableString;
    id: number;
    name: string;

    constructor(json:IDocumentProduct) {
        this.basicCode = json.basicCode;
        this.codeSuffix = json.codeSuffix;
        this.id = json.id;
        this.name = json.name;

    }

}