import React from "react";
import {Autocomplete, Box, Button, Container, Grid, Paper, TextField, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {PhotoUpsertDialog} from "./PhotoUpsertDialog";
import {useForm} from "react-hook-form";
import {IDocument} from "../../../interfaces/media/document/IDocument";
import ProductAutocompleteSearch from "../../product/ProductAutocompleteSearch";
import {IProductAutocomplete} from "../../../interfaces/product/IProductAutocomplete";
import {IPhotoFilter} from "../../../interfaces/media/photo/IPhotoFilter";
import photoService from "../../../services/PhotoService";
import {IPhoto} from "../../../interfaces/media/photo/IPhoto";
import {ButtonBasic} from "../../shared/ButtonBasic";
import {PhotosFilterContainer} from "./PhotosFilterContainer";
import {useTranslation} from "react-i18next";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {PaperBasic} from "../../shared/PaperBasic";

export const PhotosFilter: React.FC = () => {


    const {t, i18n} = useTranslation();
    const [openDialog, setOpenDialog] = React.useState(false);

    const [loadins, setLoading] = React.useState(false);

    const [autoCompleteProduct, setAutocompleteProduct] = React.useState<IProductAutocomplete | null>(null);

    const [photos, setPhotos] = React.useState<IPhoto[]>([]);

    const [selectedPhotoId, setSelectedPhotoId] = React.useState(0);




    const handleProductSearchAutocomplete = (productLocale: IProductAutocomplete | null) => {
        setAutocompleteProduct(productLocale);
    }

    const handlePhotoDetailClick = (id:number) => {
        setSelectedPhotoId(id);
        setOpenDialog(true);
    }

    const handleNewPhotoClick = () => {
        setSelectedPhotoId(0);
        setOpenDialog(true);
    }

    const handleFilter = () => {
        setSelectedPhotoId(0);
        setLoading(true);

        let ids = [];
        if(autoCompleteProduct){
            ids.push(autoCompleteProduct.id);
        }



        let f:IPhotoFilter = {
            productIds: ids,
            createdAtTo: '',
            createdAtFrom: ''
        }

        photoService.filter(f).then((res) => {
            setPhotos(res);
        }).finally(() => { setLoading(false)})



    }


    return(
        <>
        <Container>
            <Box padding={1} sx={{display: 'flex'}} >
                <Button variant="contained" color={'info'} startIcon={<AddBoxIcon />} onClick={handleNewPhotoClick}>{t('photos.buttons.addNewPhoto')}</Button>
            </Box>
            {/*<Paper elevation={1} sx={{
                margin: "25px 0px 50px 0px",
                padding: "50px"
            }}>
                <Typography variant={'h5'} >{t('photos.prodPhotoFilter')}</Typography>
                <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                        <ProductAutocompleteSearch  onChange={handleProductSearchAutocomplete} label={t('photos.productLink')} resetValOnClick={false} />

                    </Grid>
                    <Grid item xs={12}>
                        <ButtonBasic onClick={handleFilter} appVariant="filter" isLoading={loadins} />
                    </Grid>
                </Grid>

            </Paper>*/}
            <PaperBasic title={t('photos.prodPhotoFilter')} paperMargins={'20px 0px 30px 0px'}>
                <Box sx={{
                    px:3
                }}>
                    <Grid container spacing={1.5}>
                        <Grid item xs={12}>
                            <ProductAutocompleteSearch  onChange={handleProductSearchAutocomplete} label={t('photos.productLink')} resetValOnClick={false} />

                        </Grid>
                        <Grid item xs={12}>
                            <ButtonBasic onClick={handleFilter} appVariant="filter" isLoading={loadins} />
                        </Grid>
                    </Grid>
                </Box>
            </PaperBasic>
            <PhotoUpsertDialog refresh={handleFilter} key={selectedPhotoId}  id={selectedPhotoId} onClose={()=>setOpenDialog(false)} open={openDialog} />
        </Container>
        <Container>
            <PhotosFilterContainer photos={photos} onDetail={(id:number) => handlePhotoDetailClick(id)} />
        </Container>
        </>
    );
}