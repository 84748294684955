import React from "react";
import {Box, Chip, Paper, Typography} from "@mui/material";
import {IDocument} from "../../interfaces/media/document/IDocument";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

interface IProps {
    document: IDocument
}
export const ProductDocumentItem: React.FC<IProps> = (props) => {
    const document = props.document;
    return(
        <Paper sx={{padding: 1, backgroundColor: '#f4f1f1', width: 230, margin: 1}}>
            <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                    <Box display="flex" flexDirection="column" width={50} justifyContent="center" alignItems="center">
                        <a target="_blank" rel="noreferrer" href={document.link}><InsertDriveFileIcon color="info" fontSize="large" /></a>

                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" >{document.name}</Typography>
                        <Typography variant="note" >{document.fileName}</Typography>
                    </Box>
                </Box>
                <Box display="flex">
                    <Chip  label={document.extension} variant="outlined" color="info" size="small" sx={{minWidth: 40, margin: 0.2}} />
                    <Chip  label={document.isActive ? "Aktivní" : "Neaktivní"} variant="outlined" color="info" size="small" sx={{minWidth: 40, margin: 0.2}} />
                    <Chip  label={document.isPublic ? "Veřejný" : "Neveřejný"} variant="outlined" color="info" size="small" sx={{minWidth: 40, margin: 0.2}} />


                </Box>

            </Box>

        </Paper>
    )
}