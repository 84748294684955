import { NullableString } from "../../interfaces/NullableStringType";

import dayjs from "dayjs";
import {IProductErpLastSyncFromEso} from "../../interfaces/product/IProductErpLastSyncFromEso";

export class ProductErpLastSync {
    productId: number;
    erpId: number;
    direction: string;
    isSuccess: boolean;
    lastSyncDate: string;
    message: NullableString;

    constructor(json:IProductErpLastSyncFromEso){
        this.productId = json.productId;
        this.erpId = json.erpId;
        this.direction = json.direction;
        this.isSuccess = json.isSuccess;
        this.lastSyncDate = json.lastSyncDate ? dayjs(json.lastSyncDate).format('DD.MM.YYYY HH:mm')  : "";
        this.message = json.message;
    }

}