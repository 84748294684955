import {useTranslation} from "react-i18next";
import React from "react";
import {AlertAppContext} from "../alert/AlertAppContext";
import {Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import {ButtonBasic} from "../shared/ButtonBasic";
import orderService from "../../services/OrderService";

export const JobSyncOrdersIn = () => {

    const {t } = useTranslation();
    const [auto, setAuto] = React.useState(true);
    const [from, setFrom] = React.useState<string>('');
    const [isLoading, setisLoading] = React.useState(false);
    const appAlert = React.useContext(AlertAppContext);

    const handleSyncOrdersClick = () => {
        setisLoading(true);
        orderService.syncOrdersFromErp('cz', from, auto).then(() => {
            appAlert.showAlert({severity: "success"});
        }).catch((err) => {
            appAlert.showAlert({severity: "error", message: err?.message ?? null})
        }).finally(() => {
            setisLoading(false);
        });
    }

    return(
        <Grid container spacing={2} padding={3} alignItems="flex-start" justifyContent="flex-start" >
            <Grid item xs={12} >
                <Typography textAlign={'left'} variant={'body1'}>ERP-CZ-ORDERS</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography textAlign={'left'} variant={'body2'}>{t('admin.jobs.syncOrdersIn.description')}</Typography>
            </Grid>
            <Grid item md={2} sm={3} xs={6} alignItems="flex-start" justifyContent="flex-start" display={'flex'}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={auto}
                            onChange={(e) =>    setAuto(e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label="Auto"
                />
            </Grid>
            <Grid item md={2} sm={3} xs={6} alignItems="flex-start" justifyContent="flex-start" display={'flex'}>
                {auto ?
                    null
                    :
                    <TextField
                        label={t('admin.jobs.syncOrdersIn.syncFrom')}
                        defaultValue={from}
                        InputLabelProps={{
                            shrink: true
                        }}
                        size={'small'}
                        onChange={(e) =>    setFrom(e.target.value)}
                        type={'date'}
                    />}

            </Grid>
            <Grid item xs={12} alignItems="flex-start" justifyContent="flex-start" display={'flex'}>

                <ButtonBasic isLoading={isLoading} onClick={handleSyncOrdersClick}  appVariant={'sync'} />

            </Grid>





        </Grid>
    );
}