import React, {useEffect} from "react";
import {IBreadcrumbs} from "../interfaces/breadcrumbs/IBreadcrumbs";
import {useTranslation} from "react-i18next";
import {BreadcrumbsBase} from "../components/breadcrumbs/BreadcrumbsBase";
import {Box, Button, Container} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {OrdersFilter} from "../components/order/OrdersFilter";
import {OrderFormDialog} from "../components/order/form/OrderFormDialog";
import {OrderDetailDialog} from "../components/order/OrderDetailDialog";

export const OrdersPage: React.FC = () => {

    const {t} = useTranslation();
    const [openDialogCreate, setOpenDialogCreate] = React.useState(false);
    const [openDialogDetail, setOpenDialogDetail] = React.useState(false);
    const [createdOrderId, setCreatedOrderId] = React.useState<number|null>(null);

    const breadcrumbs: IBreadcrumbs = {
        data: [
            {
                isLink: false,
                name: t('drawer.orders.title'),
                to: ""

            }
        ]
    }

    const onOrderCreate = (id:number) => {
        setOpenDialogCreate(false);
        setCreatedOrderId(id);
        setOpenDialogDetail(true);
    }

    useEffect(() => {
        document.title = t('drawer.orders.title');
    }, []);

    return (<>
        <BreadcrumbsBase breadcrumbs={breadcrumbs}/>
        <Container >
            <Box padding={1} sx={{display: 'flex'}}>
                <Button variant="contained" color={'info'} startIcon={<AddBoxIcon/>}
                        onClick={() => setOpenDialogCreate(true)}>{t('orders.buttons.add')}</Button>
            </Box>



            <OrdersFilter/>






            <OrderFormDialog onOrderCreate={(id:number) => onOrderCreate(id)} onClose={() => setOpenDialogCreate(false)} open={openDialogCreate} />
            {/* TODO: O dodelat refresh po zalozeni objednavky  */}
            <OrderDetailDialog refresh={() => null} orderId={createdOrderId} onClose={() => setOpenDialogDetail(false)} open={openDialogDetail} />
        </Container>



    </>)
}