import React, {FC} from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {Button, Dialog, DialogContent, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";


interface IProps {
    message?: string,
    isOpen: boolean,
    onClose(): void
    onConfirm(): void
}

export const ConfirmDeleteDialog: FC<IProps> = ({isOpen, onClose, onConfirm, message}) => {

    const {t} = useTranslation();


    return (<Dialog
        open={isOpen}
        onClose={onClose}>
        <DialogContent>
            <Grid container >
                <Grid xs={12} item textAlign={'center'}>
                    <HighlightOffIcon sx={{width: '80px', height: '80px', color: 'error.dark'}}/>
                </Grid>
                <Grid xs={12} item textAlign={'center'}>
                    <Typography variant={'h5'}>
                        {message ? message : t('deleteDialog.default')}
                    </Typography>

                </Grid>
                <Grid xs={12} container textAlign={'center'} spacing={2} justifyContent={'center'} mt={1}>

                    <Grid item>
                        <Button variant={'contained'} color={'inherit'} onClick={onClose}>{t('deleteDialog.buttons.close')}</Button>
                    </Grid>
                    <Grid item>
                        <Button variant={'contained'} color={'error'} onClick={onConfirm}>{t('deleteDialog.buttons.confirm')}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>);
}