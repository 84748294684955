import React, {useEffect} from "react";
import customerService from "../../services/CustomerService";
import {ICustomerFilter} from "../../interfaces/customer/ICustomerFilter";
import Customer from "../../models/customer/Customer";
import {Autocomplete, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";


interface IProps {
    onSelect(item: Customer[]): void
    isMultiple: boolean
    size?: "small" | "medium"
}

export const CustomerSelect: React.FC<IProps> = (props) => {

    const {t} = useTranslation();
    const [loaded, setLoaded] = React.useState(false);
    const [customers, setCustomers] = React.useState<Customer[]>([]);

    useEffect(() => {

        if (loaded) return;

        let f: ICustomerFilter = {
            name: null,
            fullText: null
        }

        customerService.filter(f).then((res: Customer[]) => {

            setCustomers(res);
            setLoaded(true);
        });

    }, [loaded]);

    const onChange = (item: Customer | null) => {
        if (item === null) {
            props.onSelect([]);
        } else {
            const items: Array<Customer> = [item];
            props.onSelect(items);
        }
    }

    const onChangeMultiple = (items: Customer[] | null) => {
        if (items === null) {
            props.onSelect([]);
        } else {
            props.onSelect(items);

        }
    }


    if (props.isMultiple) {
        return (<Autocomplete
            options={customers}
            multiple
            size={props.size ?? "medium"}
            // value={props.selectedLangs}
            getOptionLabel={(option) => option.name}
            renderInput={(params: any) => <TextField {...params} label={t('general.text.customers')}/>}
            onChange={(_, data) => {
                onChangeMultiple(data)
            }}
        />);
    } else
        return (<Autocomplete
            fullWidth={true}
            options={customers}
            size={props.size ?? "medium"}
            // value={props.selectedLangs}
            getOptionLabel={(option) => option.name}
            renderInput={(params: any) => <TextField {...params} label={t('general.text.customer')}/>}
            onChange={(_, data) => {
                onChange(data)
            }}
        />);


}