import {NetworkService} from "./NetworkService";
import {ILogin} from "../interfaces/auth/ILogin";
import {IRecoveryInputs} from "../interfaces/auth/IRecoveryInputs";

const controller = "/auth";

class AuthService {

    async login(form: ILogin): Promise<any> {
        console.log('test1');
        const {data} = await NetworkService.newConnection().post(`${controller}/login`, form);
        console.log(data);
        console.log('test2');
        return data;
    }

    async reset(form: ILogin): Promise<any>{
        const {data} = await NetworkService.newConnection().post(`${controller}/reset`, form);
        return data;
    }

    async recovery(form: IRecoveryInputs) {
        const {data} = await NetworkService.newConnection().post(`${controller}/recovery`, form);
        return data;
    }

}

const authService = new AuthService();
export default authService;