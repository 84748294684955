import React from "react";
import {IBreadcrumbs} from "../../interfaces/breadcrumbs/IBreadcrumbs";
import {Box} from "@mui/material";
import {BreadcrumbsBase} from "../../components/breadcrumbs/BreadcrumbsBase";
import {PhotosFilter} from "../../components/media/photos/PhotosFilter";

export const PhotosPage: React.FC = () => {
    const breadcrumbs: IBreadcrumbs = {
        data: [
            {
                isLink: true,
                name: "Media",
                to: ""

            },
            {
                isLink: false,
                name: "Fotografie",
                to: ""

            }
        ]
    }

    return(
        <Box>
            <BreadcrumbsBase breadcrumbs={breadcrumbs} />
            <PhotosFilter />
        </Box>
    );

}