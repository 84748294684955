import React, {useEffect} from "react";
import {
    Box,
    Button,
    Chip, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useTheme
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Order} from "../../models/order/Order";
import {IDialogBase} from "../../interfaces/IDialogBase";
import {OrderDetail} from "./OrderDetail";
import orderService from "../../services/OrderService";
import {ButtonBasic} from "../shared/ButtonBasic";
import LaunchIcon from "@mui/icons-material/Launch";
import {DoNotDisturb} from "@mui/icons-material";
import {AlertAppContext} from "../alert/AlertAppContext";
import {BasicConfirmDialog} from "../shared/BasicConfirmDialog";
import {IBasicConfirmDialog} from "../../interfaces/IBasicConfirmDialog";

interface IProps extends IDialogBase {
    orderId: number|null
    refresh: () => void
}


export const OrderDetailDialog: React.FC<IProps> = (props) => {
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [actionLoading, setActionLoading] = React.useState<boolean>(false);
    const [confirm, setConfirm] = React.useState<IBasicConfirmDialog>({
        title: '',
        message: ''
    });
    const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
    const theme = useTheme();
    const {t} = useTranslation();
    const appAlert = React.useContext(AlertAppContext);

    const [order, setOrder] = React.useState<Order|null>(null);

    const handleSendOrder = () => {
        //todo: O hlasky
        orderService.syncOutOrder(order?.id ?? 0, 'cz').then( () => {

        }).catch(() => {

        }).finally(() => {

        });
    }

    const cancelOrder = () => {
        setActionLoading(true);
        orderService.cancelOrder(order?.id ?? 0).then( () => {
            appAlert.showAlert({severity: "success"});
            props.refresh();
        }).catch((err) => {
            appAlert.showAlert({severity: "error", message: err?.message ?? null})
        }).finally(() => {
            setActionLoading(false);
            setLoaded(false);
        });
    }

    const handleCancelButtonClick = () => {
        setConfirm({message: 'Opravdu stornovat objednávku?', title: 'STORNO OBJEDNÁVKY'});
        setOpenConfirm(true);

    }




    useEffect(() => {
        if(!loaded && props.orderId){
            orderService.detail(props.orderId).then((res) => {
                setOrder(res);
            }).catch((err) => {
                console.error(err);
            }).finally(() => setLoaded(true));
        }
    }, [loaded, props.orderId])

    return(
        <Dialog open={props.open}
                onClose={props.onClose}
                fullWidth={true}
                maxWidth="xl"
        >
            <DialogTitle sx={{
                p: 0

            }}>
                    <Box display="flex"   flexDirection="column" p={2}>
                        <Box display="flex" flexDirection="row" alignItems="center" >
                            <Chip color={orderService.returnOrderStateChipColor(order?.orderState.id ?? 0)} label={order?.orderState.name} sx={{marginRight: 2}} />
                            <Typography >  {order ? order.orderNumber : null}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
                            <Typography variant="note">{order?.orderState.note}</Typography>
                        </Box>
                    </Box>





            </DialogTitle>
            <DialogContent sx={{padding: 0, width:'100%', maxWidth: '100%'}}>
                <OrderDetail order={order}  loaded={loaded} />
            </DialogContent>
            <DialogActions  sx={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column',padding: 3, backgroundColor: theme.palette.lightBlue.light}}>
                {actionLoading ? <CircularProgress /> :

                    <Box display="flex" justifyContent="flex-end" mb={2}>
                        <ButtonBasic appVariant="close" onClick={() => {props.onClose(true)}} mr={2} />

                        {order?.orderState.id === 1 ?
                            <Button  sx={{marginRight: 2}} color="warning" startIcon={<DoNotDisturb />} variant="outlined" onClick={handleCancelButtonClick}>Stornovat</Button>
                            : null}


                        <Button startIcon={<LaunchIcon />} variant="outlined" onClick={handleSendOrder}>Odeslat do ERP</Button>

                    </Box>

                }

            </DialogActions>
            <BasicConfirmDialog open={openConfirm} onClose={() => setOpenConfirm(false)} onConfirm={cancelOrder} data={confirm} />
        </Dialog>

    )
}