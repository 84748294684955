import React from "react";
import {createTheme, getContrastRatio} from "@mui/material";
import {alpha} from "@mui/material/styles";


export const drawerWidth:Number = 240;

declare module "@mui/material/styles" {
    interface Palette {
        actionColor: Palette['primary'];
        actionColor2: Palette['primary'];
        lightBlue: Palette['primary'];
    }
    interface PaletteOptions {
        actionColor?: PaletteOptions['primary'];
        actionColor2?: PaletteOptions['primary'];
        lightBlue?: PaletteOptions['primary'];
    }

    interface TypographyVariants {
        note: React.CSSProperties;
        paperHeading: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        note?: React.CSSProperties;
        paperHeading?: React.CSSProperties;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        actionColor: true;
        actionColor2: true;
    }
   
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        note: true;
        paperHeading: true;
    }
}



const lightBlueBase = '#03a9f4';
const lightBlueMain = alpha(lightBlueBase, 0.4);

export const newDTtheme = createTheme({
    palette: {
        primary: {
            main: '#3a8ec0',
            light: '#92c4dd',
            dark: '#0b4473'
        },
        secondary: {
            main: '#f29d48',
            light: '#fbe0bd',
            dark: '#cf6338'
        },
        actionColor: {
            main: '#dd3ca2',
            light: '#ecbbe0',
            dark: '#cc0388',
            contrastText: getContrastRatio('#dd3ca2', '#fff') > 3.5 ? '#fff' : '#111'
        },
        actionColor2: {
            main: '#C4C4C4',
        },
        lightBlue: {
            main: lightBlueBase,
            light: alpha(lightBlueBase, 0.08),
            dark: alpha(lightBlueBase, 0.6),
            contrastText: getContrastRatio(lightBlueMain, '#fff') > 4.5 ? '#fff' : '#111',
        },


    },
    typography: {
        h1: {
          fontSize: '2.75rem',
            color: '#3a8ec0'
        },
        h2: {
           fontSize: '2.5rem',
            color: '#3a8ec0'
        },
        h3: {
          fontSize: '1.6rem',
            color: '#3a8ec0'
        },
        h4: {
            fontSize: '1.35rem',
            color: '#3a8ec0'
        },
        h5: {
          fontSize: '1.1rem',
            color: '#3a8ec0'
        },
        note: {
            fontSize: '0.9rem',
            color: '#4f4f4f'
        },
        paperHeading: {
            display: 'flex',
            justifyContent: 'left',
            fontSize: '1.2rem',
            fontWeight: 'bolder',
            //color: alpha('#525252',0.8),
            color: alpha('#675355',0.9),
            width: '100%',
            borderBottom: '1px solid #E0E0E0',
            //borderBottomColor: '#675355'
            //background: 'linear-gradient(to bottom, #bfbfbf, #e1e1e1)',
            //backgroundColor: '#e1e1e1',
            marginTop: 0,
            paddingTop: 18,
            paddingBottom: 18,
            paddingLeft: 20,
            backgroundColor: alpha(lightBlueBase, 0.08)


        }

    }




});

