import React from "react";
import {Dialog} from "@mui/material";
import {IDialogBase} from "../../interfaces/IDialogBase";

interface IProps extends IDialogBase{

}

export const OrdersDialog: React.FC<IProps> = (props) => {

    return(<Dialog open={props.open}
    onClose={props.onClose}
    maxWidth={'xl'}>
        orders table
    </Dialog>)

}