import React from "react";
import {Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import customerService from "../../services/CustomerService";
import {ButtonBasic} from "../shared/ButtonBasic";
import {AlertAppContext} from "../alert/AlertAppContext";



export const JobSyncCustomers: React.FC = () => {
    const {t } = useTranslation();
    const [auto, setAuto] = React.useState(true);
    const [from, setFrom] = React.useState<string>('');
    const [isLoading, setisLoading] = React.useState(false);
    const appAlert = React.useContext(AlertAppContext);


    const handleSyncCustomersClick = () => {
        setisLoading(true);
        customerService.syncFromErp('cz', from, auto).then(() => {
            appAlert.showAlert({severity: "success"});
        }).catch((err) => {
            appAlert.showAlert({severity: "error", message: err?.message ?? null})
        }).finally(() => {
            setisLoading(false);
        });
    }
    return (
            <Grid container spacing={2} padding={3} alignItems="flex-start" justifyContent="flex-start" >
                <Grid item xs={12} >
                    <Typography textAlign={'left'} variant={'body1'}>ERP-CZ-CUSTOMER</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography textAlign={'left'} variant={'body2'}>{t('admin.jobs.syncCustomers.description')}</Typography>
                </Grid>
                <Grid item md={2} sm={3} xs={6} alignItems="flex-start" justifyContent="flex-start" display={'flex'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={auto}
                                onChange={(e) =>    setAuto(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Auto"
                    />
                </Grid>
                <Grid item md={2} sm={3} xs={6} alignItems="flex-start" justifyContent="flex-start" display={'flex'}>
                    {auto ?
                      null
                        :
                        <TextField
                            label={t('admin.jobs.syncCustomers.syncFrom')}
                            defaultValue={from}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            onChange={(e) =>    setFrom(e.target.value)}
                            type={'date'}
                        />}

                </Grid>
                <Grid item xs={12} alignItems="flex-start" justifyContent="flex-start" display={'flex'}>

                    <ButtonBasic isLoading={isLoading} onClick={handleSyncCustomersClick}  appVariant={'sync'} />

                </Grid>





            </Grid>
    );
}