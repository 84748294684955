import React, {useEffect} from "react";
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import categoryService from "../../services/CategoryService";
import {ICategory} from "../../interfaces/category/ICategory";
import {CategoryTreeStack} from "./CategoryTreeStack";
import {CategoryTreeSelected} from "./CategoryTreeSelected";
import {CategoryUpsertDialog} from "./CategoryUpsertDialog";
import {PaperBasic} from "../shared/PaperBasic";
import {useTranslation} from "react-i18next";
import AddBoxIcon from "@mui/icons-material/AddBox";





//type TCatBase = {[]:TUpdatedCategory;}
interface IProps {
    showSaveButton: boolean,
    showEditButton: boolean,
    onSave: (names: string[], selectedId: number, matrix: number[]) => void,
    matrix: number[],
    tree: string[]

}


export const CategoryTree:React.FC<IProps> = (props) => {

    const {t} = useTranslation();


    const [isLoaded, setIsLoaded] = React.useState(false);
    const [categories, setCategories] = React.useState<ICategory[]>([]);
    const [stackMatrix, setStackMatrix] = React.useState<number[]>(props.matrix.length > 0 ? props.matrix : [0]);
    const [tree, setTree] = React.useState<string[]>(props.tree.length > 0 ? props.tree : []);

    const [selectedCatId, setSelectedCatId] = React.useState<number>(0);
    const [selectedParentId, setSelectedParentId] = React.useState<number>(0);
    const [openUpsertModal, setOpenUpsertModal] = React.useState(false);






    const handleSave = () => {
        setSelectedCatId(selectedCatId);
        props.onSave(tree, selectedCatId, stackMatrix);
    }

    const fetchCategories = async () => {
        const res = await categoryService.get();
        /*const updatedRes:TUpdatedCategory[] = res.map((category) => ({
        ...category,
            isVisible: false,
            tier: 0
        })
        );*/

        //catBase vytvari objet s indexem 0 (tier co se nebude zobrazovat)

        setCategories(res);
        //console.log(categories);
    }


    const handleStackButtonClick = (id:number, parentId:number,  stackIndex:number) => {
        let currentCat = categories.find(f => f.id === id && f.parentId === parentId);
        if(currentCat === undefined)
            return null;
        let sMatrix = [...stackMatrix];
        let toReduce = sMatrix.length - (stackIndex + 1);
        let sTree = [...tree];
        let toReduceTree = sTree.length - (stackIndex );

        sMatrix.length -= toReduce;
        sTree.length -= toReduceTree;
        sMatrix.push(currentCat.id);
        sTree.push(currentCat.name);





        setStackMatrix(sMatrix);
        setTree(sTree);
        setSelectedCatId(currentCat.id);
        setSelectedParentId(parentId);

    }

    const handleEdit = () => {
        setOpenUpsertModal(true);

    }



    const refresh = () => {
        fetchCategories().then(() => {
            setStackMatrix([0]);
            setSelectedParentId(0);
            setSelectedCatId(0);
            setTree([]);
        });

    }

    const handleCreate= () => {
        setStackMatrix([0]);
        setSelectedParentId(0);
        setSelectedCatId(0);
        setTree([]);
        setOpenUpsertModal(true);
    }


    useEffect( () => {
        if (!isLoaded) {
            fetchCategories().then(() => {
                setIsLoaded(true);
            })
        }
    }, [isLoaded])

    if(!isLoaded){
        return(
            <CircularProgress />
        );
    }


    return(
        <>
            {props.showEditButton ?

                <Box padding={2} textAlign="left">
                    <Button variant="contained" onClick={handleCreate} color={'info'} startIcon={<AddBoxIcon />} >{t('categories.buttons.newCategory')}</Button>
                </Box>

                : null}

        <PaperBasic title={t('categories.title')}>


        <Box sx={{
            px:3
        }}>

            <Box padding={2} textAlign="left">
                {tree.length === 0 ?
                    <Typography variant="note">{t('categories.chooseCategory')}</Typography>
                    :

                    <CategoryTreeSelected tree={tree} showText={true} />
                }


            </Box>

            <Box sx={{
                display: 'flex',
                overflowY: 'hidden',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                p: 2
            }}>

                {stackMatrix.length > 0 ? stackMatrix.map((stack, i) => {
                    return(

                        <CategoryTreeStack stackMatrix={stackMatrix} selectedParentId={selectedParentId} selectedCatId={selectedCatId} key={'catStack_' + i} stackIndex={i} categories={categories} parentId={stack} useTargetData={handleStackButtonClick} />
                    );
                }) : null}

            </Box>
            <Box display="flex" justifyContent="flex-start" padding={2}>
                {props.showSaveButton && selectedCatId > 0 ?

                    <Button variant="contained" color="primary" onClick={handleSave}>{t('general.buttons.select')}</Button>

                    : null}

                {props.showEditButton && selectedCatId > 0 ?

                    <Button variant="contained" color="primary" onClick={handleEdit}>Detail</Button>

                    : null}

            </Box>
            {openUpsertModal ?
                <CategoryUpsertDialog onRefresh={() => refresh()} key={selectedCatId} id={selectedCatId} onClose={() => setOpenUpsertModal(false)} open={openUpsertModal} />
                : null}



        </Box>
        </PaperBasic>
        </>

    );
}