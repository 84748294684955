import React, {useEffect, useRef} from "react";
import {
    Checkbox, Chip, FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField, Typography
} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {IUserInputs} from "../../interfaces/user/IUserInputs";
import {useTranslation} from "react-i18next";
import userService from "../../services/UserService";
import {AlertAppContext} from "../alert/AlertAppContext";
import {IUserRole} from "../../interfaces/user/./IUserRole";
import CancelIcon from "@mui/icons-material/Cancel";

interface IProps {
    id?: number

    onSuccess(): void


}

export const UpsertUser: React.FC<IProps> = (props) => {

    const {t} = useTranslation();
    const appAlert = React.useContext(AlertAppContext);
    const [defaultValues, setDefaultValues] = React.useState<IUserInputs>();
    const [changePwd, setChangePwd] = React.useState<boolean>(true);
    const [permissions, setPermissions] = React.useState<IUserRole[]>([]);
    const [selected, setSelected] = React.useState<any>([]);

    const {
        setValue,
        setError,
        watch,
        handleSubmit,
        register,
        formState: {errors},
        control
    } = useForm<IUserInputs>({
        defaultValues: defaultValues
    });

    const password = useRef({});
    password.current = watch("password", "");

    const username = useRef({});
    username.current = watch("username");


    useEffect(() => {


        if (props.id && props.id > 0) {

            setChangePwd(false);

            const detail = userService.get(props.id);
            const roles = userService.roles();

            Promise.all([detail, roles]).then((data) => {


                setValue("username", data[0].username);
                setValue("note", data[0].note);
                setValue("firstName", data[0].firstName);
                setValue("lastName", data[0].lastName);
                setValue("position", data[0].position);
                setValue("email", data[0].email);

                setPermissions(data[1]);

                let roles = [];

                for (let role in data[0].roles) {
                    let item = data[0].roles[role];
                    roles.push(item.roleId);
                }

                setSelected(roles);

            }).catch((err) => {
                appAlert.showAlert({severity: "error"});
            });

        } else {

            const roles = userService.roles();

            Promise.all([roles]).then((data) => {

                setPermissions(data[0]);

            }).catch((err) => {
                appAlert.showAlert({severity: "error"});
            });
        }
    }, [props.id]);

    useEffect(() => {
        setValue("permissions", selected);
    }, [selected]);

    const submitForm: SubmitHandler<IUserInputs> = async (agent: IUserInputs) => {

        if (props.id && props.id > 0) {
            await userService.update(agent, props.id).then(() => {

                appAlert.showAlert({severity: "success", message: t('admin.users.editUserDialog.userUpdated')});
                props.onSuccess();

            }).catch((err) => {



                if (Object.keys(err?.errors).length > 0) {

                    for (const [key, value] of Object.entries(err?.errors)) {

                        switch (key.toLowerCase()) {
                            case 'username':
                                setError('username', {
                                    type: 'error',
                                    message: value?.toString()
                                });
                                break;
                        }
                    }
                } else {
                    appAlert.showAlert({severity: "error"});
                }
            });
        } else {
            await userService.create(agent).then(() => {

                appAlert.showAlert({severity: "success", message: t('admin.users.editUserDialog.userCreated')});
                props.onSuccess();

            }).catch((err) => {
                console.log(err);
                if (err?.errors) {
                    for (const [key, value] of Object.entries(err?.errors)) {

                        switch (key.toLowerCase()) {
                            case 'username':
                                setError('username', {
                                    type: 'error',
                                    message: value?.toString()
                                });
                                break;
                        }
                    }
                }
            });
        }


    }


    return (
        <Grid padding={2}>
            <form id={"add-user"} onSubmit={handleSubmit(submitForm)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            value={watch('firstName') ? watch('firstName') : ''}
                            fullWidth
                            type="text"
                            label={t('general.name')}
                            sx={{mb: 4}}
                            {...register('firstName', {
                                required: t('general.validation.required'),
                                maxLength: {
                                    value: 50,
                                    message: t('admin.users.editUserDialog.maxLengthExceeded'),
                                }
                            })}
                            error={errors.firstName ? true : false}
                            helperText={errors.firstName && errors?.firstName?.message}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            value={watch('lastName') ? watch('lastName') : ''}
                            fullWidth
                            type="text"
                            label={t('general.surname')}

                            sx={{mb: 4}}
                            {...register('lastName', {
                                required: t('general.validation.required'),
                                maxLength: {
                                    value: 50,
                                    message: t('admin.users.editUserDialog.maxLengthExceeded'),
                                }
                            })}
                            error={errors.lastName ? true : false}
                            helperText={errors.lastName && errors?.lastName?.message}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            value={watch('position') ? watch('position') : ''}
                            fullWidth
                            type="text"
                            label={t('general.position')}

                            sx={{mb: 4}}
                            {...register('position', {
                                required: t('general.validation.required'),
                                maxLength: {
                                    value: 50,
                                    message: t('admin.users.editUserDialog.maxLengthExceeded'),
                                }
                            })}
                            error={errors.position ? true : false}
                            helperText={errors.position && errors?.username?.message}
                        />
                    </Grid>
                </Grid>
                <TextField
                    type="text"
                    label={t('general.username')}
                    fullWidth
                    value={username.current ? username.current : ''}
                    sx={{mb: 4}}
                    {...register('username', {
                        required: t('general.validation.required'),
                        maxLength: 20
                    })}
                    error={errors.username ? true : false}
                    helperText={errors.username && errors?.username?.message}
                />
                <TextField
                    type="email"
                    label={t('general.email')}
                    fullWidth
                    value={watch('email') ? watch('email') : ''}
                    sx={{mb: 4}}
                    {...register('email', {
                        required: t('general.validation.required'),
                        maxLength: 100
                    })}
                    error={errors.email ? true : false}
                    helperText={errors.email && errors?.email?.message}
                />
                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                    <FormControlLabel
                        control={
                            <Controller
                                name="isDisabled"
                                control={control}
                                defaultValue={false}
                                render={({field: {value, ref, ...field}}) => (
                                    <Checkbox
                                        {...field}
                                        inputRef={ref}
                                        checked={!!value}
                                        color="primary"
                                        size={"medium"}
                                        disableRipple
                                    />
                                )}
                            />
                        }
                        label={t('admin.users.editUserDialog.disableUser')}
                        labelPlacement="end"
                    />
                </Stack>

                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{t('admin.users.editUserDialog.permissions')}</InputLabel>
                        <Select
                            multiple
                            fullWidth={true}
                            value={selected}
                            onChange={(e) => setSelected(e.target.value)}
                            input={<OutlinedInput label="Multiple Select"/>}
                            MenuProps={{sx: {maxHeight: 500}}}
                            renderValue={(selected) => (
                                <Stack gap={1} direction="row" flexWrap="wrap">
                                    {selected.map((value: any) => (
                                        <Chip
                                            key={value}
                                            label={permissions.find(x => x.id === value)?.name}
                                            onDelete={() =>
                                                setSelected(
                                                    selected.filter((item: any) => item !== value)
                                                )
                                            }
                                            deleteIcon={
                                                <CancelIcon
                                                    onMouseDown={(event) => event.stopPropagation()}
                                                />
                                            }
                                        />
                                    ))}
                                </Stack>
                            )}
                        >
                            {permissions.map((name) => (
                                <MenuItem key={name.id} value={name.id} style={{display: 'block'}}>
                                    <Typography variant="body2" noWrap>
                                        {name.id} - {name.name}
                                    </Typography>
                                    <Typography variant="caption" noWrap>
                                        {name.description}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <TextField
                    value={watch('note') ? watch('note') : ''}
                    multiline={true}
                    rows={2}
                    type="text"
                    label={t('general.note')}
                    fullWidth
                    sx={{mb: 4}}
                    {...register('note', {
                        maxLength: {
                            value: 500,
                            message: t('admin.users.editUserDialog.maxLengthExceeded'),
                        }
                    })}
                    error={errors.note ? true : false}
                    helperText={errors.note && errors?.note?.message}
                />

                {props.id && props.id > 0 ?

                    <FormControlLabel control={<Checkbox onClick={() => {
                        setChangePwd(!changePwd)
                    }}/>} label={t('admin.users.editUserDialog.changeUserPassword')}/>
                    : null}

                {((props.id && props.id > 0 && changePwd) || changePwd) ?
                    <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                        <TextField
                            type="password"
                            variant='outlined'
                            label={t('general.password')}
                            fullWidth
                            sx={{mb: 4}}
                            {...register('password', {required: true})}
                            error={errors.password ? true : false}
                            helperText={errors.password && t('general.validation.required')}
                        />
                        <TextField
                            type="password"
                            variant='outlined'
                            label={t('general.passwordAgain')}
                            fullWidth
                            sx={{mb: 4}}
                            {...register('passwordConfirm', {
                                required: t('general.validation.required'),
                                validate: value =>
                                    value === password.current || t('messages.general.pswDoNotMatch')
                            })}
                            error={errors.passwordConfirm ? true : false}
                            helperText={errors.passwordConfirm && errors.passwordConfirm.message}
                        />

                    </Stack> : null
                }

            </form>

        </Grid>

    )
}