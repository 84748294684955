import React from "react";
import {alpha} from "@mui/material/styles";
import {MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";


export default function LanguageSelector()  {

    const {t, i18n} = useTranslation();


    const [pickedLang, setPickedLang] = React.useState(i18n.language)



    const languageChangeHandler = (lang:string) => {
        setPickedLang(lang);
        localStorage.setItem('lang', lang);
        i18n.changeLanguage(lang);
    }

    return(
        <Select size={'small'} value={i18n.language} onChange={(e) => {
            languageChangeHandler(e.target.value)
        }} sx={{
            display: {xs: 'flex', md: 'flex'},
            height: '30px',
            color: {xs: 'black', md: 'black'},
            bgcolor: {xs: 'secondary.main', md: 'inherit'},
            fontSize: 14,
            mt: {xs: 7, md: 0},
            mr: 2,
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: {xs: alpha('#FFFFFF', 0.5), md: 'secondary.main'}
            },
            '& .MuiSvgIcon-root': {
                color: {xs: 'black', md: 'black'}
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: {xs: 'primary.light', md: 'secondary.dark'}
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: {xs: 'primary.light', md: 'secondary.main'},
            },
            ml: 1
        }}>
            <MenuItem value={"cs"}>Česky</MenuItem>
            <MenuItem value={"en"}>English</MenuItem>
        </Select>
    );
}