import {NullableString} from "../../interfaces/NullableStringType";
import {IJobConstructor} from "../../interfaces/job/IJobConstructor";
import dayjs from "dayjs";

export class Job{
    id:number
    code:string
    name:string
    active:boolean
    description: NullableString
    lastRun: string
    lastRunWasSuccess: boolean
    lastRunBy: number
    lastRunMesssage: NullableString
    direction: string
    lastSuccessRun: string
    lastRunLog: NullableString

    constructor(j:IJobConstructor) {
        this.id = j.id;
        this.code = j.code;
        this.name = j.name;
        this.active = j.active;
        this.description = j.description;
        this.lastRun = j.lastRun ? dayjs(j.lastRun).format('DD.MM.YYYY HH:mm')  : "";
        this.lastRunWasSuccess = j.lastRunWasSuccess;
        this.lastRunBy = j.lastRunBy;
        this.lastRunMesssage = j.lastRunMesssage;
        this.direction = j.direction;
        this.lastSuccessRun = j.lastSuccessRun ? dayjs(j.lastSuccessRun).format('DD.MM.YYYY HH:mm')  : "";
        this.lastRunLog = j.lastRunLog;


    }

}