import {IDocument} from "../interfaces/media/document/IDocument";
import {NetworkService} from "./NetworkService";
import {IDocumentFilter} from "../interfaces/media/document/IDocumentFilter";
import {Document} from "../models/media/Document";
import {IDocumentDetail} from "../interfaces/media/document/IDocumentDetail";
import {DocumentDetail} from "../models/media/DocumentDetail";

const controller = "/document";

export class DocumentService {
    async create(document: IDocumentDetail): Promise<void> {

        let data = this.createFormData(document);


        await NetworkService.newConnection().post(controller , data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    }

    async update(document: IDocumentDetail): Promise<void> {

        let data = this.createFormData(document);
        console.log(data);


        await NetworkService.newConnection().put(controller , data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    }

    async filter(filter: IDocumentFilter): Promise<IDocument[]> {


        let params = "name=" + filter.name;
        params += "&documentTypeCode=" + filter.documentTypeCode;
        params += "&public="+ filter.public;
        params += "&expiration="+ filter.expiration;
        params += "&active="+ filter.active;
        if(filter.langCodes.length > 0){
            filter.langCodes.forEach((x) => {
                params += "&langCodes=" + x;
            })
        }
        if(filter.productIds.length > 0){
            filter.productIds.forEach((x) => {
                params += "&productIds=" + x.toString();
            })
        }


        const {data} = await NetworkService.newConnection().get<IDocument[]>(controller + "?" + params);

        return data.map(x => new Document(x));

    }

    async detail(id:number): Promise<IDocumentDetail>{
        const {data} = await NetworkService.newConnection().get<IDocumentDetail>(`${controller}/${id}`);
        return new DocumentDetail(data);
    }

    async delete(id:number): Promise<void>{
        await NetworkService.newConnection().delete(`${controller}/${id}`);
    }

    async getBase64(guid: string) :Promise<any>{
        var result = await NetworkService.newConnection().get<any>(`${controller}/${guid}/base64`);
        return result.data;
    }

    createFormData(document: IDocumentDetail): FormData{
        let data = new FormData();
        data.append('name', document.name);
        //data.append('lang', document.lang);
        data.append('DocumentTypeCode', document.documentTypeCode);
        data.append('isPublic', document.isPublic.toString());
        data.append('isActive', document.isActive.toString());
        data.append('note', document.note);
        data.append('expirationDate', document.expirationDate ?? '');
        data.append('id', document.id.toString());
        data.append('guid', document.guid);

        if(document.products.length > 0){

            for (let i = 0; i < document.products.length; i++) {
                data.append('Products', document.products[i].id.toString());
            }
        }

        if(document.langs.length > 0){

            for (let i = 0; i < document.langs.length; i++) {
                data.append('Langs', document.langs[i].code);
            }
        }



        if(document.files){
            for (let i = 0; i < document.files.length; i++) {
                data.append('files', document.files[i]);
            }
        }



        return data;
    }


}

const documentService = new DocumentService();

export default documentService;

