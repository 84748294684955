import {useAuth} from "../context/AuthProvider";
import {Outlet, Navigate} from 'react-router-dom';
import {useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import IUser from "../interfaces/user/IUser";
import {UnAuthorized} from "./auth/UnAuthorized";


type Props = {
    allowedRoles: string[]
}


export const ProtectedRoute = (props: Props) => {
    const {token} = useAuth();

    var defArray: string[];
    defArray = [];
    const [role, setRole] = useState<string[]>(defArray);

    useEffect(() => {


        if (!token) {
            setRole([]);
            return;
        }

        let data = jwtDecode<IUser>(token);
        setRole(data.role);
    }, [token]);



    if (!token) {
        return <Navigate to="/login"/>;
    }



    if( props.allowedRoles.length === 0){
        return <Outlet/>
    }

    if (token && props.allowedRoles && role) {

        let containsAny = false;

        if (role.includes('1') || props.allowedRoles.length === 0) {
            containsAny = true;
        }
        else
        {
            if (!containsAny) {

                for (const element of role) {
                    if (props.allowedRoles.includes(element)) {
                        containsAny = true;
                        break;
                    }
                }

            }
        }

        if (containsAny) return <Outlet/>
    }

    // If authenticated, render the child routes
    return <UnAuthorized/>;
};