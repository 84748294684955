import React, {useEffect, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import productService from "../../services/ProductService";
import i18n from "../../translations/i18n";
import {IProductAutocomplete} from "../../interfaces/product/IProductAutocomplete";


interface IProps {
    onChange: (value:IProductAutocomplete|null) => void
    label: string,
    resetValOnClick: boolean
    size?: "small" | "medium"

}

const ProductAutocompleteSearch: React.FC<IProps> = (props) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<IProductAutocomplete[]>([]);
    const [val, setVal] = useState<IProductAutocomplete|null>(null);



    const handleOptionSelect = (event:React.SyntheticEvent<Element, Event>, value:IProductAutocomplete|null) => {
        setVal(value);
        props.onChange(value);

    };

   /* const returnAutocompleteOption = (option:IProductAutocomplete): string => {
        let r = "";
        r += option.product.basicCode;
        if(option.product.codeSuffix !== null || option.product.codeSuffix !== ""){
            r += "_" + option.product.codeSuffix;
        }
        r += " " + option.name;

        return r;
    }*/

    useEffect(() => {
        if(searchTerm.length > 2){
            productService.filterAutocomplete({fulltext: searchTerm, langCode: i18n.language}).then((res) => {
                setSearchResults(res);
            })
        }

    }, [searchTerm]);


    return(
        <Autocomplete
            size={props.size ?? "medium"}
            options={searchResults}
            //getOptionLabel={(option) => returnAutocompleteOption(option)}
            onInputChange={(event, value) => setSearchTerm(value)}
            renderInput={(params:any) => (
                <TextField {...params} label={props.label} variant="outlined" />
            )}
            value={val}
            onChange={(event, value) => handleOptionSelect(event, value)}
            blurOnSelect={props.resetValOnClick}

        />
    )
}

export default ProductAutocompleteSearch;