import {IDocument} from "../../../interfaces/media/document/IDocument";
import {Box, Chip, Container, Paper, Typography} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import React from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import {TableIsEmpty} from "../../shared/TableIsEmpty";
import {DocumentUpsertDialog} from "./DocumentUpsertDialog";
import {PaperBasic} from "../../shared/PaperBasic";
import {useTranslation} from "react-i18next";


interface IProps{
    data: IDocument[]
    onFilter: () => void
}

interface IPropsTollTip{
    document:IDocument
}

export const DocumentsFilterTable: React.FC<IProps> = (props) => {
    const[id, setId] = React.useState<number>(0);
    const [open, setOpen] = React.useState(false);
    const [timeStamp, setTimeStamp] = React.useState(0);
    const {t, i18n} = useTranslation();


    if(props.data.length === 0){
        return(

                <TableIsEmpty />

        )
    }

    const handleInfoClicl = (id:number) => {
        setId(id);
        setTimeStamp(Date.now());
        setOpen(true);

    }


    const InfoTooltip:React.FC<IPropsTollTip> = (props) => {
        return (
            <Tooltip title={t('documents.tooltipDocDetail')} onClick={() => handleInfoClicl(props.document.id)}>
                <IconButton >
                    <InfoIcon/>
                </IconButton>
            </Tooltip>


        );
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 70
        },
        {
            field: ' ',
            headerName: '',
            width: 30,
            renderCell: (params) => <InfoTooltip document={params.row}  />

        },
        {
            field: 'name',
            headerName: t('general.text.title'),
            width: 200,
        },
        {
            field:'documentTypeCode',
            headerName: t('general.type'),
            renderCell: (params) => (<Chip size="small" label={params.row.documentTypeCode} variant="filled" color="info" />),
            width: 100,
        },
        {
            field:'isActive',
            headerName: t('general.active'),
            width: 100,
            renderCell: (params) => (params.row.isActive ? <CheckBoxIcon color="success" /> : <DisabledByDefaultIcon color="error" />  )
        },
        {
            field:'isPublic',
            headerName: t('general.public'),
            width: 100,
            renderCell: (params) => (params.row.isPublic ? <CheckBoxIcon color="success" /> : <DisabledByDefaultIcon color="error" />  )
        }
    ];

    const getRowId = (row:IDocument) => ('pTable_' + row.id);


    return(

            <PaperBasic title={t('general.searchResults')} paperMargins={'20px 0px 30px 0px'}>
                <Box sx={{
                    px:3
                }}>

                    <Container>
                        <DataGrid
                            rows={props.data}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {page: 0, pageSize: 5},
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            getRowId={getRowId}
                        />
                    </Container>
                    <DocumentUpsertDialog key={timeStamp} id={id} onClose={() => setOpen(false)} open={open} />
                </Box>
            </PaperBasic>

    )

}