import React, {useEffect} from "react";
import {Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {IUnitLocale} from "../../interfaces/unit/IUnitLocale";
import unitService from "../../services/UnitService";
import {useTranslation} from "react-i18next";

interface IProps{
    onChange: (id:number) => void,
    id: number,
    variant: "filled" | "standard" | "outlined" | undefined,
    isDisabled: boolean,
    units?: IUnitLocale[]
}
const UnitSelect: React.FC<IProps> = (props) => {
    const [loaded, setLoaded] = React.useState<boolean>(props.units ? true : false);
    const [units, setUnits] = React.useState<IUnitLocale[]>(props.units ?? []);
    const {t} = useTranslation();


    useEffect(() => {
       if(!loaded  ){
           unitService.get("cs").then( (res)  => {
               setUnits(res);
               setLoaded(true);
           })
       }

    }, [loaded, units]);

    const handleChange = (val:string|number) => {
       props.onChange(Number(val));

    }


    if(!loaded){
        return <CircularProgress /> ;

    }

    if(units.length > 0 && props.isDisabled){
        return(

            <Box>
                <div><Typography variant={'caption'}>{t('products.newProductDialog.basicMeasureUnit')}</Typography> </div>
                {/* eslint-disable-next-line array-callback-return */}
                {units.map((x,i) => {
                    if(x.unitId === props.id){
                        return(
                          <div key={'unit_' + i}>  {x.code} - {x.name}</div>
                        )
                    }

                })}
            </Box>
        )
    }

    if(units.length > 0){
        return(
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t('products.newProductDialog.basicMeasureUnit')}</InputLabel>
                <Select
                    disabled={props.isDisabled}
                    variant={props.variant}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t('products.newProductDialog.basicMeasureUnit')}
                    value={props.id}
                    onChange={(e) => handleChange(e.target.value)}
                >
                    {units.map((x,i) => {
                        return(
                            <MenuItem key={'unit_' + i} value={x.unitId}>{x.code} - {x.name}</MenuItem>
                        )
                    })}

                </Select>
            </FormControl>
        )
    }

    return null;




}

export default UnitSelect;