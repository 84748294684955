import React from "react";
import {Box, IconButton} from "@mui/material";
import {IDocumentProduct} from "../../../interfaces/media/document/IDocumentProduct";
import BackspaceIcon from '@mui/icons-material/Backspace';


interface IProps {
    product: IDocumentProduct,
    index: number,
    onDelete(index: number): void,
}
export const DocumentSelectedProduct: React.FC<IProps> = (props) => {
    return(
        <Box display="flex" margin={0.5} padding={0.5} alignItems="center">
            <div>
                <IconButton aria-label="delete" size="small" onClick={() => props.onDelete(props.index)}>
                    <BackspaceIcon fontSize="small" color="error" />
                </IconButton>
            </div>
            <div>{props.product.basicCode + (props.product.codeSuffix ? ("_" + props.product.codeSuffix)  : "") + '-' + props.product.name}</div>
        </Box>
    )
}