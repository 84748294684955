import React from "react";
import {Box, Container} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import {TableIsEmpty} from "../shared/TableIsEmpty";
import {PaperBasic} from "../shared/PaperBasic";
import Supplier from "../../models/supplier/Supplier";



interface IProps {
    data: Supplier[],
    isLoading: boolean
}

export const SupplierFilterTable: React.FC<IProps> = (props) => {
    const {t} = useTranslation();
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 70
        },
        {
            field: 'name',
            headerName: t('general.title'),
            width: 440
        },
        {
            field: 'code',
            headerName: t('general.code'),
            width: 70
        },
        {
            field: 'idFromErp',
            headerName: 'ERP ID',
            width: 70
        },
        {
            field: 'idNumber',
            headerName: 'IČ',
            width: 90
        },
        {
            field: 'updatedAt',
            headerName: t('general.updatedAt'),
            width: 150
        },
        {
            field: 'createdAt',
            headerName: t('general.createdAt'),
            width: 150
        }

    ]

    if(props.data.length === 0){
        return(
            <Container>
                <TableIsEmpty />
            </Container>
        )
    }

    return(
        <Container>
            <PaperBasic title={t('customers.customersFilterTable.title')} paperMargins={'20px 0px 30px 0px'}>
                <Box sx={{px:3}}>
                    <DataGrid
                        loading={props.isLoading}
                        rows={props.data}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10},
                            },
                        }}
                        pageSizeOptions={[10, 50, 100]}
                    />
                </Box>
            </PaperBasic>

        </Container>
    )
}