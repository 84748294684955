import {NetworkService} from "./NetworkService";
import {IBaseScrollPagination} from "../interfaces/IBaseScrollPagination";
import {INotice} from "../interfaces/noticeBoard/INotice";
import {IBaseScrollPaginationResponse} from "../interfaces/IBaseScrollPaginationResponse";
import {NoticeScrollPaginationResponse} from "../models/noticeBoard/NoticeScrollPaginationResponse";
import {Notice} from "../models/noticeBoard/Notice";
import {INoticeInputs} from "../interfaces/noticeBoard/INoticeInputs";

const controller = "/noticeBoard";


export class NoticeBoardService {
    async filter(f:IBaseScrollPagination): Promise<NoticeScrollPaginationResponse> {
        let params = new URLSearchParams();
        params.append('page', f.page.toString());
        params.append('take', f.take.toString());


        const {data} = await NetworkService.newConnection().get<IBaseScrollPaginationResponse<INotice>>(controller + "?" + params.toString());
        return new NoticeScrollPaginationResponse(data) ;


    }

    async create(notice: INoticeInputs): Promise<INotice> {

        const {data} = await NetworkService.newConnection().post<INotice>(controller, notice );
        return new Notice(data) ;


    }

    async delete(id:number): Promise<void> {
        return await NetworkService.newConnection().delete(controller + "/" + id);
    }

}

const noticeBoardService = new NoticeBoardService();
export default noticeBoardService;