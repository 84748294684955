import React from 'react';
import {Box, Container, Paper} from "@mui/material";
import {NoticeBoardContainer} from "../../components/noticeBoard/NoticeBoardContainer";
import {PaperBasic} from "../../components/shared/PaperBasic";
import {useTranslation} from "react-i18next";

export const DashBoardPage: React.FC = () => {

    const {t, i18n} = useTranslation();

    return(
        <Container>
            <Box sx={{marginTop: 5}}>
                <PaperBasic title={t('dashboard.title')}>
                    <NoticeBoardContainer />
                </PaperBasic>
            </Box>
        </Container>
        );

}