import {Button, Card, CardActions, CardContent, CardMedia,  Typography} from "@mui/material";
import React from "react";
import {IPhoto} from "../../../interfaces/media/photo/IPhoto";
import InfoIcon from '@mui/icons-material/Info';

interface IProps {
    photo: IPhoto
    onDetail: (id:number) => void
}
export const PhotosFilterContainerItem: React.FC<IProps> = (props) => {


    const photo = props.photo;
    return(
        <Card sx={{ width: 260, margin: 1 }}>
                <CardMedia
                    component="img"
                    height="180"
                    image={photo.link}
                    alt={photo.fileName}
                />
                <CardContent
                    sx={{padding: 1, textAlign: 'left'}}
                >
                   {/* <Typography gutterBottom variant="h5" component="div">
                        Lizard
                    </Typography>*/}
                    <Typography variant="body2" color="text.secondary">
                        Počet produktů: {photo.productsCount}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Nahráno: {photo.createdAt}
                    </Typography>
                    <Typography sx={{fontSize: 10}} variant="note" color="text.secondary">
                        {photo.fileName}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button startIcon={<InfoIcon />} onClick={() => props.onDetail(photo.id)} size="medium" >Detail</Button>
                </CardActions>
        </Card>

    )
}