import React from "react";
import {Box, Container,  Typography} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {ButtonBasic} from "../../components/shared/ButtonBasic";
import {PaperBasic} from "../../components/shared/PaperBasic";



export const DevComponentsPage: React.FC = () => {



    return(
        <Box>
            <Container sx={{
                mt:3
            }}>

                <PaperBasic title={'Buttons'} paperMargins={'0px 0px 20px 0px'}>

                <TableContainer sx={{
                    px: 3
                }}>
                    <Table>
                        <TableHead sx={{
                            '& .MuiTableCell-root': {
                                fontWeight: 'bold'
                            }
                        }}>
                            <TableRow >
                                <TableCell>Prvek</TableCell>
                                <TableCell>Název</TableCell>
                                <TableCell>Poznámky</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {/*  -------   SaveButton   -------   */}
                            <TableRow>
                                <TableCell>
                                    <ButtonBasic appVariant={'save'} />
                                </TableCell>
                                <TableCell>BasicButton</TableCell>
                                <TableCell>
                                    <Typography variant={'body2'}>
                                        appVariant={'save'}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            {/*  -------   DeleteButton   -------   */}
                            <TableRow>
                                <TableCell>
                                    <ButtonBasic appVariant={'delete'}/>
                                </TableCell>
                                <TableCell>BasicButton</TableCell>
                                <TableCell>
                                    <Typography variant={'body2'}>
                                        appVariant={'delete'}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            {/*  -------   CloseButton   -------   */}
                            <TableRow>
                                <TableCell>
                                    <ButtonBasic appVariant={'close'}/>
                                </TableCell>
                                <TableCell>BasicButton</TableCell>
                                <TableCell>
                                    <Typography variant={'body2'}>
                                        appVariant={'close'}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            {/*  -------   FilterButton   -------   */}
                            <TableRow>
                                <TableCell>
                                    <ButtonBasic appVariant={'filter'}/>
                                </TableCell>
                                <TableCell>BasicButton</TableCell>
                                <TableCell>
                                    <Typography variant={'body2'}>
                                        appVariant={'filter'}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            {/*  -------   SyncButton   -------   */}
                            <TableRow>
                                <TableCell>
                                    <ButtonBasic appVariant={'sync'}/>
                                </TableCell>
                                <TableCell>Sync button</TableCell>
                                <TableCell>
                                    <Typography variant={'body2'}>
                                        appVariant={'Sync'}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            {/*  -------   ButtonBasic - loading   -------   */}
                            <TableRow>
                                <TableCell>
                                    <ButtonBasic appVariant={'save'} isLoading={true}>
                                    </ButtonBasic>
                                </TableCell>
                                <TableCell>ButtonBasic</TableCell>
                                <TableCell>
                                    <Typography variant={'body2'}>
                                        appVariant={'save'} isLoading={'true'}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            {/*  -------   ButtonBasic   -------   */}
                            <TableRow>
                                <TableCell>
                                    <ButtonBasic customColor={'actionColor.main'}>
                                        Text tlačítka
                                    </ButtonBasic>
                                </TableCell>
                                <TableCell>ButtonBasic</TableCell>
                                <TableCell>
                                    <Typography variant={'body2'}>
                                        customColor={'actionColor'}, text tlačítka v children ButtonBasicu
                                    </Typography>
                                </TableCell>
                            </TableRow>

                        </TableBody>
                        {/*  -------   ButtonBasic - description   -------   */}
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography sx={{fontWeight: 'bolder'}}>Info k BasicButton</Typography><br/>
                                <Typography>
                                    ButtonBasic má následující props: <br/>
                                    <strong>- children?:</strong> ReactNode | string | null (v případě zvolené appVariant se text vyplňuje sám, i s ohledem na jazykové mutace. Pokud se vyplní children, tak mají prioritu ty)<br/>
                                    <strong>- isLoading?:</strong> boolean (v případě, že je true, objevuje se loading kolečko a button je disabled) <br/>
                                    <strong>- appVariant?:</strong> 'save' | 'delete' | 'filter' | 'close' (v případě použití appVarianty se automaticky vyplní i startIcon a změní požadovaná barva) <br/>
                                    <strong>- customColor?:</strong> string (pokud je potřeba libovolná barva na pozadí, je možno využít tento props buď přes '#FEFEFE' a nebo použitím theme barev např. 'primary.main' nebo 'error.main')<br/>
                                    <strong>- onClick?:</strong> () {'=>'} void
                                </Typography>
                            </TableCell>

                        </TableRow>

                    </Table>

                </TableContainer>
                    <br/>
                </PaperBasic>

        {/* --------------------  SURFACES  ---------------------- */}


                <PaperBasic title={'PaperBasic'}>
                    <Container sx={{
                        mx: 1,
                        mb: 2
                    }}>
                    <Typography align={'left'}>
                        Props:<br/>
                        <strong>- children?:</strong> ReactNode | string | null,<br/>
                        <strong>- title?:</strong> string | null,<br/>
                        <strong>- margins?:</strong> string | null (např. ve formátu '15px 0px 15px 0px')<br/><br/>
                        Vyplněný title props automaticky doplňuje předformátovaný nadpis. Pokud title props neexistuje, segment se negeneruje

                    </Typography>
                    </Container>
                </PaperBasic>




            </Container>

        </Box>
    );
}