import React, {useEffect} from "react";
import {Alert, Box, Button, CircularProgress, Grid,Tooltip, Typography} from "@mui/material";
import {ButtonBasic} from "../../shared/ButtonBasic";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import productService from "../../../services/ProductService";
import {ProductErpLastSync} from "../../../models/product/ProductErpLastSync";
import {ErpInfoTabSyncBox} from "./ErpInfoTabSyncBox";
import IconButton from "@mui/material/IconButton";
import {IErpCommResponse} from "../../../interfaces/erp/IErpCommResponse";
import {IZboziFromEso} from "../../../interfaces/product/IProductDataFromErp";
import {ErpInfoTabErpData} from "./ErpInfoTabErpData";
import {AlertAppContext} from "../../alert/AlertAppContext";
import {useTranslation} from "react-i18next";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SyncIcon from "@mui/icons-material/Sync";
import ProductErp from "../../../models/product/ProductErp";
import {ErpInfoTabProductErp} from "./ErpInfoTabProductErp";



interface IProps {
    productId: number
}
const ErpInfoTab: React.FC<IProps> = (props: IProps) => {
    const appAlert = React.useContext(AlertAppContext);

    const [loaded, setLoaded] = React.useState(false);

    const [erpLoading, setErpLoading] = React.useState(false);

    const [erpData, setErpData] = React.useState<IErpCommResponse<IZboziFromEso>|null>(null);

    const [lastSyncOut, setLastSyncOut] = React.useState<ProductErpLastSync[]>([]);

    const [lastSyncIn, setLastSyncIn] = React.useState<ProductErpLastSync[]>([]);

    const {t} = useTranslation();

    const [productErp, setProductErp] = React.useState<ProductErp|null>(null);

    const handleSyncToErpClick = () => {
        setErpLoading(true);
        productService.syncOutProductWithErp(props.productId, 'cz').then(() => {
            appAlert.showAlert({severity: "success" });
            setLoaded(false);
        }).catch((err) => {
            appAlert.showAlert({severity: "error", message: "Došlo k chybě. " + err.message });
            console.log(err);
        }).finally(()=>{
            setErpLoading(false);
        });
    }

    const handleSyncFromErpClick = () => {
        setErpLoading(true);
        productService.syncInProductWithErp(props.productId, 'cz').then(() => {
            appAlert.showAlert({severity: "success" });
            setLoaded(false);
        }).catch((err) => {
            appAlert.showAlert({severity: "error", message: "Došlo k chybě. " + err.message });
            console.log(err);
        }).finally(()=>{
            setErpLoading(false);
        });
    }

    const handleErpFreshDataClick = () => {
        setErpLoading(true);
        productService.loadFreshProductDataFromErp(props.productId, 'cz').then((res) => {
            setErpData(res);
            setErpLoading(false);
            console.log(res);
        });
    }

    useEffect(()=>{
        if(!loaded){
            productService.getErpProductLastSync(props.productId, 'cz').then((res) => {
               setLoaded(true);
               setLastSyncOut(res.filter(x => x.direction === 'out'));
                setLastSyncIn(res.filter(x => x.direction === 'in'));
            });
        }
    },[loaded, props.productId]);

    if(!loaded){
        return(
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
            </Grid>
        )
    }


    return(
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} display={'flex'} alignItems={'center'}>
                    <Typography variant={'h5'}>ESO9 </Typography>
                    <Tooltip placement={'top'} title="Otevřít ERP ESO9 v novém tabu.">
                    <IconButton sx={{marginLeft: 1}} target={'_blank'} href={'https://cloud2.eso.cz/DAMATRADE/esoform.asp?TURL=esoframe.htm'}> <OpenInNewIcon color={'primary'}  /></IconButton>
                    </Tooltip>


                </Grid>
                <Grid item xs={12} >
                    <Button size="small" onClick={() => setLoaded(false)} startIcon={erpLoading ? <CircularProgress size={20} /> : <SyncIcon />}  variant={'outlined'} color={'primary'} >
                        {t('general.refresh')}
                    </Button>
                </Grid>
                <Grid item xs={12} >
                    {lastSyncOut.length === 0 ?
                        <Alert sx={{marginTop: 1}} severity="info">
                            <div>Intranet {'--->'} ERP </div>
                            {t('products.productDetailDialog.erp.noSyncSince')}</Alert>
                        : <ErpInfoTabSyncBox lastSync={lastSyncOut[0]} />

                    }
                    {lastSyncIn.length === 0 ?
                        <Alert sx={{marginTop: 1}} severity="info">
                            <div>ERP {'--->'} Intranet </div>
                            {t('products.productDetailDialog.erp.noSyncSince')}</Alert>
                        : <ErpInfoTabSyncBox lastSync={lastSyncIn[0]} />

                    }

                </Grid>

                <Grid item xs={12}>
                    <ErpInfoTabProductErp productId={props.productId}  />
                </Grid>

                {/* TODO: O pridat omezeni na role */}
                <Grid item xs={12} >
                    <Button onClick={handleErpFreshDataClick} startIcon={erpLoading ? <CircularProgress size={20} /> : <HelpOutlineOutlinedIcon />} sx={{marginRight: 1}} variant={'outlined'} color={'primary'} >
                        {t('products.productDetailDialog.erp.loadFreshData')}
                    </Button>

                    <ButtonBasic mr={1} onClick={handleSyncToErpClick} appVariant={"sync"} isLoading={erpLoading} >
                        {t('products.productDetailDialog.erp.syncOut')}
                    </ButtonBasic>
                    <ButtonBasic onClick={handleSyncFromErpClick}  appVariant={"sync"} isLoading={erpLoading} >
                        {t('products.productDetailDialog.erp.syncIn')}
                    </ButtonBasic>
                </Grid>
            </Grid>
            <Box mt={2}>
                    <ErpInfoTabErpData isLoading={erpLoading}  erpResponse={erpData} />
            </Box>
        </>
    )
}

export default ErpInfoTab;