import {Dialog, DialogActions, DialogContent} from "@mui/material";
import React from "react";
import {IDialogBase} from "../../interfaces/IDialogBase";
import {CategoryTree} from "../category/CategoryTree";
import {ButtonBasic} from "../shared/ButtonBasic";

interface IProps extends IDialogBase {
    onCatSelect: (names: string[], id: number, matrix: number[]) => void,
    showEditButton: boolean,
    matrix: number[],
    tree: string[]
}

const NewProductCategoryTreeModal: React.FC<IProps> = (props) => {
    return(
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth={'xl'}
            fullWidth={true}
        >

            <DialogContent>
                <CategoryTree tree={props.tree} matrix={props.matrix} showEditButton={props.showEditButton} onSave={props.onCatSelect} showSaveButton={true} />
            </DialogContent>
            <DialogActions sx={{padding: 3}}>
                <ButtonBasic onClick={() => {props.onClose(true)}} appVariant="close"/>
            </DialogActions>
        </Dialog>
    )
}

export default NewProductCategoryTreeModal;