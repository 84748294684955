import * as React from 'react';
import {Alert, Box, Grid, TextField, Typography} from "@mui/material";
import {ButtonBasic} from "../shared/ButtonBasic";
import {SubmitHandler, useForm} from "react-hook-form";
import supportService from "../../services/SupportService";
import {AlertAppContext} from "../alert/AlertAppContext";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import {IContactSupportInputs} from "../../interfaces/support/IContactSupportInputs";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {PaperBasic} from "../shared/PaperBasic";

interface IProps {
    onClose(): void
}
export default  function ContactSupport(props: IProps){

    const {t} = useTranslation();
    const appAlert = React.useContext(AlertAppContext);
    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const {setValue, register, handleSubmit, formState: {errors}} = useForm<IContactSupportInputs>();

    useEffect(() => {
        const url = window.location.href;
        setValue('url', url);
    });

    const submitForm: SubmitHandler<IContactSupportInputs> = async (data: IContactSupportInputs) => {


        setIsSaving(true);


        await supportService.send(data).then((res) => {

            appAlert.showAlert({severity: "success", message: 'Zpráva byla odeslána pracovníkům podpory'});
        }).catch((err) => {
            appAlert.showAlert({severity: "error", message: err.response.data.message});
        }).finally(() => {
            setIsSaving(false);
        });
    }

    return(
        <PaperBasic title=' Nahlásit chybu' >

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        py: 1,
                        px: 3
                    }}
                >


                        <form onSubmit={handleSubmit(submitForm)}>
                                <Box >
                                    <Box sx={{marginBottom: 2}}><Alert severity='info'><Typography variant="note">Nahlášení chyby/požadavku na IT support</Typography></Alert></Box>




                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        autoFocus
                                        type='text'
                                        label='Předmět'
                                        placeholder='Zadejte předmět - krátký výstižný název'
                                        variant="outlined"
                                        {...register('subject', {required: true})}
                                        helperText={errors.subject && t('general.validation.required')}
                                    />
                                    <TextField
                                        multiline
                                        rows={8}
                                        margin="normal"
                                        required
                                        autoComplete="current-password"
                                        label='Text zprávy'
                                        placeholder='Zadejte prosím co nejpřesnější popis'
                                        type='text'
                                        variant="outlined"
                                        fullWidth
                                        {...register('message', {required: true})}
                                        helperText={errors.message && t('general.validation.required')}

                                    />
                                    <ButtonBasic color={'primary'} appVariant={"save"} type={"submit"} isLoading={isSaving}
                                                 icon={<MailIcon/>} fullWidth={true}>Odeslat</ButtonBasic>
                                    <Box sx={{marginTop: 4}}> <ButtonBasic appVariant="close" onClick={() => {props.onClose()}} /></Box>



                                </Box>
                        </form>
                </Box>




        </PaperBasic>

    )
}