import {ICountryLocale} from "../../interfaces/country/ICountryLocale";

class CountryLocale implements  ICountryLocale {
    countryCode: string;
    langCode: string;
    name: string;

    constructor(j:ICountryLocale) {
        this.countryCode = j.countryCode;
        this.langCode = j.langCode;
        this.name = j.name;
    }



}

export default CountryLocale;