import {ICustomerAddressConstructor} from "../../interfaces/customer/ICustomerAddressConstructor";

class CustomerAddress {
    id: number;
    name: string;
    street: string;
    city: string;
    postal: string;
    idFromErp: number;
    customerId:number;

    constructor(data: ICustomerAddressConstructor) {
        this.id = data.id;
        this.name = data.name;
        this.street = data.street;
        this.city = data.city;
        this.postal = data.postal;
        this.idFromErp = data.idFromErp;
        this.customerId = data.customerId;
    }


}

export default CustomerAddress;