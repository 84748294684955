import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Avatar, Button,
    Menu,
    MenuItem,
    Tooltip,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {alpha, styled} from '@mui/material/styles';
import LanguageSelector from "./LanguageSelector";
import DrawerContent from "./DrawerContent";
import {useAuth} from "../../context/AuthProvider";
import {jwtDecode} from "jwt-decode";
import IUser from "../../interfaces/user/IUser";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import SearchBar from "./SearchBar";
import ContactSupport from "./ContactSupport";
import {useTranslation} from "react-i18next";
import BugReportIcon from '@mui/icons-material/BugReport';
import {grey} from "@mui/material/colors";

export const drawerWidth = 240;


export default function MainNav() {
    const {t, i18n} = useTranslation();
    const auth = useAuth();


    const {logout} = useAuth();
    const [user, setUser] = React.useState<IUser>();

    useEffect(() => {

        if (auth?.token) {
            const decoded = jwtDecode<IUser>(auth?.token);
            setUser(decoded);
        }

    }, [auth])


    // const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isMenuOpen, setMenuOpen] = React.useState<null | string>(null);

    const [toggleDrawer, setToggleDrawer] = React.useState<boolean>(false);

    const theme = useTheme();

    // Funkce pro mereni aktualniho breakpointu => pouziva se pro dynamicke zobrazovani language selectoru v Draweru nebo AppBaru
    const isMdOrBigger = useMediaQuery(theme.breakpoints.up('md'));


    const handleCloseMenu = () => {
        setMenuOpen(null);
        setAnchorEl(null);
    }

    const handleOpenMenu = (which: string, anchor: HTMLElement) => {
        setAnchorEl(anchor);
        setMenuOpen(which);
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };




    const Search = styled('div')(({theme}) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha('#F7F7F7', 0.98),
        '&:hover': {
            backgroundColor: alpha('#F7F7F7', 0.85),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    }));

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            {/* -------------------   APPBAR - MAIN ------------------           */}

            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    width: {md: `calc(100% - ${drawerWidth}px)`},
                    ml: {md: `${drawerWidth}px`},
                    backgroundColor: 'white',
                    //background: {xs: 'linear-gradient(to top, #0b4473, #3a8ec0)', md: 'white'},
                    color: 'grey',
                    borderBottom: '1px solid #cfcfcf'

                }}
            >
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between', height: {xs: '70px', md: '70px'}}}>
                    <IconButton

                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {md: 'none'}, color: {xs: 'primary.main', md: 'inherit'},}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Box>
                        <Search>
                            <SearchBar/>
                        </Search>
                    </Box>
                    <Box sx={{flexGrow: 0, display: 'flex', alignItems: 'center'}}>
                        <Button sx={{marginRight: 0}} color={'actionColor'} size={'small'} startIcon={<BugReportIcon />} variant={'outlined'} onClick={() => {
                            setToggleDrawer(true)
                        }}>{t('appBar.bugReport')}</Button>
                        {/*                  APPBAR - VYBER JAZYKA           */}

                        {/*{isMdOrBigger && languageSelectorDrawer}*/}
                        {isMdOrBigger && <LanguageSelector/>}

                        {/*                  APPBAR - AVATAR a NASTAVENÍ            */}

                        <Box sx={{
                            ml: {xs: 2, sm: 1}
                        }}>
                            <Tooltip title={t('appBar.avatar.tooltip')}>
                                <IconButton
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleOpenMenu('settings', event.currentTarget)}
                                    sx={{p: 0}}>
                                    <Avatar alt={user?.unique_name} src="/static/images/avatar/2.jpg"
                                            sx={{bgcolor: 'secondary.main', color: 'black', fontWeight: 'bold'}}/>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{mt: '45px'}}
                                id="menu-appbar-setting"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}

                                open={isMenuOpen === 'settings'}
                                onClose={handleCloseMenu}
                            >

                                <MenuItem key={"menu-item-profile"}>
                                    <Link to={"/profile"}>
                                        <Typography textAlign="center">{t('appBar.avatar.passwordChange')}</Typography>
                                    </Link>
                                </MenuItem>

                                <MenuItem key={"menu-item-logout"} onClick={() => {
                                    handleCloseMenu();
                                    logout();
                                }}>
                                    <Typography textAlign="center">{t('appBar.avatar.logout')}</Typography>
                                </MenuItem>

                            </Menu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {sm: drawerWidth + 'px'}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >
                {/*                  DRAWER            */}
                <Drawer
                    // container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', md: 'none'},
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth + 'px',
                            color: 'white',
                            background: 'linear-gradient(to bottom, #0b4473, #3a8ec0)'
                        },

                    }}
                >
                    <DrawerContent/>
                    <LanguageSelector/>
                </Drawer>
                <Drawer
                    variant="permanent"

                    sx={{
                        display: {xs: 'none', md: 'block'}, color: 'white',
                        boxShadow: 10,
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            color: 'white',
                            background: 'linear-gradient(to bottom, #0b4473, #3a8ec0)',

                        },
                        // svetlejsi verze gradientu: background: 'linear-gradient(to bottom, #3a8ec0, #AFF2D8)'
                    }}
                    open
                >
                    <DrawerContent/>

                </Drawer>
            </Box>
            <Drawer anchor={'right'} open={toggleDrawer} onClose={() => {
                setToggleDrawer(false)
            }}>

                <ContactSupport onClose={() => setToggleDrawer(false)}/>
            </Drawer>
        </Box>
    );
}