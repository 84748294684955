import {NetworkService} from "./NetworkService";
import {ICustomerFilter} from "../interfaces/customer/ICustomerFilter";
import Supplier from "../models/supplier/Supplier";
import {ISupplierConstructor} from "../interfaces/supplier/ISupplierConstructor";

const controller = "/supplier";

class SupplierService {

    async syncFromErp(countryCode: string ,from: string, auto: boolean):Promise<any>{
        await NetworkService.newConnection().get(`${controller}/country/${countryCode}/sync?from=${from}&auto=${auto}`);
    }

    async filter(f:ICustomerFilter):Promise<Supplier[]>{
        let str = Object.keys(f).map(function(key) {
            if(f[key] !== null)  return key + '=' + f[key];

        }).join('&');

        const {data} = await NetworkService.newConnection().get<ISupplierConstructor[]>(controller + "?" + str);

        return data.map(x => new Supplier(x));
    }
}

const supplierService = new SupplierService();
export default supplierService;