import React from "react";
import {
    Alert,
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography, useTheme
} from "@mui/material";
import {OrderForm} from "./OrderForm";
import {IDialogBase} from "../../../interfaces/IDialogBase";
import {ButtonBasic} from "../../shared/ButtonBasic";
import {IOrderConstructor} from "../../../interfaces/order/IOrderConstructor";
import {Order} from "../../../models/order/Order";
import orderService from "../../../services/OrderService";
import {useTranslation} from "react-i18next";
import {IOrderCreatedDto} from "../../../interfaces/order/IOrderCreatedDto";


interface IProps extends IDialogBase {
    onOrderCreate: (id:number) => void

}



export const OrderFormDialog: React.FC<IProps> = (props) => {

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const theme = useTheme();
    const {t} = useTranslation();

    //todo: O order state z db
    const orderInitialState:IOrderConstructor = {
        id: 0,
        noteInternal: null,
        noteCustomer: null,
        customer: null,
        customerAddress: null,
        username: null,
        items: [],
        erpStore: null,
        orderNumber: null,
        createdAt: null,
        customerOrderNumber: null,
        orderState: {
            id: 1,
            name: 'Nová',
            note: null
        },
        userCreated: {
            id: 0,
            email: "",
            username: "",
            firstName: "",
            note: "",
            isDisabled: false,
            roles: [],
            lastName: "",
            position: ""
        }
    };

    const [order, setOrder] = React.useState<Order>(() => new Order(orderInitialState));
    const [orderCreated, setOrderCreated] = React.useState<IOrderCreatedDto|null>(null);

    const handleSave = () => {
        order.validate();
        if(!order.isValid){
            //todo: O hlaska
            return false;
        }
        orderService.create(order.returnCreateDto()).then((res) => {
            setOrderCreated(res);
        }).catch(() => {
            //todo: O hlaska
        });

    }
    const handleShowCreated = () => {
        if(orderCreated === null) return;
        props.onOrderCreate(orderCreated.orderId);
    }

    return (
        <Dialog open={props.open}
                    onClose={props.onClose}
                    fullWidth={true}
                    maxWidth="xl"
        >
            <DialogTitle sx={{
                p: 0

            }}>

                <Typography variant={'paperHeading'} >
                    {t('orders.form.newOrder')}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{padding: 0, width:'100%', maxWidth: '100%'}}>

                <OrderForm loaded={loaded} setLoaded={(v:boolean) => setLoaded(v)} order={order} setOrder={(o:Order) => setOrder(o)} />

            </DialogContent>
            <DialogActions  sx={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column',padding: 3, backgroundColor: theme.palette.lightBlue.light}}>
                <Box display="flex" justifyContent="flex-end" mb={2}>
                    <ButtonBasic appVariant="close" onClick={() => {props.onClose(false)}} mr={2} />
                    <ButtonBasic appVariant="save" onClick={handleSave} />
                </Box>
                <Box display="flex" flexDirection="column" >
                    {orderCreated ?
                        <Alert severity="success" >
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <Box>
                                    Objednávka byla úspešně založena pod id: {orderCreated.orderId} číslem: {orderCreated.orderNumber}.
                                </Box>
                                <Box onClick={handleShowCreated} sx={{marginLeft: 2,color: theme.palette.lightBlue.main, cursor: 'pointer', fontWeight: 'bold'}} >
                                   ZOBRAZIT
                                </Box>
                            </Box>

                        </Alert>
                        : null}

                </Box>
            </DialogActions>
        </Dialog>
    )

}