import React from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {ICategory} from "../../interfaces/category/ICategory";
import {useTranslation} from "react-i18next";

interface IProps {
    categories: ICategory[],
    parentId: number,
    useTargetData : (id:number, parentId:number, stackIndex: number) => void,
    stackIndex: number,
    selectedCatId: number,
    selectedParentId: number,
    stackMatrix: number[]
}

export const CategoryTreeStack:React.FC<IProps> = (props) => {

    const {t} = useTranslation();

    const buttonClickHandler = (id:number, parentId:number) => {
        props.useTargetData(id, parentId,  props.stackIndex);

    }
    const arr = props.categories.filter(item => item.parentId === props.parentId);

    const returnColor = (item: ICategory) => {
        if(item.id === props.selectedCatId){
            return 'secondary';
        }
        if(item.id === props.stackMatrix[(props.stackIndex + 1)]){
            return 'primary';
        }

        return 'actionColor';
    }

    const returnVariant = (item: ICategory) => {
        if(item.id === props.selectedCatId){
            return 'contained';
        }
        if(item.id === props.stackMatrix[(props.stackIndex + 1)]){
            return 'contained';
        }

        return 'outlined';


    }


    if(arr.length === 0){
        return(
            <Box sx={{
                mr:2,
                minWidth: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch'
            }}>
                <Box >
                <Typography variant={'h6'} sx={{
                    borderBottom: '3px solid',
                    borderColor: 'actionColor.main',
                    fontSize: '0.9rem',
                    py: '5px',
                    px: 1,
                    mb: '10px',
                    backgroundColor: 'primary.dark',
                    color: 'white'

                }}>{t('categories.categoryLevel')} {props.stackIndex + 1}</Typography>
                </Box>
                <Box sx={{   p: 1,
                    border: '1px solid',
                    borderColor: 'actionColor2.main',
                    height: '100%'}}>

                <Typography color={'actionColor2.main'} >{t('categories.noCategories')}</Typography>
                </Box>
            </Box>
        )
    }

    return(
        <>
        <Box sx={{
            mr:2,
            minWidth: '200px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch'
        }}>
            <Box sx={{

            }}>
            <Typography variant={'h6'} sx={{
                borderBottom: '3px solid',
                borderColor: 'actionColor.main',
                fontSize: '0.9rem',
                py: '5px',
                px: 1,
                mb: '10px',
                backgroundColor: 'primary.dark',
                color: 'white'

            }}>{t('categories.categoryLevel')} {props.stackIndex + 1}</Typography>
            </Box>
        <Stack direction={'column'} sx={{
            p: 1,
            border: '1px solid',
            borderColor: 'actionColor2.main',
            height: '100%'
        }}>

            {arr.map((item, i) => {


                return(
                    <Button variant={returnVariant(item)}
                            color={returnColor(item)}
                            key={i}
                            onClick={() => buttonClickHandler(item.id, item.parentId)}
                            sx={{my:1}}>
                        {item.name}
                    </Button>
                );
            })}

        </Stack>
        </Box>
        </>
    );
}