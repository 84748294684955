import {NullableString} from "../../interfaces/NullableStringType";
import {INotice} from "../../interfaces/noticeBoard/INotice";
import  dayjs from 'dayjs'


export class Notice implements INotice {
    id: number
    name: string
    htmlContent: NullableString
    isFromAdmin: boolean
    createdAt: string
    createdBy: string

    constructor(j:INotice) {
        this.id = j.id;
        this.name = j.name;
        this.htmlContent = j.htmlContent;
        this.createdAt = dayjs(j.createdAt).format('DD.MM.YYYY HH:mm');
        this.isFromAdmin = j.isFromAdmin;
        this.createdBy = j.createdBy;
    }
}