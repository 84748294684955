import React from "react";
import {Container, Paper, Typography} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {IProducer} from "../../interfaces/producer/IProducer";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import {ProducerUpsertDialog} from "./ProducerUpsertDialog";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import {TableIsEmpty} from "../shared/TableIsEmpty";
import {useTranslation} from "react-i18next";
import {PaperBasic} from "../shared/PaperBasic";

interface IProps {
    data: IProducer[]
    onFilter: () => void
}

interface IPropsTollTip{
    producer:IProducer
}
export const ProducerFilterTable: React.FC<IProps> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [producer, setProdcuer] = React.useState<IProducer|null>(null);
    const [rand, setRand] = React.useState<number>(0)
    const {t, i18n} = useTranslation();

    if(props.data.length === 0){
        return(
            <Container>
               <TableIsEmpty />
            </Container>
        )
    }



    const handleInfoClick = (p:IProducer) => {
        setProdcuer(p);
        setRand(Math.random());
        setOpen(true);
    }

    const InfoTooltip:React.FC<IPropsTollTip> = (props) => {
        return (
            <Tooltip title={t('producers.producerFilterTable.tooltipProducerDetail')} onClick={() => handleInfoClick(props.producer)}>
                <IconButton >
                    <InfoIcon/>
                </IconButton>
            </Tooltip>


        );
    }

    const columns: GridColDef[] = [
        {
            field: ' ',
            headerName: '',
            width: 30,
            renderCell: (params) => <InfoTooltip producer={params.row}  />

        },
        {
            field: 'id',
            headerName: 'ID',
            width: 70
        },
        {
            field:'code',
            headerName: t('general.text.code'),
            width: 100
        },
        {
            field: 'name',
            headerName: t('general.text.title'),
            width: 200
        },
        {
            field:'countryCode',
            headerName: t('producers.producerFilterTable.countryCode'),
            width: 100,
        },
        {
            field:'active',
            headerName: t('general.active'),
            width: 100,
            renderCell: (params) => (params.row.active ? <CheckBoxIcon color="success" /> : <DisabledByDefaultIcon color="error" />  )
        },
    ];

    const getRowId = (row:IProducer) => ('pTable_' + row.id);



    return(
        <Container>
            <PaperBasic title={t('producers.producerFilterTable.title')} paperMargins={'20px 0px 30px 0px'}>

                <Container>
                    <DataGrid
                        rows={props.data}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 5},
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        getRowId={getRowId}
                    />
                </Container>
                <ProducerUpsertDialog refresh={props.onFilter} key={rand} producer={producer} onClose={() => setOpen(false)} open={open} />
            </PaperBasic>
        </Container>
    )




}