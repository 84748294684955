import React, {useEffect} from "react";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import langService from "../../services/LangAction";
import {ILang} from "../../interfaces/langs/iLang";
import {useTranslation} from "react-i18next";

interface IProps {
    onSelect(item: ILang[]) : void,
    selectedLangs: ILang[]
}

export  const LangMultiselect: React.FC<IProps> = (props) => {

    const {t} = useTranslation();
    const [langs, setLangs] = React.useState<ILang[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {

        if(loaded) return;

        langService.list().then((res: ILang[]) => {

            setLangs(res);
            setLoaded(true);
        });

    }, [loaded]);

    const onChange = (items: ILang[] | null) => {
        if(items === null){
            props.onSelect([]);
        } else{
            props.onSelect(items);

        }
    }

    if(!loaded)
        return <CircularProgress />

    return( <Autocomplete
        options={langs}
        multiple
        value={props.selectedLangs}
        getOptionLabel={(option) => option.name}
        renderInput={(params: any) => <TextField {...params} label={t('documents.languagesInDoc')}/>}
        onChange={(_, data) => {onChange(data)}}
    />);
}