import React from "react";
import Box from "@mui/material/Box";
import DTlogo from "../../DTlogo-white-transparent-small.png";
import Divider from "@mui/material/Divider";
import {alpha} from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemText from "@mui/material/ListItemText";
import TopicIcon from "@mui/icons-material/Topic";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {Collapse, Link} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {useTranslation} from "react-i18next";
import BuildIcon from '@mui/icons-material/Build';
import {NavLink} from "react-router-dom";
import {ProtectedComponent} from "../ProtectedComponent";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';


export default function DrawerContent() {


    const {t, i18n} = useTranslation();


    const [openDrawerMenu, setOpenDrawerMenu] = React.useState<string[]>([]);
    const handleDrawerMenuClick = (clickIndex: string) => {


        if (openDrawerMenu.includes(clickIndex)) {
            const openCopy = openDrawerMenu.filter((element) => {
                return element !== clickIndex
            });
            setOpenDrawerMenu(openCopy);


        } else {
            const openCopy = [...openDrawerMenu];
            openCopy.push(clickIndex);
            setOpenDrawerMenu(openCopy);
        }
    };

    return (
        <Box>
            {/*<Toolbar />*/}
            <Box sx={{
                mt: 3,
                mb: 2,
                ml: {xs: 3, md: 0}
            }}>
                <Link to={'/'} component={NavLink}>
                    <img src={DTlogo} alt={'DamaTrade logo'} width={'75%'}/>
                </Link>
            </Box>

            <Divider sx={{
                display: {md: 'none'},
                bgcolor: alpha('#FFFFFF', 0.3)
            }}/>

            {/*                  MENU - DASHBOARD            */}
            <List>
                <ListItem key={'dashboard'} disablePadding>
                    <ListItemButton to={'/'} component={NavLink}>
                        <ListItemIcon sx={{color: 'white'}}>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{fontWeight: 'bold'}}
                                      primary={t('drawer.dashboard.title')}/>
                    </ListItemButton>
                </ListItem>

            </List>


            {/*                  MENU - KATALOG            */}

            <List>
                <ListItem key={'catalogue'} disablePadding>
                    <ProtectedComponent role={['100', '102', '104', '106', '107']}>
                        <ListItemButton onClick={() => handleDrawerMenuClick('catalogue')}>
                            <ListItemIcon sx={{color: 'white'}}>
                                <TopicIcon/>
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{fontWeight: 'bold'}}
                                          primary={t('drawer.catalogue.title')}/>
                            {openDrawerMenu.includes('catalogue') ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                    </ProtectedComponent>
                </ListItem>
                <Collapse in={openDrawerMenu.includes('catalogue')} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ProtectedComponent role={['100']}>
                            <ListItemButton sx={{pl: 4}}
                                            component={NavLink}
                                            to={'/products-page'}
                            >
                                <ListItemText primary={t('drawer.catalogue.products')}/>
                            </ListItemButton>
                        </ProtectedComponent>
                        <ProtectedComponent role={['104']}>
                            <ListItemButton sx={{pl: 4}} to={'/producers-page'} component={NavLink}>
                                <ListItemText primary={t('drawer.catalogue.producers')}/>
                            </ListItemButton>
                        </ProtectedComponent>
                        <ProtectedComponent role={['106']}>
                            <ListItemButton sx={{pl: 4}} to={'/suppliers-page'} component={NavLink}>
                                <ListItemText primary={t('drawer.catalogue.suppliers')}/>
                            </ListItemButton>
                        </ProtectedComponent>
                        <ProtectedComponent role={['107']}>
                            <ListItemButton sx={{pl: 4}} to={'/customers-page'} component={NavLink}>
                                <ListItemText primary={t('drawer.catalogue.customers')}/>
                            </ListItemButton>
                        </ProtectedComponent>
                        <ProtectedComponent role={["102"]}>
                            <ListItemButton sx={{pl: 4}} to={'/categories-page'} component={NavLink}>
                                <ListItemText primary={t('drawer.catalogue.categories')}/>
                            </ListItemButton>
                        </ProtectedComponent>
                    </List>
                </Collapse>

            </List>

            {/*                  MENU - OBJEDNAVKA            */}

            <List>
                <ProtectedComponent role={['110', '111']}>
                    <ListItemButton to={'/orders'} component={NavLink}>
                        <ListItemIcon sx={{color: 'white'}}>
                            <AssignmentIcon/>
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{fontWeight: 'bold'}} primary={t('drawer.orders.title')}/>
                    </ListItemButton>
            </ProtectedComponent>
        </List>


    {/*                  MENU - MEDIA            */
    }

    <List>
        <ProtectedComponent role={['108', '109']}>
            <ListItem key={'media'} disablePadding>
                <ListItemButton onClick={() => handleDrawerMenuClick('media')}>
                    <ListItemIcon sx={{color: 'white'}}>
                        <AssignmentIcon/>
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{fontWeight: 'bold'}} primary={t('drawer.media.title')}/>
                    {openDrawerMenu.includes('media') ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
            </ListItem>
        </ProtectedComponent>
        <Collapse in={openDrawerMenu.includes('media')} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ProtectedComponent role={['108']}>
                    <ListItemButton sx={{pl: 4}} to={'/documents-page'} component={NavLink}>
                        <ListItemText primary={t('drawer.media.documents')}/>
                    </ListItemButton>
                </ProtectedComponent>
                <ProtectedComponent role={['109']}>
                    <ListItemButton sx={{pl: 4}} to={'/photos-page'} component={NavLink}>
                        <ListItemText primary={t('drawer.media.photos')}/>
                    </ListItemButton>
                </ProtectedComponent>

            </List>
        </Collapse>

    </List>

    {/*                  Admin            */
    }


    <List>
        <ProtectedComponent role={['1']}>
            <ListItem key={'admin'} disablePadding>
                <ListItemButton onClick={() => handleDrawerMenuClick('admin')}>
                    <ListItemIcon sx={{color: 'white'}}>
                        <AdminPanelSettingsIcon/>
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{fontWeight: 'bold'}} primary={t('drawer.admin.title')}/>
                    {openDrawerMenu.includes('admin') ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
            </ListItem>
        </ProtectedComponent>
        <Collapse in={openDrawerMenu.includes('admin')} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItemButton sx={{pl: 4}} to={'/admin/users'} component={NavLink}>
                    <ListItemText primary={t('drawer.admin.userManagement')}/>
                </ListItemButton>
                <ListItemButton sx={{pl: 4}} to={'/admin/jobs'} component={NavLink}>
                    <ListItemText primary={t('drawer.admin.jobs')}/>
                </ListItemButton>
            </List>
        </Collapse>

    </List>

    <ProtectedComponent role={['1']}>
        {/*                  DEV COMPONENTS            */}
        <List>
            <ListItem key={'devComponents'} disablePadding>
                <ListItemButton to={'/dev-components'} component={NavLink}>
                    <ListItemIcon sx={{color: 'white'}}>
                        <BuildIcon/>
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{fontWeight: 'bold'}}
                                  primary={'Dev Components'}/>
                </ListItemButton>
            </ListItem>

        </List>
    </ProtectedComponent>
    {/*{isXstoMD && <LanguageSelector />}*/
    }
</Box>
)
    ;
}