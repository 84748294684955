import React from "react";
import Login from "../../components/auth/Login";
import {ForgotPassword} from "../../components/auth/ForgotPassword";
import {Box, Grid, Paper} from "@mui/material";


export const LoginPage: React.FC = () => {

    const [showForgotPassword, setShowForgotPassword] = React.useState<boolean>();


    return (
        <Grid container component="main" sx={{height: '100vh'}}>

            <Grid
                item
                xs={false}
                sm={4}
                md={8}
                sx={{
                    backgroundImage: 'url(/assets/images/login-background.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />

            <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img alt='logo' style={{width: 100}} src={"/assets/images/logo.png"}/>
                    {showForgotPassword ?
                        <ForgotPassword onBackToLogin={() => {setShowForgotPassword(false)}}/> :
                        <Login onForgotPassword={() => {
                            setShowForgotPassword(true)
                        }}/>}
                </Box>
            </Grid>

        </Grid>

    )
}