import React from 'react';
import {Box, Container} from "@mui/material";
import {IBreadcrumbs} from "../../interfaces/breadcrumbs/IBreadcrumbs";
import {BreadcrumbsBase} from "../../components/breadcrumbs/BreadcrumbsBase";
import {CategoryTree} from "../../components/category/CategoryTree";


export const CategoryPage: React.FC = () => {
    const breadcrumbs: IBreadcrumbs = {
        data: [
            {
                isLink: false,
                name: "Kategorie",
                to: ""

            }
        ]
    }

    return(
        <Box>
            <BreadcrumbsBase breadcrumbs={breadcrumbs} />
            <Container>
                    <CategoryTree tree={[]} matrix={[]} showEditButton={true} showSaveButton={false} onSave={() => {}} />
            </Container>
        </Box>
    );

}