import {IDocument} from "../../interfaces/media/document/IDocument";


const linkToAzure = process.env.REACT_APP_AZURE_BLOB_ENDPOINT + "/" + process.env.REACT_APP_AZURE_DOCUMENTS_CONTAINER + "/";
export class Document implements IDocument{
    expirationDate: string;
    files: any;
    id: number;
    isActive: boolean;
    isPublic: boolean;
    name: any;
    note: string;
    documentTypeCode: string;
    extension: string;
    guid: string;
    link: string;
    fileName: string;


    constructor(json:IDocument) {
        this.id = json.id;
        this.name = json.name;
        this.isActive = json.isActive;
        this.expirationDate = json.expirationDate ?? '';
        this.isPublic = json.isPublic;
        this.note = json.note;
        this.documentTypeCode = json.documentTypeCode;
        this.extension = json.extension;
        this.guid = json.guid;
        this.link = linkToAzure + json.guid + json.extension;
        //this.link = linkToAzure + "a7b7799a-dad1-4aee-a0b2-dd18b8c2a6e2";
        this.fileName = json.fileName;



    }



}