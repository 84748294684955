import React, {useEffect} from 'react';
import {Box, Container} from "@mui/material";
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {ProductDetailDialog} from "./ProductDetailDialog";
import {IProductLocale} from "../../interfaces/product/IProductLocale";
import {TableIsEmpty} from "../shared/TableIsEmpty";
import productService from "../../services/ProductService";
import {IProductFilter} from "../../interfaces/product/IProductFilter";
import {useTranslation} from "react-i18next";
import {PaperBasic} from "../shared/PaperBasic";
import {MainProductPhoto} from "./MainProductPhoto";

interface IProps {
    data: IProductLocale[],
    selectedId: number|null,
    onFilter: () => void,
    isLoading: boolean
}

interface IPropsTollTip{
    product:IProductLocale
}

export const ProductFilterTable: React.FC<IProps> = (props) => {

    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [product, setProduct] = React.useState<IProductLocale|null>(null);

    useEffect(() => {

        if(props.selectedId){

            let f: IProductFilter = {
                producerId: 0,
                langCode: 'cs',
                fulltext: '',
                id: props.selectedId,
                categoryId: 0,
                states: []
            }

            productService.filter(f).then((res)=> {

                if(res.length === 1)
                {
                    setProduct(res[0]);
                    setOpen(true);
                }

            });


        }

    }, [props.selectedId])

    const onClose = () => {
        setOpen(false);
        props.onFilter();
    }

    const handleInfoClick = (p:IProductLocale) => {
        setProduct(p)
        setOpen(true);
    }


    const InfoTooltip:React.FC<IPropsTollTip> = (props) => {
        return (
            <Tooltip title={t('products.productFilterTable.tooltipItemInfo')} onClick={() => handleInfoClick(props.product)}>
                <IconButton>
                    <InfoIcon/>
                </IconButton>
            </Tooltip>


        );
    }


    if(props.data.length === 0 && props.selectedId === 0){
        return(
            <Container>
               <TableIsEmpty />
            </Container>
        )
    }

    const columns: GridColDef[] = [
        {
            field: 'productId',
            headerName: 'ID',
            width: 70
        },
        {
            field: ' ',
            headerName: '',
            width: 30,
            renderCell: (params) => <InfoTooltip product={params.row}  />

        },
        {
            field:'basicCode',
            headerName: t('products.newProductDialog.basicCode'),
            type: 'number',
            width: 150,
            valueGetter: (params) => (params.row.product.basicCode)

        },
        {
            field: 'codeSuffix',
            headerName: 'Suffix',
            type: 'number',
            width: 160,
            valueGetter: (params) => (params.row.product.codeSuffix)
        },
        {
            field: 'mainPhoto',
            headerName: 'Foto',
            width: 100,
            renderCell: (params) => (params.row.product.mainPhoto == null ? 'Chybí foto' : <MainProductPhoto size="small" product={params.row.product.mainPhoto}/> )
        },
        {
            field: 'name',
            headerName: t('products.productFilterTable.productName'),
            width: 400
        }
    ];

    const getRowId = (row:IProductLocale) => ('pTable_' + row.id);



    return (
        <Container>
            <PaperBasic title={t('products.productFilterTable.title')} paperMargins={'20px 0px 30px 0px'}>


                <Box sx={{
                    px:3
                }}>
                    <DataGrid
                        loading={props.isLoading}
                        rows={props.data}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10},
                            },
                        }}
                        pageSizeOptions={[10, 50, 100]}
                        getRowId={getRowId}
                    />
                </Box>
                <ProductDetailDialog onClose={onClose} open={open} product={product} />
            </PaperBasic>
        </Container>


    );


}