
import {NetworkService} from "./NetworkService";
import {DocumentType} from "../models/media/DocumentType";
import {IDocumentType} from "../interfaces/media/document/IDocumentType";

const controller = "/documenttype";
export class DocumentTypeService{


    async types() : Promise<IDocumentType[]>{
        const { data } = await NetworkService.newConnection().get<IDocumentType[]>(controller + '?lang=cs' );
        return data.map(x=> new DocumentType(x));
    }
}

const documentTypeService = new DocumentTypeService();

export default documentTypeService;