import React from "react";
import {Snackbar, Alert} from "@mui/material";
import {IAlertContextType} from "../../interfaces/alert/IAlertContextType";

export const AlertApp = (props:IAlertContextType) => {

    const a = props.alert;

    if(a === null)
        return null;

    return(
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'left' }} open={true} autoHideDuration={ a.severity !== 'error' ? 6000 : null} onClose={props.hideAlert}>
            <Alert variant='filled' onClose={props.hideAlert} severity={a.severity} sx={{ width: '100%' }}>
                {a.message}
            </Alert>
        </Snackbar>
    )
}