import React from "react";
import {IDialogBase} from "../../interfaces/IDialogBase";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {UpsertUser} from "./UpsertUser";
import {CloseButton} from "../../theming/customButtons";
import CloseIcon from "@mui/icons-material/Close";
import {ButtonBasic} from "../shared/ButtonBasic";
import {useTranslation} from "react-i18next";
import {SubmitHandler, useForm} from "react-hook-form";
import {IUserInputs} from "../../interfaces/user/IUserInputs";

interface IProps extends IDialogBase {

}
export const AddUserDialog: React.FC<IProps> = (props) => {

    const {t} = useTranslation();
    const [isSaving, setIsSaving] = React.useState<boolean>(false);

    const {
        formState: {errors},
    } = useForm<IUserInputs>();



    return (
        <Dialog
            open={props.open}
            onClose={props.onClose} fullWidth={true} maxWidth={"lg"}>

                <DialogTitle sx={{
                    paddingLeft: '0px',
                    paddingRight: '0px'
                }}>
                    <Typography variant={'paperHeading'} py={1} px={4}>
                        {t('admin.users.createNewUser')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <UpsertUser onSuccess={() => {props.onClose(true)} }/>
                </DialogContent>
                <DialogActions>
                    <CloseButton startIcon={<CloseIcon/>} onClick={() => {props.onClose(false)}}>
                        {t('general.buttons.close')}
                    </CloseButton>
                    <ButtonBasic form={"add-user"}  appVariant={"save"} type={"submit"} isLoading={isSaving}/>

                </DialogActions>

        </Dialog>
)
}