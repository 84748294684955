import React from 'react';
import {Box, Grid, TextField, Typography} from "@mui/material";
import {ButtonBasic} from "../shared/ButtonBasic";
import LoginIcon from "@mui/icons-material/Login";
import {SubmitHandler, useForm} from "react-hook-form";
import {ILogin} from "../../interfaces/auth/ILogin";
import authService from "../../services/AuthService";
import {useTranslation} from "react-i18next";
import {AlertAppContext} from "../alert/AlertAppContext";

interface IProps {
    onBackToLogin(): void
}
export const  ForgotPassword = (props: IProps) => {

    const {t} = useTranslation();
    const appAlert = React.useContext(AlertAppContext);
    const {register, handleSubmit, formState: {errors}} = useForm<ILogin>();


    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const submitForm: SubmitHandler<ILogin> = async (data: ILogin) => {


        setIsSaving(true);

        await authService.reset(data).then((res) => {

            appAlert.showAlert({severity: "success", message: 'Požadavek byl zpracován'});
        }).catch((err) => {
            appAlert.showAlert({severity: "error", message: err.response.data.message});
        }).finally(() => {
            setIsSaving(false);
        });
    }

    return(<>
        <Typography component="h1" variant="h5">
            Zapomenuté heslo
        </Typography>
        <form onSubmit={handleSubmit(submitForm)}>
            <Box sx={{mt: 1}}>
                <Typography sx={{fontStyle: 'italic'}}>
                    Zadejte svůj email a my Vám na něj pošele odkaz pro obnovu hesla.
                </Typography>
            </Box>
            <Box>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    autoComplete="email"
                    autoFocus
                    label='Přihlašovací jméno'
                    placeholder='Zadejte přihlašovací jméno'
                    variant="outlined" {...register('username', {required: true})}
                    error={errors.username ? true : false}
                    helperText={errors.username && t('general.validation.required')}
                />
                <ButtonBasic color={'primary'} appVariant={"save"} type={"submit"} isLoading={isSaving}
                             icon={<LoginIcon/>} fullWidth={true}>Obnovit heslo</ButtonBasic>
                <Grid container mt={2}>
                    <Grid item xs sx={{
                        '&:hover': {
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        },
                    }}
                    >
                                    <span onClick={props.onBackToLogin}>
                                        Zpět na přihlášení
                                    </span>

                    </Grid>
                </Grid>
            </Box>
        </form>
    </>)

}