import React from "react";
import {Box, FormControlLabel, Switch} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {useTranslation} from "react-i18next";
import {IPhotoProduct} from "../../../interfaces/media/photo/IPhotoProduct";
import BackspaceIcon from "@mui/icons-material/Backspace";
import Tooltip from "@mui/material/Tooltip";

interface IProps {
    product: IPhotoProduct,
    onDelete(prod: IPhotoProduct): void,
    onMainPhotoChange(prod: IPhotoProduct, value: boolean): void
}

export const PhotoSelectedProduct: React.FC<IProps> = ({product, onDelete, onMainPhotoChange}) => {

    const {t} = useTranslation();

    return (
        <div key={product.id}>
            <Box sx={{mb: "10px"}}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <div>
                        <IconButton title={t('photos.buttons.removeTitle')} aria-label="delete"
                                    color={"error"}
                                    onClick={() => onDelete(product)}>
                            <BackspaceIcon/>
                        </IconButton>
                    </div>
                    <div>{product.basicCode + (product.codeSuffix ? ("_" + product.codeSuffix) : "") + '-' + product.name}</div>
                </Box>
                <Box sx={{ml: '50px', mt: "-10px"}}>
                    <FormControlLabel
                        control={
                            <Tooltip title="Při uložení nastavit fotku jako hlavní na produktu (pokud je při načtení detailu již zaškrtnuto, fotka je již nastavená jako hlavní)">
                            <Switch
                                checked={product.mainPhoto}
                                onChange={() => {onMainPhotoChange(product, !product.mainPhoto)}}
                                inputProps={{ 'aria-label': 'controlled' }}
                                size="small"
                                name="Hlavní foto na produktu"

                            />
                            </Tooltip>
                        }
                        label="Hlavní foto pro produkt"
                    />

                </Box>
            </Box>
        </div>
    )
}