import {IPhoto} from "../../interfaces/media/photo/IPhoto";
import {NullableString} from "../../interfaces/NullableStringType";
import  dayjs from 'dayjs'


const linkToAzure = process.env.REACT_APP_AZURE_BLOB_ENDPOINT + "/" + process.env.REACT_APP_AZURE_IMAGES_CONTAINER + "/" + process.env.REACT_APP_AZURE_IMAGES_SMALL_FOLDER + "/";
export class Photo implements IPhoto{
    cloudUploaded: boolean;
    extension: string;
    fileName: string;
    id: number;
    mimetype: string;
    length: number;
    productsCount: number;
    guid: string;
    link: string;
    createdAt: NullableString;


    constructor(json:IPhoto) {
        this.cloudUploaded = json.cloudUploaded;
        this.extension = json.extension;
        this.fileName = json.fileName;
        this.id = json.id;
        this.mimetype = json.mimetype;
        this.length = json.length;
        this.productsCount = json.productsCount;
        this.guid = json.guid;
        this.link = linkToAzure + json.guid + ".webp";
        this.createdAt = json.createdAt ? dayjs(json.createdAt).format('DD.MM.YYYY HH:mm')  : "";

    }


}