import React from 'react';
import {IAlert} from "../../interfaces/alert/IAlert";
import {IAlertContextType} from "../../interfaces/alert/IAlertContextType";
import {AlertApp} from "./AlertApp";
import {useTranslation} from "react-i18next";

const defaultVal:IAlertContextType = {
    alert: null,
    showAlert: () => {},
    hideAlert: () => {}
}

const AlertAppContext = React.createContext<IAlertContextType>(defaultVal);

interface Props {
    children: React.ReactNode;
}

const AlertAppProvider:React.FC<Props> = ({ children }) => {
    const [alert, setAlert] = React.useState<IAlert|null>(null);
    const {t} = useTranslation();

    const showAlert = (alert:IAlert) => {
        if(alert.severity === "success" && (alert.message === null || alert.message === undefined || alert.message === '')){
            alert.message = t('general.done');
        }
        if(alert.severity === "error" && (alert.message === null || alert.message === undefined || alert.message === '')){
            alert.message = t('general.error');
        }
        setAlert(alert);
    };

    const hideAlert = () => {
        setAlert(null);
    };

    return (
        <AlertAppContext.Provider value={{ alert, showAlert, hideAlert }}>
            {children}
            <AlertApp   alert={alert} hideAlert={hideAlert} showAlert={showAlert}/>
        </AlertAppContext.Provider>
    );
};

export { AlertAppContext, AlertAppProvider };