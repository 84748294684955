import React, {useEffect} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, FormGroup, FormHelperText, Grid, Input, TextField, Typography,
} from "@mui/material";
import {IDialogBase} from "../../interfaces/IDialogBase";
import producerService from "../../services/ProducerService";
import Switch from "@mui/material/Switch";
import {AlertAppContext} from "../alert/AlertAppContext";
import CountrySelect from "../country/countrySelect";
import {IProducer} from "../../interfaces/producer/IProducer";
import {SubmitHandler, useForm} from "react-hook-form";
import {ButtonBasic} from "../shared/ButtonBasic";
import {useTranslation} from "react-i18next";
import {IBasicException} from "../../interfaces/IBasicException";

interface IProps extends IDialogBase{
    producer: IProducer|null,
    refresh: () => void
}
export const ProducerUpsertDialog: React.FC<IProps> = (props) => {

    const [cCode, setCcode] = React.useState<string>(props.producer ? props.producer.countryCode : '');
    const [active, setActive] = React.useState<boolean>(props.producer ? props.producer.active : true);
    const [onActionLoading, setOnActionLoading] = React.useState(false);
    const {t} = useTranslation();



    useEffect(() =>{
        if(props.producer?.countryCode)
        {
            setProdCode(props.producer?.countryCode);
        }

    }, [props.producer])

    const setProdCode = (c:string) => {
        setCcode(c);
        setValue('countryCode', c);

    }


    const {
        setValue,
        register,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm<IProducer>();

    const id = props.producer ? props.producer.id : 0;


    const appAlert = React.useContext(AlertAppContext);

    const dialogTitle: string = props.producer ? t('producers.producerUpsertDialog.titleUpsert') : t('producers.producerUpsertDialog.titleNew');

    const submitForm: SubmitHandler<IProducer> = async (agent: IProducer) => {
        setOnActionLoading(true);
        agent.id = id;
        if(agent.id === 0){
            producerService.insert(agent).then(
                () => {
                    appAlert.showAlert({severity: "success", message: "Hotovo"});
                    props.refresh();
                    props.onClose(true);
                    reset();
                }
            ).catch(
                (err:IBasicException) => {
                    console.log(err);
                    appAlert.showAlert({severity: "error", message: err.message})
                }
            );
        }else {
            producerService.update(agent).then(
                () => {
                    appAlert.showAlert({severity: "success", message: "Hotovo"});
                    props.refresh();
                    props.onClose(true);
                    reset();
                }
            ).catch(
                (err:IBasicException) => appAlert.showAlert({severity: "error", message: err.message})
            );
        }
        setOnActionLoading(false);

    }

    useEffect(() => {
        if(props.producer !== null){
            setValue('name', props.producer.name);
            setValue('code', props.producer.code);
            setValue('website', props.producer.website);
            setValue('note', props.producer.note);
        }

    }, [props.producer, setValue]);



   /* const handleSave = async () => {
        const producer = new Producer({
            name: name,
            note: note,
            id: id,
            website: website,
            code: code,
            active: active,
            countryCode: cCode
        });


        if(!producer.isValid().isValid){
            appAlert.showAlert({severity: "error", message: "Formulář nění validní"});
            console.log("Valid error");
            return false;
        }

        if(producer.id > 0){
            producerService.update(producer).then(
                () => {
                    appAlert.showAlert({severity: "success", message: "Hotovo"});
                    props.refresh();
                    props.onClose();
                }
            ).catch(
                () => appAlert.showAlert({severity: "error", message: "Došlo k chybě"})
            );

        } else {
            producerService.insert(producer).then(
                () => {
                    appAlert.showAlert({severity: "success", message: "Hotovo"});
                    props.refresh();
                    props.onClose();
                }
            ).catch(
                () => appAlert.showAlert({severity: "error", message: "Došlo k chybě"})
            );

        }






    }*/



    return(
        <Dialog
            open={props.open}
            onClose={props.onClose}>
            <form onSubmit={handleSubmit(submitForm)}>
                <DialogTitle sx={{
                    p: 0
                }}>

                    <Typography variant={'paperHeading'} py={1}>
                        {dialogTitle}
                    </Typography>
                </DialogTitle>
                <DialogContent>

                    <Grid container spacing={2} padding={1} mt={1}>

                        <Grid item xs={12}>
                            <TextField
                                label={t('producers.producerUpsertDialog.producerName')}
                                variant={"outlined"}
                                fullWidth={true}
                                {...register('name', {required: true})}
                                error={errors.name ? true : false}
                                helperText={errors.name && t('general.validation.required')}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CountrySelect hasError={!!errors.countryCode} showAll={false}
                                           onChange={(val) => setProdCode(val)} size={'medium'} countryCode={cCode}/>
                            {errors.countryCode &&
                                <FormHelperText
                                    error={true}>{null != errors.countryCode.message ? errors.countryCode.message.toString() : ''}</FormHelperText>}

                            <Input defaultValue={cCode} type={"hidden"} {...register('countryCode', {
                                validate: () => {
                                    if (cCode === '') {
                                        return t('general.validation.required')
                                    }
                                    return true;
                                }
                            })} />


                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label={t('producers.producerUpsertDialog.producersCode')}
                                variant={"outlined"}
                                fullWidth={true}
                                {...register('code', {required: true})}
                                error={errors.code ? true : false}
                                helperText={errors.code && t('general.validation.required')}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label={t('producers.producerUpsertDialog.web')}
                                fullWidth={true}
                                {...register('website')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t('producers.producerUpsertDialog.note')}
                                fullWidth={true}
                                multiline={true}
                                {...register('note')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                    <Switch
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        checked={active}
                                        {...register('active')}
                                         onChange={(e) => setActive(e.target.checked)}
                                    />
                                    }
                                    label={t('producers.producerUpsertDialog.active')}/>
                            </FormGroup>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {props.onClose(false)}}>{t('general.buttons.close')}</Button>
                    <ButtonBasic appVariant={"save"} type={"submit"} isLoading={onActionLoading}/>
                </DialogActions>
            </form>

        </Dialog>
    );
}