import React from "react";
import {useTranslation} from "react-i18next";
import {PaperBasic} from "./PaperBasic";

export const TableIsEmpty: React.FC = () => {
    const {t} = useTranslation();

    return(
            <PaperBasic title={t('producers.producerFilterTable.emptyTable')} paperMargins={'20px 0px 30px 0px'}>
                {/*<Typography variant={'paperHeading'} py={1}>
                    {t('producers.producerFilterTable.emptyTable')}
                </Typography>*/}
            </PaperBasic>
    )
}