import {IDocumentDetail} from "../../interfaces/media/document/IDocumentDetail";
import {ILang} from "../../interfaces/langs/iLang";
import {Lang} from "../lang/Lang";
import {IDocumentProduct} from "../../interfaces/media/document/IDocumentProduct";
import {DocumentProduct} from "./DocumentProduct";

const linkToAzure = process.env.REACT_APP_AZURE_BLOB_ENDPOINT + "/" + process.env.REACT_APP_AZURE_DOCUMENTS_CONTAINER + "/";
export class DocumentDetail implements IDocumentDetail {
    expirationDate: string;
    files: any;
    id: number;
    isActive: boolean;
    isPublic: boolean;
    name: any;
    note: string;
    documentTypeCode: string;
    extension: string;
    guid: string;
    link: string;
    fileName: string;
    langs: ILang[];
    products: IDocumentProduct[];


    constructor(json:IDocumentDetail) {
        this.id = json.id;
        this.name = json.name;
        this.isActive = json.isActive;
        this.expirationDate = json.expirationDate ?? '';
        this.isPublic = json.isPublic;
        this.note = json.note ?? "";
        this.documentTypeCode = json.documentTypeCode;
        this.extension = json.extension;
        this.guid = json.guid;
        this.link = linkToAzure + json.guid + json.extension;
        this.fileName = json.fileName;
        this.langs =  json.langs && json.langs.length > 0 ? json.langs.map((x) => new Lang(x)) : [];
        this.products = json.products && json.products.length > 0 ? json.products.map((x) => new DocumentProduct(x)) : [];
    }
}