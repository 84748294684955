import React, {useEffect} from "react";
import Switch from "@mui/material/Switch";
import {Box, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import IUserFilter from "../../interfaces/user/IUserFilter";
import UserFilterModel from "../../models/user/UserFilterModel";
import {useTranslation} from "react-i18next";

interface IProps {
    filter: IUserFilter,

    refreshFilter(filter: IUserFilter): void
}

export const UserFilter: React.FC<IProps> = (props) => {

    const {t, i18n} = useTranslation();
    const [onlyActive, setOnlyActive] = React.useState<boolean>(props.filter.onlyActive);
    const [fulltext, setFulltext] = React.useState<string>(props.filter.fulltext);
    const [orderBy, setOrderBy] = React.useState<string>('name');

    useEffect(() => {
        props.refreshFilter(new UserFilterModel(fulltext, onlyActive, orderBy));
    }, [onlyActive, fulltext, orderBy]);

    return (<Grid
        container
        direction="row"
        alignItems="center"
    >
        <Grid item xs={6} md={3}>
            <FormControlLabel control={<Switch checked={onlyActive} onChange={() => {
                setOnlyActive(!onlyActive)
            }}/>}
                              label={t('admin.users.onlyActive')} />

        </Grid>
        <Grid item xs={6} md={6}>
            <TextField fullWidth={true} id="fulltext" type="text" placeholder={t('admin.users.enterSearchText')} onChange={(e) => {
                setFulltext(e.target.value)
            }}/>
        </Grid>

        <Grid item xs={6} md={3}>
            <FormControl>
                <InputLabel id="demo-simple-select-label">{t('general.sortBy')}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={orderBy}
                    label="Řadit dle"
                    onChange={(e) => {
                        setOrderBy(e?.target?.value)
                    }}
                >
                    <MenuItem value={'name'}>{t('general.alphabet')}</MenuItem>
                    <MenuItem value={'date-desc'}>{t('general.mostRecent')}</MenuItem>
                    <MenuItem value={'date-asc'}>{t('general.oldest')}</MenuItem>
                </Select>
            </FormControl>
        </Grid>


    </Grid>)

}