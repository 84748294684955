import React, {useEffect} from "react";
import {ICountryLocale} from "../../interfaces/country/ICountryLocale";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import countryService from "../../services/CountryService";
import i18n from "../../translations/i18n";
import {useTranslation} from "react-i18next";



interface IProps{
    onChange: (val:string) => void,
    size: "small" | "medium",
    countryCode: string,
    showAll: boolean,
    hasError: boolean
}

const CountrySelect: React.FC<IProps> = (props) => {
    const {t, i18n} = useTranslation();

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [countries, setCountries] = React.useState<ICountryLocale[]>([]);

    const labelSize = props.size === "small" ? "small" : "normal";

    useEffect(() => {
        if(!loaded){
            countryService.get(i18n.language).then((res) => {
                setCountries(res);
                setLoaded(true);
            })
        }
    }, [loaded]);

    const handleChange = (val:any) => {
        props.onChange(val);
    }



    if(!loaded){
        return <CircularProgress /> ;

    }

    if(countries.length > 0){
        return(
            <FormControl fullWidth>
                <InputLabel size={labelSize} id="country-select-label">{t('producers.producerFilter.country')}</InputLabel>
                <Select
                    displayEmpty
                    size={props.size}
                    labelId="country-select-label"
                    id="country-select"
                    label={t('producers.producerFilter.country')}
                    value={props.countryCode}
                    error={props.hasError}
                    onChange={(e) => handleChange( e.target.value)}
                >
                    {props.showAll ?
                        <MenuItem key={'country_sel_'} value={''}>
                            {t('producers.producerFilter.all')}
                        </MenuItem>
                        : null}


                    {countries.map((x) => {
                        return(
                            <MenuItem key={'country_sel_' + x.countryCode} value={x.countryCode}>[{x.countryCode}] - {x.name}</MenuItem>
                        )
                    })}

                </Select>
            </FormControl>
        )
    }


    return null;
}

export default CountrySelect;