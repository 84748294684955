import {NetworkService} from "./NetworkService";
import {IContactSupportInputs} from "../interfaces/support/IContactSupportInputs";

const controller = "/support";

class SupportService {

    async send(form: IContactSupportInputs) {

        const {data} = await NetworkService.newConnection().post(`${controller}/send`, form);
        return data;
    }
}

const supportService = new SupportService();

export default supportService;