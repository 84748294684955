import React, {ReactNode} from "react";
import {PaperBasic} from "./PaperBasic";
import {NullableString} from "../../interfaces/NullableStringType";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

interface IProps{
    title: NullableString,
    count: number,
    children?: ReactNode | string | null,
    boxPadding?:number
}



export const TableBasic:React.FC<IProps> = (props) => {
    
    const paperMargin = '20px 0px 30px 0px';
    const {t} = useTranslation();

    if(props.count === 0){
        return(
            <PaperBasic title={props.title} paperMargins={paperMargin}>
                <Typography variant="h5">{t('producers.producerFilterTable.emptyTable')}</Typography>
            </PaperBasic>
        )
    }
    
    
    return(
        <PaperBasic title={props.title} paperMargins={paperMargin} boxPadding={props.boxPadding} >
            {props.children}
        </PaperBasic>
    )   
}