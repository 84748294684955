import {Box, Paper, Typography, useTheme} from "@mui/material";
import {IErpStore} from "../../../interfaces/erpStore/IErpStore";
import React from "react";

interface IProps{
    sklad:IErpStore|null
}

export const OrderFormWarehouseBox:React.FC<IProps> = (props) => {

    const theme = useTheme();

    if(props.sklad === null){
        return(
            <Paper elevation={1}  sx={{backgroundColor: theme.palette.grey[100], padding: 1.2}}>
                <Typography variant="note" >Vyberte sklad</Typography>
            </Paper>
        )
    }

    return(
        <Paper elevation={1}  sx={{backgroundColor: theme.palette.grey[100], padding: 1.2}}>
            <Paper elevation={0}  sx={{backgroundColor: theme.palette.grey[200], padding: 1}}>
                <Box display="flex" flexDirection="column">
                    <Typography variant="note">Sklad:</Typography>
                    <Typography variant="h5" >{props.sklad.name}</Typography>
                    <Typography variant="note" >ID ERP: {props.sklad.idInErp} ID INTRANET: {props.sklad.id}</Typography>
                    <Typography variant="note" >{props.sklad.note}</Typography>
                </Box>
            </Paper>

        </Paper>
    )
}