import {NetworkService} from "./NetworkService";
import {IProductFilter} from "../interfaces/product/IProductFilter";
import {IProductLocale} from "../interfaces/product/IProductLocale";
import ProductLocale from "../models/product/ProductLocale";
import {IProductFilterAutocomplete} from "../interfaces/product/IProductFilterAutocomplete";
import {IProductAutocomplete} from "../interfaces/product/IProductAutocomplete";
import {ProductAutocomplete} from "../models/product/ProductAutocomplete";
import {IProductBasicInfo} from "../interfaces/product/basicInfo/IProductBasicInfo";
import {IProductErpLastSyncFromEso} from "../interfaces/product/IProductErpLastSyncFromEso";
import {ProductErpLastSync} from "../models/product/ProductErpLastSync";
import {IErpCommResponse} from "../interfaces/erp/IErpCommResponse";
import {IZboziFromEso} from "../interfaces/product/IProductDataFromErp";
import {IProductErpConstructor} from "../interfaces/product/IProductErpConstructor";
import ProductErp from "../models/product/ProductErp";
import {IProductAddToOrderInfoConstructor} from "../interfaces/product/IProductAddToOrderInfoConstructor";
import {ProductAddToOrderInfo} from "../models/product/ProductAddToOrderInfo";

const controller = "/product";

class ProductService {
    async filter(filter:IProductFilter): Promise<IProductLocale[]>{

        console.log(filter);
        let str = Object.keys(filter).map(function(key) {
            if(key === 'states') return null;
            return key + '=' + filter[key];
        }).join('&');
        if(filter.states.length > 0){
            for (let i = 0; i < filter.states.length; i++) {
                str +=  '&states=' + filter.states[i];
            }
        }
        const {data} = await NetworkService.newConnection().get<IProductLocale[]>(controller + "?" + str);

        return data.map(x => new ProductLocale(x));
    }

    async filterAutocomplete(filter:IProductFilterAutocomplete): Promise<IProductAutocomplete[]>{

        let str = Object.keys(filter).map(function(key) {
            return key + '=' + filter[key];
        }).join('&');
        const {data} = await NetworkService.newConnection().get<IProductLocale[]>(controller + "/autocomplete/?" + str);

        return data.map(x => new ProductAutocomplete(x));
    }

    async create (productLocale:IProductLocale): Promise<IProductLocale>{
        const {data} = await NetworkService.newConnection().post<IProductLocale>(
          controller, productLocale
        );

        return new ProductLocale(data);
    }

    async update (productLocale:IProductLocale): Promise<IProductLocale>{
        const {data} = await NetworkService.newConnection().put<IProductLocale>(
            controller, productLocale
        );

        return new ProductLocale(data);
    }

    async getLocaleInfo(produtcId:number, langCode:string): Promise<IProductLocale | null>{
         return NetworkService.newConnection().get(`${controller}/${produtcId}/localeInfo/${langCode}`).then((res) => {
             //product locale neexistuje
             if(res.data === '' ){
                 return null;
             } else {
                 return new ProductLocale(res.data);
             }
        });
    }

    async getErpInfo(produtcId:number, countryCode:string): Promise<ProductErp | null>{
        return NetworkService.newConnection().get<IProductErpConstructor>(`${controller}/${produtcId}/erpInfo?countryCode=${countryCode}`).then((res) => {
            //product erp neexistuje
            if(!res.data){
                return null;
            } else {
                return new ProductErp(res.data);
            }
        });
    }

    async getAddToOrderInfo(produtcId:number, langCode:string, countryCode:string): Promise<ProductAddToOrderInfo | null>{
        return NetworkService.newConnection().get<IProductAddToOrderInfoConstructor>(`${controller}/${produtcId}/addToOrderInfo/${langCode}?countryCode=${countryCode}`).then((res) => {
            //product locale neexistuje
            if(!res.data ){
                return null;
            } else {
                let p = new ProductAddToOrderInfo(res.data);
                return p;


            }
        });
    }

    async getBasicInfo(produtcId:number, langCode:string): Promise<IProductBasicInfo>{
        return NetworkService.newConnection().get(`${controller}/${produtcId}/basicInfo/${langCode}`).then((res) => {
            return res.data;

        });
    }

    async setMainPhotoOnProduct(productId:number, photoId:number): Promise<void> {
        await NetworkService.newConnection().put(`${controller}/${productId}/mainPhoto/${photoId}`)
    }

    async syncOutProductWithErp(productId:number, countryCode:string): Promise<void>{
        const data = await NetworkService.newConnection().put(`${controller}/${productId}/country/${countryCode}/sync/out`);
        console.log(data);

    }

    async syncInProductWithErp(productId:number, countryCode:string): Promise<void>{
        const data = await NetworkService.newConnection().get(`${controller}/${productId}/country/${countryCode}/sync/in`);
        console.log(data);

    }

    async syncInProductsFromErp(countryCode: string ,from: string, auto: boolean):Promise<any>{
        await NetworkService.newConnection().get((`${controller}/country/${countryCode}/sync/in?from=${from}&auto=${auto}`));

    }

    async getErpProductLastSync(productId:number, countryCode:string): Promise<ProductErpLastSync[]>{
        const {data} = await NetworkService.newConnection().get<IProductErpLastSyncFromEso[]>(`${controller}/${productId}/country/${countryCode}/sync`);
        return data.map(x => new ProductErpLastSync(x));

    }

    async loadFreshProductDataFromErp(productId:number, countryCode:string): Promise<IErpCommResponse<IZboziFromEso>>{
        const {data} = await NetworkService.newConnection().get<IErpCommResponse<IZboziFromEso>>(`${controller}/${productId}/country/${countryCode}`);

        return data;
    }


}

const productService = new ProductService();

export default productService;




