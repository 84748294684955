import React from 'react';
import NewProductDialog from "./NewProductDialog";
import {Box, Button, Container, FormControl, Grid, TextField} from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import {useTranslation} from "react-i18next";
import ProducerAutocompleteSearch from "../producer/ProducerAutocompleteSearch";
import {IProducer} from "../../interfaces/producer/IProducer";
import {ProductFilterTable} from "./ProductFilterTable";
import {IProductLocale} from "../../interfaces/product/IProductLocale";
import {IProductFilter} from "../../interfaces/product/IProductFilter";
import productService from "../../services/ProductService";
import {ButtonBasic} from "../shared/ButtonBasic";
import {ProtectedComponent} from "../ProtectedComponent";
import {PaperBasic} from "../shared/PaperBasic";
import {ProductState} from "./ProductState";
import CategoryIcon from "@mui/icons-material/Category";
import {CategoryTreeSelected} from "../category/CategoryTreeSelected";
import NewProductCategoryTreeModal from "./NewProductCategoryTreeModal";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface IProps {
    selectedId: number|null;
}

export const ProductFilter: React.FC<IProps> = (props) => {
    const {t} = useTranslation();
    const [newPModal, setNewPModal] = React.useState<boolean>(false);
    const [producer, setProducer] = React.useState<IProducer | null>(null);
    const [products, setProducts] = React.useState<IProductLocale[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [states, setStates] = React.useState<number[]>([1,3]);
    const [categoryTree, setCategoryTree] = React.useState<string[]>([]);
    const [isCatModalOpen ,setIsCatModalOpen] = React.useState<boolean>(false);
    const [categoryId, setCategoryId] = React.useState<number>(0);
    const [fulltext, setFulltext] = React.useState<string>('');

    const handleFilter = () => {
        setIsLoading(true);
        let f: IProductFilter = {
            producerId: producer ? producer.id : 0,
            langCode: 'cs',
            fulltext: fulltext,
            id: 0,
            categoryId: categoryId,
            states: states
        }
        if (producer)
            f.producerId = producer.id;

        productService.filter(f).then((res) => {
            setProducts(res);
        }).catch(() => {
        }).finally(() => setIsLoading(false));

    }

    const handleCancelCategory = () => {
        setCategoryId(0);
        setCategoryTree([]);

    }

    const handleChooseCategory = (names: string[], id: number) => {
        setCategoryId(id);
        setCategoryTree(names);
        setIsCatModalOpen(false);
    }

    return (
        <>
            <Container sx={{
                marginBottom: '25px'
            }}>
                <ProtectedComponent role={["101"]}>
                    <Box padding={1} sx={{display: 'flex', marginBottom: '10px'}}>
                        <Button onClick={() => setNewPModal(true)} variant="contained" startIcon={<AddBoxIcon/>}
                                color={'info'}>{t('products.buttons.add')}</Button>
                    </Box>
                </ProtectedComponent>
                <PaperBasic title={t('products.productFilter.title')} paperMargins={'20px 0px 30px 0px'}>
                    <Box sx={{
                        px:3
                    }}>
                        <Grid container spacing={1.5} p={3}>
                            <Grid item xs={12} sm={4} md={4}>
                                <ProductState multiple={true} onSingleChange={() => null} onMultipleChange={(val) => setStates(val)}  singleId={0} multipleIds={states} disabled={false} />

                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <ProducerAutocompleteSearch onChange={(val) => setProducer(val)}/>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4}>
                                <FormControl fullWidth={true}>
                                    <TextField label={t('products.productFilter.fulltextSearch')} size="small" variant={"outlined"} fullWidth={true} onChange={(event) => setFulltext(event.target.value)} />
                                </FormControl>

                            </Grid>
                            <Grid item xs={12}
                                  sx={{
                                      display:  'flex',
                                      justifyContent: 'flex-start'
                                  }}
                            >
                                <Box marginRight={1} padding={1}>
                                    <Button size='small' onClick={() => setIsCatModalOpen(true)} variant="outlined" color="primary" startIcon={<CategoryIcon />} >{t('general.text.categoryTree')}</Button>
                                </Box>
                                <Box marginRight={0} padding={1}>
                                    <CategoryTreeSelected tree={categoryTree} showText={false} />
                                </Box>
                                {categoryId > 0 ? <Box padding={0.5}> <IconButton color={'error'} onClick={handleCancelCategory}  ><HighlightOffIcon /></IconButton></Box> : null}





                            </Grid>

                            <Grid item xs={12} sx={{
                                display:  'flex',
                                justifyContent: 'left'
                            }}>
                                <ButtonBasic  onClick={handleFilter} appVariant="filter" type="button"
                                             isLoading={isLoading}>{t('general.buttons.toFilter')}</ButtonBasic>
                            </Grid>
                        </Grid>
                    </Box>
                </PaperBasic>

                <NewProductDialog onClose={() => setNewPModal(false)} open={newPModal}/>
                <NewProductCategoryTreeModal tree={[]} matrix={[]} showEditButton={false} onClose={() => setIsCatModalOpen(false)} open={isCatModalOpen} onCatSelect={handleChooseCategory} />
            </Container>

            <ProductFilterTable isLoading={isLoading} selectedId={props.selectedId} data={products} onFilter={handleFilter}/>

        </>
    );

}