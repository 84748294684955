import {NullableString} from "../../interfaces/NullableStringType";
import {ICustomerConstructor} from "../../interfaces/customer/ICustomerConstructor";
import dayjs from "dayjs";

class Customer {
    name: string
    code:string
    idNumber: NullableString
    idFromErp: number
    id: number
    createdAt: string
    updatedAt: string

    constructor(j:ICustomerConstructor) {
        this.name = j.name;
        this.code = j.code;
        this.id = j.id;
        this.idFromErp = j.idFromErp;
        this.id = j.id;
        this.createdAt = j.createdAt ? dayjs(j.createdAt).format('DD.MM.YYYY HH:mm')  : "";
        this.updatedAt = j.updatedAt ? dayjs(j.updatedAt).format('DD.MM.YYYY HH:mm')  : "";
        this.idNumber = j.idNumber;
    }
}

export default Customer;