import {IPhotoDetail} from "../../interfaces/media/photo/IPhotoDetail";
import {IPhotoProduct} from "../../interfaces/media/photo/IPhotoProduct";
import {PhotoProduct} from "./PhotoProduct";

const baseLinkUrl = process.env.REACT_APP_AZURE_BLOB_ENDPOINT + "/" + process.env.REACT_APP_AZURE_IMAGES_CONTAINER;
export class PhotoDetail implements IPhotoDetail {
    id: number;
    guid: string;
    extension: string;
    fileName: string;
    mimeType: string;
    link: string;
    products: IPhotoProduct[];
    length: number;
    cloudUploaded: boolean;
    linkToSmall: string;
    linkToMedium: string;
    linkToOriginal: string;


    constructor(json:IPhotoDetail) {
        this.id = json.id;
        this.guid = json.guid;
        this.extension = json.extension;
        this.fileName = json.fileName;
        this.mimeType = json.mimeType;
        this.link = json.link;
        this.products = json.products && json.products.length > 0 ? json.products.map((x) => new PhotoProduct(x)) : [];
        this.length = json.length;
        this.cloudUploaded = json.cloudUploaded;
        this.link = baseLinkUrl + "/" + process.env.REACT_APP_AZURE_IMAGES_SMALL_FOLDER + "/" + json.guid + ".webp";
        this.linkToSmall = baseLinkUrl + "/" + process.env.REACT_APP_AZURE_IMAGES_SMALL_FOLDER + "/" + json.guid + ".webp";
        this.linkToMedium = baseLinkUrl + "/" + process.env.REACT_APP_AZURE_IMAGES_MEDIUM_FOLDER + "/" + json.guid + ".webp";
        this.linkToOriginal = baseLinkUrl + "/" + process.env.REACT_APP_AZURE_IMAGES_ORIGINAL_FOLDER + "/" + json.guid + json.extension;


    }

}