import {NetworkService} from "./NetworkService";
import {ICategory} from "../interfaces/category/ICategory";
import Category from "../models/category/Category";
import {ICategorySelectItem} from "../interfaces/category/ICategorySelectItem";
import {ICategoryLocale} from "../interfaces/category/ICategoryLocale";
import CategoryLocale from "../models/category/CategoryLocale";

const controller = "/category";

class CategoryService{
    async get ():Promise<ICategory[]>{

        const {data} = await NetworkService.newConnection().get<ICategory[]>(controller);
        return data.map(x => new Category(x));
    }

    async getById( id: number): Promise<ICategory>{
        const {data} = await NetworkService.newConnection().get<ICategory>(`${controller}/${id}`)
        return new Category(data);
    }

    async update(cat:ICategory): Promise<ICategory>{
        const {data} = await NetworkService.newConnection().put<ICategory>(`${controller}`, cat);
        return new Category(data);
    }

    async create(cat:ICategory): Promise<ICategory>{
        const {data} = await NetworkService.newConnection().post<ICategory>(`${controller}`, cat);
        return new Category(data);
    }

    async updateLocale(cat:ICategoryLocale): Promise<ICategoryLocale>{
        const {data} = await NetworkService.newConnection().put(`${controller}/${cat.categoryId}/locale/${cat.langCode}`, cat);
        return new CategoryLocale(data);
    }

    returnSelectItem( categories: ICategory[], langCode: string): ICategorySelectItem[] {
        let items:ICategorySelectItem[] = [];



        for (let i = 0; i < categories.length; i++) {
            let lvl = 0;
            let catId = categories[i].id;
            while (this.returnCycleParentId(catId, categories) > 0){
                catId = this.returnCycleParentId(catId, categories);
                lvl ++;
            }
            items.push({id: categories[i].id, name: categories[i].locales.filter(x => x.langCode = langCode)[0].name, level: lvl});

        }

        return items;
    }

    returnCycleParentId(categoryId: number, categories: ICategory[]){
        let arr = categories.filter(x => x.id === categoryId);
        if(arr.length === 0){
            return 0;
        }
        return arr[0].parentId;
    }

    returnCategoryTree(stack: [{id:number, name: string}]): string[] {
        let tree: string[] = [];
        if(stack.length > 0){
            for (let i = 0; i < stack.length; i++) {
                tree.push(stack[i].name);
            }
        }
        return tree;
    }

    returnCategoryMatrix(stack: [{id:number, name: string}]): number[] {
        let matrix: number[] = [0];
        if(stack.length > 0){
            for (let i = 0; i < stack.length; i++) {
                matrix.push(stack[i].id);
            }
        }
        return matrix;
    }


}

const categoryService = new CategoryService();

export default categoryService;