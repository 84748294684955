import React from 'react';
import {useTranslation} from "react-i18next";
import {Box, Container} from "@mui/material";
import {PaperBasic} from "../../components/shared/PaperBasic";
import {JobFilterTable} from "../../components/job/JobFilterTable";
import {JobSyncCustomers} from "../../components/job/JobSyncCustomers";
import {JobSyncSuppliers} from "../../components/job/JobSyncSuppliers";
import {JobSyncProductsIn} from "../../components/job/JobSyncProductsIn";
import {JobSyncOrdersIn} from "../../components/job/JobSyncOrdersIn";

export const JobsPage: React.FC = () => {
    const {t } = useTranslation();

    const paperMargin = "15px 0px 30px 0px";

    return (
        <Box sx={{marginTop: 5}}>
           <Container sx={{px:3, marginBottom: 3}}>
               <JobFilterTable />
           </Container>
            <Container>
                <PaperBasic title={t('admin.jobs.syncCustomers.title')} paperMargins={paperMargin} >
                   <JobSyncCustomers />
                </PaperBasic>
                <PaperBasic title={t('admin.jobs.syncSuppliers.title')} paperMargins={paperMargin} >
                    <JobSyncSuppliers />
                </PaperBasic>
                <PaperBasic title={t('admin.jobs.syncProdIn.title')} paperMargins={paperMargin} >
                    <JobSyncProductsIn />
                </PaperBasic>
                <PaperBasic title={t('admin.jobs.syncOrdersIn.title')} paperMargins={paperMargin}>
                    <JobSyncOrdersIn />
                </PaperBasic>
            </Container>
        </Box>
    );
}