import React from "react";
import {Order} from "../../../models/order/Order";
import {Alert, Box, Grid} from "@mui/material";

interface IProps{
    order: Order
}

export const OrderFormAlerts: React.FC<IProps> = (props) => {
    return(
        <Grid container spacing={1} direction="column">
            {!props.order.erpStore  ?
                <Grid item>
                    <Alert severity="warning">
                        Vyberte sklad
                    </Alert>
                </Grid>

                : null}
            {!props.order.customer  ?
                <Grid item>
                    <Alert severity="warning">
                        Vyberte zákazníka
                    </Alert>
                </Grid>

                : null}
            {!props.order.customerAddress  ?
                <Grid item>
                    <Alert severity="warning">
                        Vyberte pobočku zákazníka
                    </Alert>
                </Grid>
                : null}
            {props.order.items.length === 0 ?
                <Grid item>
                    <Alert severity="warning">
                        Vyberte alespoň jednu položku
                    </Alert>
                </Grid>
            : null}

        </Grid>
    )
}