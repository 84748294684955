

import React, {useEffect} from "react";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import producerService from "../../services/ProducerService";
import {useTranslation} from "react-i18next";
import {IProducer} from "../../interfaces/producer/IProducer";


/**
 * Simple select of producer
 *
 * Komponenta si načte veškeré výrobce a nabídne je v selectu. Alternativa pro autocomplete kompoennetu která vyhledává na backednu a nabízí návrhy.
 */

interface IProps {
    selectedId: number,
    onChange: (id:number) => void,
    variant?: "outlined" | "filled" | "standard"
}
const ProducerAllSelect: React.FC<IProps> = (props) => {

    const selectedId = props.selectedId > 0 ? props.selectedId.toString() : '';

    const {t} = useTranslation();

    const [isLoaded, setisLoaded] = React.useState<boolean>(false);


    const handleChange = (val:string|number) => {
        props.onChange(Number(val));

    }

    const [producers, setProducers] = React.useState<IProducer[]>([]);

    useEffect(() => {
        if(!isLoaded){
            producerService.filter({fullText: '', name: '', countryCode: '', showNonActive: false, id: 0}).then((res) => {
                setProducers(res);
                setisLoaded(true);
            })
        }

    }, [isLoaded]);

    if(!isLoaded)
        return <CircularProgress />

    return(
        <FormControl fullWidth>
            <InputLabel id="producer-simple-select-label">{t('products.newProductDialog.selectProducer')}</InputLabel>
            <Select
                labelId="producer-simple-select-label"
                id="producer-simple-select"
                value={selectedId.toString()}
                label="{t('products.newProductDialog.selectProducer')}"
                onChange={(e) => handleChange(e.target.value)}
                variant={props.variant ?? "standard"}
            >
                {producers.length > 0 && producers.map((x,i) => (
                    <MenuItem value={x.id} key={'producerSimpleSelect_' + i}>[{x.code}] {x.name}</MenuItem>
                ))}


            </Select>
        </FormControl>
    )
}

export default ProducerAllSelect;