import React, {useEffect} from "react";
import {Alert, Chip, FormHelperText, Grid, Input, InputLabel, Typography} from "@mui/material";
import ProductAutocompleteSearch from "../../product/ProductAutocompleteSearch";
import {useTranslation} from "react-i18next";
import {
    Control,
    FieldErrors,
    UseFormClearErrors,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue
} from "react-hook-form";
import {IPhotoInputs} from "../../../interfaces/media/photo/IPhotoInputs";
import {IPhotoProduct} from "../../../interfaces/media/photo/IPhotoProduct";
import {PhotoSelectedProduct} from "./PhotoSelectedProduct";
import {PhotoProduct} from "../../../models/photo/PhotoProduct";
import {IProductAutocomplete} from "../../../interfaces/product/IProductAutocomplete";
import {IPhotoDetail} from "../../../interfaces/media/photo/IPhotoDetail";
import {PhotoEditPartInUpsertDialog} from "./PhotoEditPartInUpsertDialog";
import {AlertAppContext} from "../../alert/AlertAppContext";

interface IProps {
    reg: UseFormRegister<IPhotoInputs>,
    con: Control<IPhotoInputs, any>,
    err: FieldErrors<IPhotoInputs>
    set: UseFormSetValue<IPhotoInputs>,
    setError: UseFormSetError<IPhotoInputs>,
    clearErrors: UseFormClearErrors<IPhotoInputs>
    id: number,
    photo: IPhotoDetail|null
}


export const Photo: React.FC<IProps> = (
    {
        clearErrors,
        setError,
        set,
        reg,
        con,
        err,
        id,
        photo
    }
    ) => {

    const {t} = useTranslation();


    const [selected, setSelected] = React.useState<IPhotoProduct[]>( photo !== null && id > 0 ? photo.products : []);
    const appAlert = React.useContext(AlertAppContext);

    useEffect(() => {

        set('products', selected);
    }, [selected, set])

    const onChange = (productLocale: IProductAutocomplete | null) => {

        let main = false;
        if (productLocale?.mainPhoto) main = true;


        if (!productLocale) return;

        let arr = selected.slice();
        if(arr.find(f => f.id === productLocale.id)){
            appAlert.showAlert({severity: "info", message: t('photos.alreadyAddedProduct')});
            return false;
        }


        arr.push(new PhotoProduct({
            id: productLocale.id,
            name: productLocale.name,
            mainPhoto: main,
            codeSuffix: productLocale.codeSuffix,
            basicCode: productLocale.basicCode
        }));

        if (arr.length > 0)
            clearErrors('products');


        setSelected(arr);
    }
    const onDelete = (product: IPhotoProduct) => {

        let arr = selected.slice();
        let index = arr.indexOf(product);

        if (index !== -1) {
            arr.splice(index, 1);
        }

        if (arr.length === 0)
            setError('products', {type: 'error', message: 'aaa'});

        setSelected(arr);
    }

    const onMainPhotoChange = (product: IPhotoProduct, value: boolean) => {


        const arr = selected.map((obj) => obj.id === product.id ? {...obj, mainPhoto: value} : obj);
        set('products', arr);
        setSelected(arr);
    }


    return (
        <Grid container spacing={2} padding={1}>


            {id > 0 ?
                <PhotoEditPartInUpsertDialog key={'phoEdit_' + id} photo={photo} />
                : null}

            {id > 0 ?
                <Grid item xs={12}>
                    <Typography>Údaje k editace</Typography>
                </Grid>
                : null}


            <Grid item xs={12}>
                <ProductAutocompleteSearch label={t('photos.setProductLink')} onChange={onChange} resetValOnClick={false} />
                <FormHelperText
                    error={true}>{err.products && err.products.message == null ? '' : err.products && t('photos.validation.productRequired')}</FormHelperText>
                <Input value={selected} type="hidden" {...reg('products', {
                    validate: (value) => {

                        return selected.length !== 0;
                    }
                })} />

            </Grid>
            {selected.length > 0 ?
                <>
                    <Grid item xs={12}>
                        <Typography variant="body1">{t('photos.selected')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {selected && selected.map((product: IPhotoProduct) => {
                            return <PhotoSelectedProduct key={product.id} onDelete={onDelete} product={product}
                                                         onMainPhotoChange={onMainPhotoChange}></PhotoSelectedProduct>
                        })}
                    </Grid>
                </> : null
            }

            {/* pokud create zobraz input field pro nahrani fotky */}
            {id === 0 ?
                <Grid item xs={12}>
                    <InputLabel>{t('general.text.uploadFile')}</InputLabel>
                    <Alert severity={'warning'}>{t('documents.fileLimit')}</Alert>
                    <Input type='file' {...reg('files', {
                        required: t('general.validation.required'),
                        validate: (value) => {

                            const acceptedFormats = ["jpg", "jpeg", "bmp", "gif", "png"];
                            const maxFileSize = 5;

                            const fileSize = value[0].size / 1024 / 1024; // in MiB

                            const fileExtension = value[0].name.split('.').pop().toLowerCase();

                            if (!acceptedFormats.includes(fileExtension)) {
                                return t('general.validation.extensionNotAllowed') + acceptedFormats.join(', ');
                            }

                            if (fileSize > maxFileSize) {
                                return t('general.validation.maxFileSize') + maxFileSize + 'MB';
                            }


                            return true;
                        }
                    })} error={!!err.files} sx={{marginTop: '15px'}}/>
                    {err.files &&
                        <FormHelperText
                            error={true}>{null != err.files.message ? err.files.message.toString() : ''}</FormHelperText>
                    }
                </Grid>

                : null}


        </Grid>
    );
}